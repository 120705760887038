export const AboutUsDetail = `Welcome to BharatSaga, where the echoes of forgotten heroes and unsung warriors resonate through the corridors of time. 

BharatSaga is designed and developed by BiCSoM Technologies Private Limited, this website is a tribute to the countless souls who sacrificed their lives, dreams, and destinies for our motherland. Our mission is to shed light on the lesser-known martyrs and leaders who have been the silent architects of India's freedom and growth from 1600 to 2024. Each story here is a testament to the indomitable spirit of patriotism, courage, and selflessness that has been the bedrock of our nation.

At BharatSaga, we believe that every Indian deserves to know the true stories of these forgotten champions. Their sacrifices, though often overlooked in mainstream narratives, have been instrumental in shaping the India/Bharat we live in today. As you explore these tales of valour and determination, we invite you to connect with the emotional tapestry of our nation's history. Let their stories inspire you to honour their legacy, not just in words but in deeds, as we continue to build a stronger, united India/Bharat.

Disclaimer: The content on BharatSaga is compiled from various secondary historical sources and archives found online and offline. While every effort has been made to ensure accuracy, some details may be subject to interpretation. If you feel that any image or content should not be used or needs to be taken down, please email us at bharatiya@bharatsaga.info with the details and a valid source link showing the same image used on our website. The website does not intend to undermine the contributions of any individual or community.
`;
export const ContactUs = `Air Marshal Arjan Singh, a revered figure in Indian military history, was born on April 15, 1919, in Lyallpur, Punjab (now in Pakistan). He joined the Royal Indian Air Force (RIAF) in 1938 and quickly distinguished himself as a skilled and courageous pilot. 
Singh's leadership and valor were prominently displayed during World War II, where he led air operations in Burma, earning the Distinguished Flying Cross in 1944. After India gained independence, he played a crucial role in transforming the fledgling Indian Air Force (IAF) into a formidable force. 
As the Chief of Air Staff in 1965, during the Indo-Pak War, Singh's strategic acumen and exemplary command were pivotal in achieving air superiority for India, contributing significantly to the nation's defense.
In recognition of his outstanding service, he was promoted to the rank of Marshal of the Indian Air Force in 2002, making him the first and only officer of the IAF to attain this five-star rank, equivalent to a Field Marshal in the Army. 
Beyond his military career, Singh also served as India's Ambassador to Switzerland and the Vatican, and as the Lieutenant Governor of Delhi, showcasing his versatility and commitment to public service. His contributions to the development and modernization of the IAF are monumental, including his efforts in improving training, infrastructure, and operational capabilities. 
Singh passed away on September 16, 2017, at the age of 98, leaving behind a legacy of dedication, bravery, and an indomitable spirit that continues to inspire generations of Indian Air Force personnel. His life and career remain a testament to the highest ideals of military service and national pride.
`;
export const DISCLAIMER = `Air Marshal Arjan Singh, a revered figure in Indian military history, was born on April 15, 1919, in Lyallpur, Punjab (now in Pakistan). He joined the Royal Indian Air Force (RIAF) in 1938 and quickly distinguished himself as a skilled and courageous pilot. 
Singh's leadership and valor were prominently displayed during World War II, where he led air operations in Burma, earning the Distinguished Flying Cross in 1944. After India gained independence, he played a crucial role in transforming the fledgling Indian Air Force (IAF) into a formidable force. 
As the Chief of Air Staff in 1965, during the Indo-Pak War, Singh's strategic acumen and exemplary command were pivotal in achieving air superiority for India, contributing significantly to the nation's defense.
In recognition of his outstanding service, he was promoted to the rank of Marshal of the Indian Air Force in 2002, making him the first and only officer of the IAF to attain this five-star rank, equivalent to a Field Marshal in the Army. 
Beyond his military career, Singh also served as India's Ambassador to Switzerland and the Vatican, and as the Lieutenant Governor of Delhi, showcasing his versatility and commitment to public service. His contributions to the development and modernization of the IAF are monumental, including his efforts in improving training, infrastructure, and operational capabilities. 
Singh passed away on September 16, 2017, at the age of 98, leaving behind a legacy of dedication, bravery, and an indomitable spirit that continues to inspire generations of Indian Air Force personnel. His life and career remain a testament to the highest ideals of military service and national pride.
`;
