import share from "../assets/images/icons/share.png";
import close from "../assets/images/icons/close.png";
import rightIcon from "../assets/images/icons/Arrow-right.png";
import leftIcon from "../assets/images/icons/Arrow-left.png";
import pauseIcon from "../assets/images/icons/pause.png";
import playIcon from "../assets/images/icons/play.png";
import bharat from "../assets/images/icons/Logo placeholder.png";
import soundOn from "../assets/images/icons/Sound-on.png";
import soundOff from "../assets/images/icons/Sound-off.png";
export const SHARE = share;
export const CLOSE = close;
export const Right = rightIcon;
export const Left = leftIcon;
export const Pause = pauseIcon;
export const Play = playIcon;
export const BharatSaga = bharat;
export const SoundOn = soundOn;
export const SoundOff = soundOff;
