import bottomRect from "../assets/images/Bottom-rectangle.png";
import flower1 from "../assets/images/Flower-side-right-side-1.png";
import flower2 from "../assets/images/Flowers-right-side-2.png";
import leaves from "../assets/images/Green-leaves.png";
import minar from "../assets/images/Minar.png";
import peacock from "../assets/images/peacock.png";
import peacock2 from "../assets/images/peacocock-closed.png";
import chackra from "../assets/images/Ashok-chakra.png";
import grass from "../assets/images/grass.png";
import scrollDownButton from "../assets/images/Scroll-down-button.png";
import plant from "../assets/images/plant.png";
import footer from "../assets/images/Flag-footer.png";

import bg1600 from "../assets/images/1600/bg.png";
import bg1700 from "../assets/images/1700-1750 images/bg.png";
import bg1750 from "../assets/images/1750-1800/bg.png";
import bg1800 from "../assets/images/1800/bg.png";
import bg1850 from "../assets/images/1850/bg.png";
import bg1900 from "../assets/images/1900/bg.png";
import bg1950 from "../assets/images/1950/bg.png";
import bg1990 from "../assets/images/1950/bg.png";

import p1600_1 from "../assets/images/1600/Tansen Portrait.png";
import p1600_2 from "../assets/images/1600/raja Todarmal portrait.png";
import p1600_3 from "../assets/images/1600/Virji vora Portrait.png";
import p1600_4 from "../assets/images/1600/Guru granth sahib portrait.png";
import p1600_5 from "../assets/images/1600/Battle of sinhagad picture.png";
import p1600_6 from "../assets/images/1600/Battle of Saraighat picture.png";
import p1600_7 from "../assets/images/1600/Banda Singh Bahadur Portrait.png";
import p1600_8 from "../assets/images/1600/Nizam ul mulk portrait.png";
import p1600_9 from "../assets/images/1600/Rani Tarabai portrait.png";
import p1600_10 from "../assets/images/1600/Shivaji amharaj portrait.png";

import p1700_1 from "../assets/images/1700-1750 images/Guru Gobind singh ji portrait.png";
import p1700_2 from "../assets/images/1700-1750 images/CChatrasal bundela portrait.png";
import p1700_3 from "../assets/images/1700-1750 images/Maharaja ajit singh portrait.png";
import p1700_4 from "../assets/images/1700-1750 images/Rani mangammal portrait 1700.png";
import p1700_5 from "../assets/images/1700-1750 images/Jagat singh of mewar portrait 1700.png";
import p1700_6 from "../assets/images/1700-1750 images/bengal silk picture.png";
import p1700_7 from "../assets/images/1700-1750 images/battle of anjengo portrait 1700.png";
import p1700_8 from "../assets/images/1700-1750 images/bishnoi community portrait.png";
import p1700_9 from "../assets/images/1700-1750 images/battle of bishnupur portrait 1700.png";
import p1700_10 from "../assets/images/1700-1750 images/Madahv rao i peshwa.png";

import p1750_1 from "../assets/images/1750-1800/Peshwa balaji rao portrait.png";
import p1750_2 from "../assets/images/1750-1800/Tipu sultan portrait.png";
import p1750_3 from "../assets/images/1750-1800/Pazhassi raja portrait.png";
import p1750_4 from "../assets/images/1750-1800/Battle of plassey picture.png";
import p1750_5 from "../assets/images/1750-1800/Treaty of allahbad picture.png";
import p1750_6 from "../assets/images/1750-1800/bengl famine picture.png";
import p1750_7 from "../assets/images/1750-1800/Raja ram mohan roy 1771.png";
import p1750_8 from "../assets/images/1750-1800/Treaty of salbai picture.png";
import p1750_9 from "../assets/images/1750-1800/Treaty of mangalore picture.png";
import p1750_10 from "../assets/images/1750-1800/Classical dance picture.png";
import p1750_11 from "../assets/images/1750-1800/Bhakti movement picture.png";

import p1800_1 from "../assets/images/1800/Sanyasi rebellion picture.png";
import p1800_2 from "../assets/images/1800/Maharaja ranjit singh portrait.png";
import p1800_3 from "../assets/images/1800/Wahabi picture.png";
import p1800_4 from "../assets/images/1800/Narsimha reddy portrait.png";
import p1800_5 from "../assets/images/1800/Ishwarchandra gupta portrait.png";
import p1800_6 from "../assets/images/1800/Tatya tope porttrait.png";
import p1800_7 from "../assets/images/1800/Pindari picture.png";
import p1800_8 from "../assets/images/1800/Nana sahib portrait.png";
import p1800_9 from "../assets/images/1800/Jamsetji jeejeebhoy portrait.png";
import p1800_10 from "../assets/images/1800/Dadabhai naraoji portrait.png";
import p1800_11 from "../assets/images/1800/Rani laksmi bai portrait.png";

import p1850_1 from "../assets/images/1850/Sir Syed ahmad khan portrait.png";
import p1850_2 from "../assets/images/1850/Ishwarchandra vidyasagar portrait.png";
import p1850_3 from "../assets/images/1850/Jamset ji tata portrait.png";
import p1850_4 from "../assets/images/1850/Mahadev govind ranade portrait.png";
import p1850_5 from "../assets/images/1850/Indigo revolt picture.png";
import p1850_6 from "../assets/images/1850/Santhal rebellion picture.png";
import p1850_7 from "../assets/images/1850/Indian Rebellion 1857 picture.png";
import p1850_8 from "../assets/images/1850/Sister nivedita portrait.png";
import p1850_9 from "../assets/images/1850/indian national association picture.png";
import p1850_10 from "../assets/images/1850/Vernacular act portrait.png";
import p1850_11 from "../assets/images/1850/INC picture.png";
import p1850_12 from "../assets/images/1850/Formation of Indain Army portrait.png";

import p1900_1 from "../assets/images/1900/Partition of bengal portrait.png";
import p1900_2 from "../assets/images/1900/GHadar movement picture.png";
import p1900_3 from "../assets/images/1900/Rowlatt Satyagrah picture.png";
import p1900_4 from "../assets/images/1900/Jallianwala bagh picture.png";
import p1900_5 from "../assets/images/1900/Kandukuri veerasalingam portrait.png";
import p1900_6 from "../assets/images/1900/S Ramanujan portrait.png";
import p1900_7 from "../assets/images/1900/Moplah Rebellion picture.png";
import p1900_8 from "../assets/images/1900/Pandita Ramabai portrait.png";
import p1900_9 from "../assets/images/1900/Kakori conspiracy portrait.png";
import p1900_10 from "../assets/images/1900/AIWC Picture.png";
import p1900_11 from "../assets/images/1900/Brdoli Satyagrah picture.png";
import p1900_12 from "../assets/images/1900/ISI portrait.png";
import p1900_13 from "../assets/images/1900/Karachi resolution picture.png";
import p1900_14 from "../assets/images/1900/Azad hind fauj picture.png";
import p1900_15 from "../assets/images/1900/Ardeshir godrej picture.png";
import p1900_16 from "../assets/images/1900/Direct action day riots picture.png";
import p1900_17 from "../assets/images/1900/TV Sundaarm portrait.png";
import p1900_18 from "../assets/images/1900/Meghnad saha portrait.png";
import p1900_19 from "../assets/images/1900/PC Mahanalobis portrait.png";
import p1900_20 from "../assets/images/1900/Kamladevi portrait.png";
import p1900_21 from "../assets/images/1900/Sir M Visvesvaraya portrait.png";
import p1900_22 from "../assets/images/1900/Pingali venkayya portrait.png";
import p1900_23 from "../assets/images/1900/Rajkumari amrit kaur portrait.png";
import p1900_24 from "../assets/images/1900/Nandalal bose portrait.png";

import p1950_1 from "../assets/images/1950/satyendra nath bose portrait.png";
import p1950_2 from "../assets/images/1950/Lakshmi sehgal picture.png";
import p1950_3 from "../assets/images/1950/PV narsimha portrait.png";
import p1950_4 from "../assets/images/1950/Hargobind singh picture.png";
import p1950_5 from "../assets/images/1950/Harish chandra portrait.png";
import p1950_6 from "../assets/images/1950/I G Patel portrait.png";
import p1950_7 from "../assets/images/1950/P R Brahmananda portrait.png";
import p1950_8 from "../assets/images/1950/Sundarlal bahuguna picture.png";
import p1950_9 from "../assets/images/1950/E.Sreedharan portrait.png";
import p1950_10 from "../assets/images/1950/Ela bhatt portrait.png";
import p1950_11 from "../assets/images/1950/Bombay plan picture.png";
import p1950_12 from "../assets/images/1950/Hirakud dam picture.png";
import p1950_13 from "../assets/images/1950/PSE picture.png";
import p1950_14 from "../assets/images/1950/GEneral elections picture.png";
import p1950_15 from "../assets/images/1950/Bhoodan movement picture.png";
import p1950_16 from "../assets/images/1950/Parallel cinema picture.png";
import p1950_17 from "../assets/images/1950/State reorganisation act picture.png";
import p1950_18 from "../assets/images/1950/Silent valley picture.png";
import p1950_19 from "../assets/images/1950/White revolution picture.png";
import p1950_20 from "../assets/images/1950/Pokhran picture.png";
import p1950_21 from "../assets/images/1950/Mandal commission picture.png";
import p1950_22 from "../assets/images/1950/Licensing policy picture.png";
import p1950_23 from "../assets/images/1950/Asian games picture.png";
import p1950_24 from "../assets/images/1950/INSAT B picture.png";
import p1950_25 from "../assets/images/1950/IITs picture.png";

import p1990_1 from "../assets/images/1990/Tessy thomas picture.png";
import p1990_2 from "../assets/images/1990/Javed Abidi portrait.png";
import p1990_3 from "../assets/images/1990/Vijay bhatkar portrait.png";
import p1990_4 from "../assets/images/1990/Anshu gupta portrait.png";
import p1990_5 from "../assets/images/1990/K.Ullas Karant picture.png";
import p1990_6 from "../assets/images/1990/nandan nilekani picture.png";
import p1990_7 from "../assets/images/1990/Aruna roy picture.png";
import p1990_8 from "../assets/images/1990/Viswanathan Shanta picture.png";
import p1990_9 from "../assets/images/1990/rajendra singh picture.png";
import p1990_10 from "../assets/images/1990/Arjan singh picture.png";
import p1990_11 from "../assets/images/1990/Shankar Shanmugam picture.png";

import p1990_12 from "../assets/images/1990/Rahul bhatia- Indigo picture.png";
import p1990_13 from "../assets/images/1990/Manoj Kumar Pandey picture.png";
import p1990_14 from "../assets/images/1990/Anil kakodkar picture.png";
import p1990_15 from "../assets/images/1990/Easterine Kire picture.png";
import p1990_16 from "../assets/images/1990/Raghunath mashelkar picture.png";
import p1990_17 from "../assets/images/1990/Rani Rampal image.png";
import p1990_18 from "../assets/images/1990/Aditi Ashok picture.png";
import p1990_19 from "../assets/images/1990/Montek singh ahluwalia picture.png";
import p1990_20 from "../assets/images/1990/Vijay kedia picture.png";
import p1990_21 from "../assets/images/1990/Soumya swaminathan picture.png";

import p1990_22 from "../assets/images/1990/IGMDP picture.png";
import p1990_23 from "../assets/images/1990/polio eradication picture.png";
import p1990_24 from "../assets/images/1990/Kargil war picture.png";
import p1990_25 from "../assets/images/1990/INS Arihant picture.png";
import p1990_26 from "../assets/images/1990/UPI picture.png";
import p1990_27 from "../assets/images/1990/IITF picture.png";
import p1990_28 from "../assets/images/1990/NASSCOM picture.png";
import p1990_29 from "../assets/images/1990/Y2k picture.png";
import p1990_30 from "../assets/images/1990/Golden Quadrilateral project picture.png";
import p1990_31 from "../assets/images/1990/ISA picture.png";

export const images = {
  BOTTOM_RECTANGLE: bottomRect,
  FLOWER_RIGHT1: flower1,
  FLOWER_RIGHT2: flower2,
  LEAVES: leaves,
  MINAR: minar,
  PEACOCK: peacock,
  PEACOCK2: peacock2,
  ASHOK_CHACKRA: chackra,
  GRASS: grass,
  SCROLLDOWN: scrollDownButton,
  PLANT: plant,
  FLAGFOOTER: footer,
};
export const BackgroundImages = [
  bg1600,
  bg1700,
  bg1750,
  bg1800,
  bg1850,
  bg1900,
  bg1950,
  bg1990,
];

export const portraits1600 = {
  P1600_1: p1600_1,
  P1600_2: p1600_2,
  P1600_3: p1600_3,
  P1600_4: p1600_4,
  P1600_5: p1600_5,
  P1600_6: p1600_6,
  P1600_7: p1600_7,
  P1600_8: p1600_8,
  P1600_9: p1600_9,
  P1600_10: p1600_10,
};

export const portraits1700 = {
  P1700_1: p1700_1,
  P1700_2: p1700_2,
  P1700_3: p1700_3,
  P1700_4: p1700_4,
  P1700_5: p1700_5,
  P1700_6: p1700_6,
  P1700_7: p1700_7,
  P1700_8: p1700_8,
  P1700_9: p1700_9,
  P1700_10: p1700_10,
};

export const portraits1750 = {
  P1: p1750_1,
  P2: p1750_2,
  P3: p1750_3,
  P4: p1750_4,
  P5: p1750_5,
  P6: p1750_6,
  P7: p1750_7,
  P8: p1750_8,
  P9: p1750_9,
  P10: p1750_10,
  P11: p1750_11,
};

export const portraits1800 = {
  P1: p1800_1,
  P2: p1800_2,
  P3: p1800_3,
  P4: p1800_4,
  P5: p1800_5,
  P6: p1800_6,
  P7: p1800_7,
  P8: p1800_8,
  P9: p1800_9,
  P10: p1800_10,
  P11: p1800_11,
};

export const portraits1850 = {
  P1: p1850_1,
  P2: p1850_2,
  P3: p1850_3,
  P4: p1850_4,
  P5: p1850_5,
  P6: p1850_6,
  P7: p1850_7,
  P8: p1850_8,
  P9: p1850_9,
  P10: p1850_10,
  P11: p1850_11,
  P12: p1850_12,
};

export const portraits1900 = {
  P1: p1900_1,
  P2: p1900_2,
  P3: p1900_3,
  P4: p1900_4,
  P5: p1900_5,
  P6: p1900_6,
  P7: p1900_7,
  P8: p1900_8,
  P9: p1900_9,
  P10: p1900_10,
  P11: p1900_11,
  P12: p1900_12,
  P13: p1900_13,
  P14: p1900_14,
  P15: p1900_15,
  P16: p1900_16,
  P17: p1900_17,
  P18: p1900_18,
  P19: p1900_19,
  P20: p1900_20,
  P21: p1900_21,
  P22: p1900_22,
  P23: p1900_23,
  P24: p1900_24,
};

export const portraits1950 = {
  P1: p1950_1,
  P2: p1950_2,
  P3: p1950_3,
  P4: p1950_4,
  P5: p1950_5,
  P6: p1950_6,
  P7: p1950_7,
  P8: p1950_8,
  P9: p1950_9,
  P10: p1950_10,
  P11: p1950_11,
  P12: p1950_12,
  P13: p1950_13,
  P14: p1950_14,
  P15: p1950_15,
  P16: p1950_16,
  P17: p1950_17,
  P18: p1950_18,
  P19: p1950_19,
  P20: p1950_20,
  P21: p1950_21,
  P22: p1950_22,
  P23: p1950_23,
  P24: p1950_24,
  P25: p1950_25,
};

export const portraits1990 = {
  P1: p1990_1,
  P2: p1990_2,
  P3: p1990_3,
  P4: p1990_4,
  P5: p1990_5,
  P6: p1990_6,
  P7: p1990_7,
  P8: p1990_8,
  P9: p1990_9,
  P10: p1990_10,
  P11: p1990_11,
  P12: p1990_12,
  P13: p1990_13,
  P14: p1990_14,
  P15: p1990_15,
  P16: p1990_16,
  P17: p1990_17,
  P18: p1990_18,
  P19: p1990_19,
  P20: p1990_20,
  P21: p1990_21,
  P22: p1990_22,
  P23: p1990_23,
  P24: p1990_24,
  P25: p1990_25,
  P26: p1990_26,
  P27: p1990_27,
  P28: p1990_28,
  P29: p1990_29,
  P30: p1990_30,
  P31: p1990_31,
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const allImages = [
  ...Object.values(portraits1600),
  ...Object.values(portraits1700),
  ...Object.values(portraits1750),
  ...Object.values(portraits1800),
  ...Object.values(portraits1850),
  ...Object.values(portraits1900),
  ...Object.values(portraits1950),
  ...Object.values(portraits1990),
];

const selectedImages = shuffle(allImages).slice(0, 30);
export const randomImages = selectedImages.reduce((acc, img, index) => {
  acc[`P${index + 1}`] = img;
  return acc;
}, {});
