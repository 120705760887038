import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQuery, useScreenSize } from "../utils/responsive";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

gsap.registerPlugin(ScrollTrigger);

export const ContactForm = () => {
  const { responsiveWidth } = useMediaQuery();
  const screenSize = useScreenSize();
  const recaptchaRef = React.createRef();
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    emailId: "",
    message: "",
    agreeToTerms: false,
  });

  const [recaptchaValid, setRecaptchaValid] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.agreeToTerms) {
      toast.warning("You must agree to the terms before submitting", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!recaptchaValid) {
      toast.warning("You must verified before submitting", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    const formdata = {
      name: formData.fullName,
      phone: formData.contactNumber,
      email: formData.emailId,
      message: formData.message,
    };
    emailjs
      .send(
        "service_1a0nbtz",
        "template_qf4ig1f",
        formdata,
        "m1Y4YYtPFI1GQBvUb"
      )
      .then(
        (result) => {
          toast.success(
            <div>
              <strong>Jai Hind!</strong>
              <p>Thank you for viewing BharatSaga and sharing your message.</p>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setFormData({
            fullName: "",
            contactNumber: "",
            emailId: "",
            message: "",
          });
          setRecaptchaValid(false);
          recaptchaRef.current.reset();
        },
        (error) => {
          console.error("Error:", error);
          toast.error("There was an error sending your feedback.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };

  const handleRecaptchaChange = (token) => {
    if (token) {
      setRecaptchaValid(true);
    } else {
      setRecaptchaValid(false);
    }
  };

  useEffect(() => {
    gsap.fromTo(
      "#contactHead",
      { opacity: 0, y: -50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: "#contactHead",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".form-label",
      { opacity: 0, y: -20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".form-label",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none reverse",
        },
      }
    );

    gsap.fromTo(
      ".form-input",
      { opacity: 0, scale: 0.9 },
      {
        opacity: 1,
        scale: 1,
        duration: 0.8,
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".form-input",
          start: "top 80%",
          end: "top 60%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, [screenSize, responsiveWidth]);

  return (
    <div className="mt-[100px]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="flex justify-center text-center kalnia"
        id="contactHead"
        style={{
          fontSize:
            screenSize === "sm" || screenSize === "xs"
              ? responsiveWidth(27)
              : responsiveWidth(39),
          paddingBottom:
            screenSize === "sm" || screenSize === "xs"
              ? responsiveWidth(16)
              : responsiveWidth(50),
        }}
      >
        <span>
          Share your <span class="text-[#F15B25]">BharatSaga</span> experience
          with us...
        </span>
      </div>
      <div className="max-w-xl mx-auto p-4 contact-form">
        <form onSubmit={handleSubmit} className="text-[20px] overpass">
          <div className="mb-4 flex flex-col items-start">
            <label htmlFor="fullName" className="form-label">
              Full Name*
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm form-input"
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start">
            <label htmlFor="contactNumber" className="form-label">
              Contact Number*
            </label>
            <input
              type="tel"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm form-input"
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start">
            <label htmlFor="emailId" className="form-label">
              Email ID*
            </label>
            <input
              type="email"
              id="emailId"
              name="emailId"
              value={formData.emailId}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm form-input"
              required
            />
          </div>
          <div className="mb-4 flex flex-col items-start">
            <label htmlFor="message" className="form-label">
              Your Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm form-input"
              rows="4"
            ></textarea>
          </div>

          <div className="mb-4">
            <label className="inline-flex items-start ">
              <input
                type="checkbox"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-[13px]">
                By using this form you agree with the storage and handling of
                your data by this website.
              </span>
            </label>
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LePDSsqAAAAAJe5Ot55yorC4jv5LgkhXFPQXzrM"
              onChange={handleRecaptchaChange}
            />
          </div>
          <div className="flex justify-center pt-4">
            <button
              type="submit"
              className="inline-flex items-center px-16 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#F15B25] hover:bg-[#046A38] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
