import { useState, useEffect, createContext, useContext } from "react";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState("xs");

  useEffect(() => {
    function getScreenSize() {
      const width = window.innerWidth;
      if (width < 640) return "xs";
      if (width < 768) return "sm";
      if (width < 1024) return "md";
      if (width < 1280) return "lg";
      if (width < 1536) return "xl";
      return "2xl";
    }

    function handleResize() {
      setScreenSize(getScreenSize());
    }

    handleResize(); // Initial call to set screen size
    window.addEventListener("resize", handleResize); // Listen for window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);

  return screenSize; // Return current screen size
};

const MediaQueryContext = createContext();

export const MediaQueryProvider = ({ children }) => {
  const screenSize = useScreenSize();
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let RES_WIDTH = 13.66;
  let RES_HEIGHT = 6.41;

  if (screenSize === "sm" || screenSize === "xs") {
    RES_WIDTH = 3.92;
    RES_HEIGHT = 8.56;
  }

  const value = {
    width: size.width,
    height: size.height,
    pixelWidth: size.width / 100,
    pixelHeight: size.height / 100,
    responsiveWidth: (width) => (size.width / 100) * (width / RES_WIDTH),
    responsiveHeight: (height) => (size.height / 100) * (height / RES_HEIGHT),
  };

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQuery = () => {
  return useContext(MediaQueryContext);
};

