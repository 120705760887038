import shaniwarWada from "../assets/images/svg/Shanivar-wada.svg";
import ahilyaFort from "../assets/images/svg/Ahilya-fort.svg";
import keerthiStambha from "../assets/images/svg/Kirti-stambh-1600.svg";
import cst from "../assets/images/svg/CST.png";
import havaMahal from "../assets/images/svg/Hawa-mahal.png";
import gatewayOfIndia from "../assets/images/svg/Gateway-of-India.svg";
import lotusTemple from "../assets/images/svg/Lotus-temple.svg";
import statueOfUnity from "../assets/images/svg/Statue-of-Unity.svg";

export const KeerthiStambha = keerthiStambha;
export const AhilyaFort = ahilyaFort;
export const ShaniwarWada = shaniwarWada;
export const CST = cst;
export const HavaMahal = havaMahal;
export const GateWayOfIndia = gatewayOfIndia;
export const LotusTemple = lotusTemple;
export const StatueOfUnity = statueOfUnity;
