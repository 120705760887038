import React, { useEffect, useRef } from "react";
import { images } from "../utils/images";
import gsap from "gsap";

export const Loader = () => {
  const imageRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      gsap.to(imageRef.current, {
        scale: 0.8,
        duration: 0.5,
        ease: "power2.inOut",
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "#FDFDE3",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img
          ref={imageRef} // Attach the ref to the image
          src={images.ASHOK_CHACKRA}
          className="custom-cursor"
          alt="Loading..."
        />
      </div>
    </div>
  );
};
