export const Tansen = `Ramtanu Pandey, popularly known as Tansen was born in 1506 in a village called Behat near Gwalior, in present-day Madhya Pradesh, India. He was born into a family of musicians, and his father, Mukund Pandey, was a singer in the royal court of Gwalior.\n

Tansen's musical education began at an early age under the guidance of his father. He later sought training from renowned musicians like Swami Haridas and Mohammad Ghaus.
Tansen's exceptional talent attracted the attention of Emperor Akbar, who invited him to his court in Delhi. Tansen served as one of the nine jewels (navaratnas) in Akbar's court, where he held the title of "Mian." Under Akbar's patronage, Tansen's fame and influence grew, and he became one of the most celebrated musicians of his time.

He developed and popularized several ragas, including Miyan ki Todi, Miyan ki Malhar, and Miyan ki Sarang. His mastery over both vocal and instrumental music was unparalleled.
Tansen's life is surrounded by numerous legends and folklore, many of which depict his extraordinary musical abilities. One famous tale is about his ability to summon rain with his singing, known as "Megh Malhar."

Tansen passed away in 1589 in Delhi.Tansen's contributions to Indian classical music are unparalleled. He is revered as one of the greatest musicians in Indian history and is often considered the "father of Hindustani classical music."
Source: ChatGPT , Image Credits: Wiki Commons
`;

export const Todarmal = `Todar Mal was born in 1514 in Laharpur, which is in present-day Uttar Pradesh, India. He hailed from a Khatri family and received a formal education, which likely included training in administrative and financial matters.

Todar Mal's career flourished under the patronage of Emperor Akbar. Recognizing his administrative talents, Akbar appointed him to various positions of importance within the empire.

Along with implementing zabt, Todar Mal established the Bandobast system, which aimed to ensure fair taxation and prevent corruption among revenue officials. This system significantly improved revenue collection efficiency and fairness.

Todar Mal also focused on agricultural reforms, encouraging the cultivation of cash crops and introducing measures to improve irrigation and land management.
Todar Mal accompanied Emperor Akbar on several military campaigns, where he played a crucial role in managing finances and supply logistics for the Mughal army.

His revenue system became the basis for future revenue administration in India. He is remembered as one of the most competent administrators of his time and is often regarded as one of the Navaratnas (nine gems) in Akbar's court.
Source: ChatGPT, Image Credits: Wiki Commons
`;

export const Virji = `Virji Vora was a prominent Indian merchant and financier based in Surat during the Mughal period, often regarded as one of the wealthiest and most influential businessmen of his time.He was a key figure in the Indian and international trade networks, dealing in a wide range of goods including spices, textiles, and precious metals. His trading activities extended to Europe, Persia, and Southeast Asia.

He was known for his exceptional financial skills, providing credit and loans to traders and even to the East India Company. His ability to lend large sums of money earned him significant influence over trade dynamics.

Vora’s economic power was so substantial that he could affect the market prices of commodities in Surat, one of the major trading hubs of the time.His immense wealth and shrewd business practices made him a legendary figure in Indian economic history. His contributions significantly impacted the economic landscape of India during the 17th century.

Virji Vora's legacy as a master merchant and financier highlights the vibrant and complex nature of Indian trade and commerce during the Mughal era. His influence extended far beyond Surat, shaping trade patterns and economic policies of his time.

Source: Chat GPT, Image Credits: Wiki Commons
`;
export const Gurugranth = `The Guru Granth Sahib, the central religious scripture of Sikhism, was first compiled by Guru Arjan Dev Ji, the fifth Sikh Guru, in 1604. This sacred text, also known as the Adi Granth, is unique as it contains the hymns and writings not only of Sikh Gurus but also of various saints and poets from different religious backgrounds, including Hinduism and Islam.

Guru Arjan Dev Ji undertook the monumental task of compiling the Adi Granth. He meticulously selected hymns of the first four Sikh Gurus, his own compositions, and writings of saints such as Kabir, Namdev, and Ravidas.
The scripture includes 1,430 pages of hymns that cover a wide range of spiritual themes and ethical teachings, promoting a message of universal brotherhood and devotion to God.

Written primarily in Gurmukhi script, it incorporates multiple languages and dialects, such as Punjabi, Hindi, Persian, and Marathi. The poetic nature of the hymns makes the scripture not just a religious text but also a literary masterpiece.

In 1708, Guru Gobind Singh Ji, the tenth Sikh Guru, declared that after him, there would be no more human Gurus, and the Guru Granth Sahib would be the eternal Guru of the Sikhs. This elevated the scripture to the highest spiritual authority in Sikhism.

Source: ChatGPT, Image Credits: Wiki Commons
`;
export const Sinhagad = `The Battle of Sinhagad, also known as the Battle of Kondhana. The Battle of Sinhagad took place in 1670 near the fort of Sinhagad (formerly known as Kondhana) in present-day Maharashtra, India. The fort was strategically located on a hill and was considered a key stronghold in the region.
The Maratha Empire, under the leadership of Chhatrapati Shivaji Maharaj, was engaged in a conflict with the Mughal Empire, which sought to expand its territory into the Deccan region. The Mughal army, led by Aurangzeb's son, Rajaram I, laid siege to the fort of Sinhagad, which was under the command of Tanaji Malusare, a trusted lieutenant of Shivaji Maharaj.
Tanaji and his forces launched a daring night assault on the fort of Sinhagad, scaling the steep cliffs with the help of ropes and ladders. The Mughal garrison was caught off guard by the surprise attack, and a fierce battle ensued within the fort's walls.
The Battle of Sinhagad is remembered as a symbol of Maratha valor and sacrifice. Tanaji Malusare's bravery and selflessness became legendary, and he is revered as a hero in Indian history. 
`;
export const Saraighat = `The Battle of Saraighat, fought in 1671, The Battle of Saraighat is famous for being one of the few major naval battles fought on the Brahmaputra River. 
The Ahom Kingdom, located in present-day Assam, was a powerful kingdom in northeastern India. The Mughal Empire, under the leadership of Emperor Aurangzeb, sought to expand its territory into the region and bring Assam under Mughal control.
The Ahom navy, consisting of small, agile boats, employed guerrilla tactics to outmaneuver the larger Mughal fleet. Despite being outnumbered and outgunned, the Ahom forces, under the leadership of Lachit Borphukan, emerged victorious. Their knowledge of the river and skillful tactics helped them defeat the Mughals decisively.
Lachit Borphukan's leadership and the bravery of the Ahom soldiers are remembered with great pride in Assam. The victory at Saraighat halted the Mughal advance into Assam and preserved the independence of the Ahom Kingdom for several more decades.
`;
export const BandaSinghBahadur = `Banda Singh Bahadur was born as Lachman Dev in 1670 in Rajouri, a town in present-day Jammu and Kashmir, India. He was born into a Rajput family and was initially a Hindu ascetic (Bairagi).
At the age of 15, Inspired by the teachings of Guru Gobind Singh, the tenth Sikh Guru, Lachman Dev embraced Sikhism and became a disciple of Guru Gobind Singh. He was given the name "Banda Singh Bahadur" by the Guru, signifying his bravery.
Banda Singh Bahadur emerged as a military leader of the Sikh Khalsa (community) during a period of intense persecution of Sikhs by the Mughal Empire under Emperor Aurangzeb. He led numerous successful military campaigns against the Mughal forces and their allies.
One of Banda Singh Bahadur's most significant achievements was the capture of the city of Sirhind in 1710 after a prolonged siege. Sirhind was a key stronghold of the Mughals, and its capture by the Sikhs was a significant blow to Mughal authority in the region.
He implemented policies aimed at promoting social justice and equality, including land reforms and the abolition of oppressive practices.
Banda Singh Bahadur is revered as a hero in Sikh history for his courage, leadership, and sacrifice.
`;
export const NizamUlMulk = `In 1671, Mir Qamar-ud-din Khan, later known as Nizam-ul-Mulk, was born into a noble family in the Deccan region of India. His early life was marked by exposure to the complexities of Mughal court politics and military campaigns.
His rise to prominence within the Mughal court was swift, owing to his administrative skills and strategic acumen.
By the time he reached adulthood, Nizam-ul-Mulk had earned the trust and favor of the Mughal Emperor Aurangzeb, who appointed him to various administrative and military positions. In 1724, he was appointed as the Subedar (governor) of the Deccan province, with Hyderabad as its capital.
As the governor of Hyderabad, Nizam-ul-Mulk implemented a series of administrative reforms aimed at improving governance, revenue collection, and law enforcement. He also demonstrated his military prowess by successfully defending Hyderabad against external threats and maintaining stability in the region.
One of Nizam-ul-Mulk's most significant achievements was the establishment of the Asaf Jahi dynasty, which ruled the Hyderabad State for over two centuries. The title "Asaf Jah" was conferred upon him by the Mughal Emperor, symbolizing his status as a trusted viceroy.
Nizam-ul-Mulk's legacy endures as one of the most capable administrators and military leaders of his time. His contributions to the stability and prosperity of the Deccan region, as well as the establishment of the Asaf Jahi dynasty, have left an indelible mark on Indian history.
`;
export const Tarabai = `She was born in 1675 in the Maratha Empire, which was then ruled by the legendary Chhatrapati Shivaji Maharaj.	
Tara Bai's life unfolded during a tumultuous period marked by political intrigue and military conflicts. Following the death of Shivaji Maharaj in 1680, his son, Sambhaji, ascended to the throne. However, Sambhaji's reign was short-lived, as he was captured and executed by the Mughal Emperor Aurangzeb in 1689.
After Sambhaji's death, Tara Bai's husband, Rajaram Bhonsle, was crowned as the new Chhatrapati of the Maratha Empire. However, the Marathas faced relentless attacks from the Mughal forces, leading to the downfall of several key Maratha strongholds.
In the face of adversity, Tara Bai emerged as a formidable leader in her own right. With her husband besieged in the fort of Raigad, she took on the responsibility of leading the Maratha resistance against the Mughals. 
After the death of her husband, Tara Bai continued to play an active role in Maratha politics. She fiercely defended the rights of her son, Shivaji II, who succeeded his father as the Chhatrapati of the Maratha Empire.
Tara Bai's legacy endures as a symbol of courage, resilience, and leadership. Her contributions to the Maratha resistance against the Mughal Empire played a significant role in shaping the course of Indian history during a critical period of transition and upheaval.
`;

export const Shivaji = `Chhatrapati Shivaji Maharaj, born in 1630, was one of the most iconic and revered figures in Indian history, particularly in the state of Maharashtra. He was a visionary leader, military strategist, and founder of the Maratha Empire, engaged in numerous battles throughout his lifetime, many of which were pivotal in establishing and expanding his kingdom like Battle of Pratapgad (1659), Battle of Kolhapur (1659), Battle of Singhagad (1665), Battle of Purandar (1665), Battle of Sinhagad (1670), which played a crucial role in shaping the political landscape of India during the 17th century.
Shivaji was born into the Bhonsle Maratha clan, and from a young age, he showed remarkable leadership qualities and military prowess. He embarked on a mission to establish an independent Maratha kingdom in the face of Mughal and Adil Shahi Sultanate dominance in the Deccan region.
Shivaji's military campaigns were characterized by his innovative guerrilla tactics, mastery of fortification, and strategic alliances. He employed unconventional warfare techniques to outmaneuver and defeat much larger and more powerful adversaries. His most notable victories include the capture of forts like Torna, Raigad, and Pratapgad, which served as strongholds for his expanding empire.
Apart from his military achievements, Shivaji was also known for his administrative reforms and governance policies. He implemented a system of decentralized administration, with a focus on local self-governance and justice. His reign saw advancements in agriculture, infrastructure, and trade, contributing to the prosperity of his subjects.
He was also known for his secular outlook and tolerance towards people of different religions, earning him respect and admiration from diverse communities.
Chhatrapati Shivaji Maharaj passed away in 1680, but his legacy continues to endure
`;
export const MaharajaJagatSingh2 = `Maharaja Jagat Singh II of Mewar was born on December 22, 1709, and he passed away on September 28, 1751. He ruled the Mewar kingdom from 1734 until his death in 1751. Throughout his reign, Maharaja Jagat Singh II made significant contributions to the governance, culture, and architecture of Mewar, leaving behind a lasting legacy in the history of Rajasthan, India.

Maharaja Jagat Singh II was a notable ruler of the Mewar kingdom, located in the present-day state of Rajasthan, India. He ascended to the throne in 1734 and ruled until his death in 1751. 

Maharaja Jagat Singh II was also known for his patronage of the arts, literature, and architecture. He commissioned the construction and renovation of several palaces, temples, and other architectural marvels in Mewar, contributing to the rich cultural heritage of the region. One of his notable architectural contributions is the Jag Mandir Palace, located on an island in Lake Pichola in Udaipur. This stunning palace, also known as the "Lake Garden Palace," is renowned for its exquisite architecture and scenic surroundings.
`;

export const GuruGobindSingh = `Guru Gobind Singh Ji, born Gobind Rai, was the tenth and last of the Sikh Gurus. He was born on December 22, 1666, in Patna Sahib, Bihar, India. Guru Gobind Singh succeeded his father, Guru Tegh Bahadur, as the leader of the Sikh community at the age of nine, after Guru Tegh Bahadur's martyrdom.
Guru Gobind Singh was not only a spiritual leader but also a poet, philosopher, warrior, and social reformer. He played a crucial role in shaping Sikhism and guiding the Sikh community during a period of significant persecution and turmoil.
One of Guru Gobind Singh's most significant contributions was the formalization of the Khalsa, a community of initiated Sikhs who adhere to a strict code of conduct. In 1699, Guru Gobind Singh initiated the first five members of the Khalsa and established the Five Ks, which include Kesh (uncut hair), Kangha (wooden comb), Kara (steel bracelet), Kirpan (sword), and Kachera (undergarment). The Khalsa played a pivotal role in defending Sikhism and fighting against injustice.
In addition to his military and spiritual leadership, Guru Gobind Singh was a prolific poet and writer. He composed numerous hymns and religious texts, including the Dasam Granth and several important compositions in the Guru Granth Sahib, the holy scripture of Sikhism.
Guru Gobind Singh passed away on October 7, 1708, in Nanded, Maharashtra, India.
`;
export const Madhavrao = `Madhavrao I (also known as Madhav Rao Ballal) was a prominent ruler of the Maratha Empire during the 18th century. He was born on February 14, 1745, and he ascended to the position of Peshwa (Prime Minister) of the Maratha Empire in 1761, following the assassination of his uncle, Narayanrao Peshwa.
Madhavrao I's reign as Peshwa was marked by significant administrative reforms and military successes. He implemented several measures to strengthen the central administration of the Maratha Empire, including the reorganization of revenue collection, the establishment of a council of ministers, and the improvement of communication and transportation systems. 
One of Madhavrao I's most notable achievements was his successful military campaigns against the Nizam of Hyderabad and the Mughal Empire. He expanded Maratha influence in northern and central India, consolidating the empire's power and extending its territorial reach.
Madhavrao I's reign was cut short by his untimely death on November 18, 1772, at the age of 27. 
`;
export const RaniMangammal = `Rani Mangammal, also known as Rani Mangammal of Madurai, was a prominent queen regent of the Madurai Nayak dynasty in present-day Tamil Nadu, India. She ruled as a regent on behalf of her young son, Rani Mangammal served as the de facto ruler of the Madurai Nayak kingdom from 1689 to 1704. She was known for her astute governance, administrative reforms, and patronage of arts and culture.
During her regency, Rani Mangammal implemented several administrative reforms aimed at improving the administration and economy of the Madurai Nayak kingdom. She introduced measures to streamline tax collection, promote trade and commerce, and strengthen the military. Under her rule, Madurai Nayak kingdom experienced a period of stability and prosperity.
Rani Mangammal was also a patron of arts and literature. She supported poets, scholars, and artists, fostering a vibrant cultural environment in Madurai. Several temples and monuments were constructed or renovated during her reign, contributing to the architectural heritage of the region.
Rani Mangammal's legacy as a capable and visionary ruler is celebrated in Tamil Nadu's history.
`;
export const TheBattleofAnjengo = `The Battle of Anjengo, also known as the Battle of Anchuthengu, took place on January 21, 1721, near the town of Anjengo (present-day Anchuthengu) in Kerala, India. It was a significant naval engagement between the forces of the British East India Company and the Marathas.
The British East India Company had established trading posts along the coast of India, including the one at Anjengo, which was strategically located for its spice trade. However, the Marathas, under the leadership of Sambhaji II, sought to challenge European dominance in the region and expand their influence along the Malabar Coast.
In 1719, the Marathas launched an attack on the British trading post at Anjengo, capturing it and taking control of the fort. This action posed a threat to British interests in the region and prompted the British East India Company to respond with military force.
In response to the Maratha capture of Anjengo,in January 1721, the British East India Company dispatched a naval expedition led by Captain William Martin, to retake Anjengo. The Maratha forces, under the command of Kanhoji Angre, engaged the British fleet in a fierce naval battle off the coast of Anjengo.
The battle was intense, with both sides suffering casualties. However, the superior firepower and discipline of the British naval forces ultimately prevailed. The Maratha fleet was defeated, and the British East India Company regained control of Anjengo.
The Battle of Anjengo was significant as it secured British control over the trading post and consolidated their influence in the region. It also demonstrated the growing naval power of the British East India Company and its ability to project force in Indian waters.
`;
export const TheBishnupurRebellion = `The Bishnupur Rebellion that occurred from 1742 to 1748 was a significant uprising in the Bishnupur region of Manipur, India, against the oppressive rule of the Ahom dynasty of Assam.
During this period, Manipur was under the suzerainty of the Ahom kingdom, which controlled large parts of Northeast India. The Ahom rulers imposed heavy taxes on the Manipuri people and interfered in their internal affairs, leading to widespread discontent among the population.
The rebellion was sparked by the oppressive policies of the Ahom rulers and the dissatisfaction of the Manipuri people with their rule. Led by Ching-Thang Khomba, a powerful noble of the Ningthouja clan, the rebels rose up against the Ahom overlords and sought to establish an independent kingdom in Manipur.
The rebellion lasted for several years, with fierce battles fought between the rebels and the forces of the Ahom kingdom. Despite facing significant challenges and hardships, the rebels managed to maintain their resistance against the Ahom rulers.
However, the rebellion ultimately came to an end in 1748 when Ching-Thang Khomba was captured and executed by the Ahom forces. With the death of their leader, the rebellion collapsed, and the Ahom dynasty reasserted its control over Manipur.
The Bishnupur Rebellion of 1742-1748 had far-reaching consequences for Manipur and its people. Although the rebellion was ultimately unsuccessful in achieving its goal of independence
`;
export const SilkIndustryBengal = `The "Golden Era" of the silk industry in Bengal, spanning from 1717 to 1757, was ignited primarily by the strategic initiatives of the British East India Company. With its expanding influence in Bengal, the Company recognized the economic potential of the region's silk production. Encouraged by the lucrative trade prospects, the British East India Company spearheaded efforts to promote and enhance silk cultivation and weaving in Bengal.
Under the Company's direction, sericulture experienced a significant expansion as farmers were incentivized to grow mulberry trees, crucial for silkworm rearing. This expansion led to a substantial increase in silk production, meeting the rising demand both domestically and in European markets.
The Company's control over Bengal's economy and trade routes facilitated the efficient export of Bengal silk, elevating its status in global markets. Bengal silk, renowned for its quality and craftsmanship, became highly sought after, contributing to the region's economic prosperity.
Simultaneously, weaving centers in Bengal, notably Murshidabad and Malda, thrived during this period. Skilled artisans produced exquisite silk fabrics and sarees, further enhancing Bengal's reputation as a premier silk-producing region.
The political stability under the rule of Nawabs appointed by the Mughal Empire provided a conducive environment for economic activities, fostering growth and innovation in the silk industry. Additionally, advancements in silk weaving techniques and equipment contributed to the industry's success during this era.
In essence, the "Golden Era" of the Bengal silk industry from 1717 to 1757 was catalyzed by the strategic initiatives of the British East India Company, which recognized and capitalized on the region's immense potential for silk production and trade.
`;
export const MaharajaAjitSingh = `Maharaja Ajit Singh was a notable ruler of the Marwar region in present-day Rajasthan, India, during the late 17th and early 18th centuries. He belonged to the Rathore clan of Rajputs and ascended to the throne of Marwar in 1679, succeeding his father Maharaja Jaswant Singh I.

Ajit Singh's reign was marked by significant political and military achievements. He successfully expanded the territory of Marwar through strategic alliances and military campaigns.

Ajit Singh is perhaps best remembered for his defiance against the Mughal Empire, particularly during the reign of Emperor Aurangzeb. Despite pressure from the Mughals to submit to their authority, Ajit Singh maintained Marwar's autonomy and sovereignty, earning him a reputation as a staunch defender of Rajput independence.
In addition to his military and political endeavors, Ajit Singh was also known for his patronage of the arts and culture. He supported poets, musicians, and scholars, contributing to the flourishing of literature and cultural activities in Marwar during his reign.
Ajit Singh's rule came to an end in 1724 when he was killed in a battle against his nephew, Abhai Singh. His death marked the beginning of a period of instability and dynastic conflicts in Marwar
`;
export const ChhatrasalBundela = `Chhatrasal Bundela was a prominent ruler and warrior who played a significant role in the history of Bundelkhand, a region in central India. Born in 1649, Chhatrasal was the son of Champat Rai, a nobleman in the service of the Mughal Empire.
Chhatrasal rose to prominence during a tumultuous period in Indian history characterized by the decline of the Mughal Empire and the rise of regional powers. He rebelled against the Mughal authority in Bundelkhand and established an independent kingdom.
He successfully resisted Mughal attempts to subjugate him and expanded his territory through conquests and alliances with other regional powers. His kingdom encompassed parts of present-day Uttar Pradesh, Madhya Pradesh, and Chhattisgarh.
He was also known for his support of Hinduism and patronage of temples and religious institutions.
One of Chhatrasal's most famous alliances was with the Maratha leader Shivaji, whom he supported in his struggle against the Mughals. This alliance helped strengthen both their positions and contributed to the weakening of the Mughal Empire in central India.
Chhatrasal Bundela passed away in 1731, leaving behind a legacy of valor, independence, and cultural patronage. He is remembered as a hero and a symbol of resistance against foreign domination in Bundelkhand. 
`;
export const BishnoiCommunity = `The Bishnoi community is renowned for its steadfast commitment to environmental conservation, a tradition that dates back to the early 18th century. In 1730, under the leadership of Amrita Devi, the Bishnois demonstrated their unwavering dedication to protecting nature and wildlife.
The incident, famously known as the Khejarli Massacre, occurred when the Maharaja of Jodhpur dispatched soldiers to fell trees in the Khejarli village, located in the Marwar region of Rajasthan, India. The trees were being cut down to fuel the construction of a new palace. However, Amrita Devi, a Bishnoi woman, along with other members of her community, bravely stood in front of the trees, embracing them to prevent their felling.
Despite facing the threat of violence and death, Amrita Devi and the Bishnois refused to relent. Their actions were not just a defense of the trees but a testament to their deeply held belief in the sanctity of all living beings. In their faith, trees are considered sacred, and harming them is strictly forbidden.
Tragically, the soldiers carried out their orders, leading to the martyrdom of Amrita Devi and over three hundred Bishnois who sacrificed their lives to protect the trees. However, their sacrifice did not go in vain. The incident drew widespread attention and condemnation, leading to the establishment of the world's first environmental conservation movement.
The Bishnois' conservation efforts serve as a poignant reminder of the profound connection between humans and the natural world, urging us all to protect and nurture the environment for future generations.
`;
export const PazhassiRaja = `Pazhassi Raja (1753–1805), also known as Kerala Varma Pazhassi Raja, is celebrated as a heroic figure and a symbol of resistance in the history of Kerala. Born into the royal family of Kottayam, he is renowned for his relentless struggle against both the Mysore Sultanate and British colonial rule.

Initially, Pazhassi Raja successfully resisted the invasions of Hyder Ali and Tipu Sultan through effective guerrilla warfare in the dense forests of Wayanad. His staunch opposition to British colonial policies began in the late 1700s, particularly against their attempts to impose heavy taxes on his people. He stopped the British from collecting taxes directly from farmers, gaining widespread local support.

Pazhassi Raja led two major revolts against the British: the first from 1793 to 1797 and the second from 1800 to 1805. Despite facing immense pressure, he utilized guerrilla tactics and the challenging terrain of Wayanad to conduct ambushes and hit-and-run attacks on British forces. His strategic prowess was notably demonstrated in the Battle of Periya in 1800, where he inflicted significant casualties on the British.

Pazhassi Raja's persistent resistance made him a legendary figure, embodying the spirit of defiance against colonial oppression. He was killed in a confrontation with British forces on November 30, 1805, but his legacy endures as a source of inspiration. Memorials, literature, and cinema continue to celebrate his bravery and leadership, cementing his place in Indian history as the "Lion of Kerala."
`;
export const TreatySalbai = `The Bengal Famine of 1770 stands as one of the most catastrophic events in Indian history, leaving an indelible mark on the social, economic, and political landscape of the region. This famine, which struck the Bengal Presidency during British colonial rule, resulted in widespread starvation and immense suffering, claiming the lives of millions of people across the affected areas. 

Several factors contributed to the severity of the Bengal Famine of 1770. A combination of natural calamities, including droughts, floods, and cyclones, devastated agricultural production, leading to acute food shortages.

British authorities prioritized revenue collection over relief efforts, enforcing oppressive taxation policies that left peasants destitute and unable to afford basic necessities.
The social and economic consequences of the Bengal Famine were profound and far-reaching. The loss of millions of lives devastated communities and families, triggering widespread social dislocation and upheaval.
The Bengal Famine of 1770 served as a stark reminder of the vulnerabilities inherent in agrarian societies and the imperative of effective governance in times of crisis. It also fueled discontent and resentment against British colonial rule, contributing to growing anti-colonial sentiments in India.
`;
export const BengalFamine = `The Treaty of Salbai, signed in 1782, stands as a pivotal diplomatic accord between the British East India Company and the Maratha Empire, following the culmination of the First Anglo-Maratha War. Located in the village of Salbai within present-day Maharashtra, India, this treaty held significant implications for the territorial, political, and economic landscape of 18th-century India. 

One of its primary outcomes was the restoration of territorial boundaries to their pre-war status, reflecting a mutual agreement to revert captured territories back to their respective rulers. This move fostered a semblance of stability in the region and facilitated the resumption of normalcy after the turmoil of conflict.


In addition to territorial and political matters, the treaty addressed economic concerns by regulating trade relations between the Maratha territories and British-controlled regions. This framework aimed to facilitate commercial activities and ensure a smooth flow of goods, thereby promoting economic prosperity for both parties involved.

The Treaty of Salbai, while bringing an end to the immediate hostilities of the First Anglo-Maratha War, set the stage for future interactions and power dynamics between the British and the Marathas. It marked a crucial moment in the colonial history of India, shaping the trajectory of British expansion and Maratha influence in the subcontinent. 
`;
export const BattlePlassey = `The Battle of Plassey, fought on June 23, 1757, near the village of Plassey in Bengal (now in West Bengal, India), stands as a pivotal moment in Indian history and British colonial expansion on the subcontinent. This battle marked the culmination of escalating tensions between the British East India Company and the Nawab of Bengal, Siraj-ud-Daulah. The British, under the leadership of Robert Clive, emerged victorious, securing a decisive victory that paved the way for their subsequent dominance in India.

The conflict leading to the Battle of Plassey was fueled by economic interests and political ambitions. The British East India Company, seeking to expand its influence and control over trade in Bengal, clashed with the Nawab, who perceived the Company's growing power as a threat to his authority. 

They formed alliances with disaffected elements within the Nawab's court, including Mir Jafar, who betrayed Siraj-ud-Daulah and defected to the British side during the battle. This betrayal proved decisive, as it weakened the Nawab's forces and contributed to their eventual defeat.
The victory at Plassey enabled the British East India Company to expand its territorial control, extract economic resources, and assert its dominance over Indian rulers and territories.
Moreover, the Battle of Plassey symbolizes the exploitative nature of colonialism and the ways in which European powers manipulated internal divisions and power struggles within India to further their imperial ambitions.
`;
export const TreatyAllahabad = `The Treaty of Allahabad, signed in 1765, represents a pivotal moment in the history of British colonial expansion in India. This treaty was concluded between the British East India Company, represented by Robert Clive, and the Mughal Emperor Shah Alam II, marking the culmination of the Battle of Buxar and the subsequent British victory over the Mughal Empire and its allies.The terms of the treaty granted the British East India Company the diwani rights, or the right to collect and manage revenue, over the rich and fertile provinces of Bengal, Bihar, and Orissa. 

In addition to granting the diwani rights, the Treaty of Allahabad also reaffirmed the Mughal Emperor's nominal sovereignty over these territories, albeit in a reduced capacity. The Mughal Emperor retained his title and ceremonial authority, but real political power now lay in the hands of the British East India Company, marking the beginning of British paramountcy in India. 
Furthermore, the treaty provided for the payment of an annual tribute to the Mughal Emperor in exchange for his acquiescence to British rule, further consolidating British control over the region.
The Treaty of Allahabad had far-reaching implications for the political, economic, and social landscape of India. It paved the way for British dominance in the Indian subcontinent, setting the stage for nearly two centuries of British colonial rule. 
Overall, the Treaty of Allahabad represents a seminal moment in Indian history, symbolizing the ascendancy of British colonial power and the decline of indigenous Indian rulership.
`;
export const SheikhChilliBaba = ``;
export const TreatyMangalore = `The Treaty of Mangalore, signed in 1784, stands as a significant diplomatic agreement between the British East India Company and the Kingdom of Mysore, concluding the Second Anglo-Mysore War (1780-1784). This conflict was characterized by intense military engagements and political maneuvering between the British and the Mysorean forces led by Tipu Sultan. 
One of the key provisions of the Treaty of Mangalore was the restoration of territories captured by both parties during the course of the war. The treaty stipulated that all territories, forts, and possessions seized by either side would be returned to their original owners, effectively reinstating the status quo ante bellum. 

The British East India Company agreed to release all Mysorean prisoners of war and return them to their homeland, while Mysore pledged to pay a sum of money as compensation for damages incurred during the war. 

Both parties agreed to enter into a treaty of perpetual friendship and alliance, pledging to uphold mutual respect, cooperation, and non-interference in each other's internal affairs. This commitment to diplomacy and mutual understanding laid the groundwork for a more stable and constructive relationship between the British and Mysorean powers in the years to come.
In conclusion, the Treaty of Mangalore represented a crucial moment in the history of Anglo-Indian relations, marking the end of hostilities between the British East India Company and the Kingdom of Mysore and laying the foundation for a period of relative peace and cooperation.
`;
export const Bhakti = `The Bhakti Movement, spanning the 17th to 18th centuries in India, emerged as a transformative socio-religious phenomenon that sought to foster devotion and spiritual enlightenment among the masses. Rooted in the diverse religious traditions of Hinduism, Islam, and Sikhism, the Bhakti Movement emphasized a personal and direct relationship with the divine, transcending traditional caste and religious boundaries.
One of the central tenets of the Bhakti Movement was the idea of bhakti, or loving devotion, as the path to salvation. Bhakti poets and saints composed devotional hymns, songs, and poems in vernacular languages such as Hindi, Tamil, Bengali, and Punjabi, making religious teachings accessible to a wider audience. These compositions, known as bhajans, kirtans, or shabads, celebrated the glory of the divine and expressed the longing of the soul to unite with the ultimate reality.
The Bhakti Movement challenged the hierarchical social structure of medieval India, which was characterized by rigid caste divisions and religious orthodoxy. Bhakti saints like Kabir, Guru Nanak, Mirabai, Tulsidas, and Chaitanya Mahaprabhu advocated for social equality, rejecting caste-based discrimination and emphasizing the inherent dignity and equality of all human beings. 

Moreover, the Bhakti Movement played a significant role in fostering cultural synthesis and integration across different regions of India. The legacy of the Bhakti Movement endures to this day, continuing to inspire millions of devotees and spiritual seekers across India and beyond.
`;
export const RajaRamMohanRoy = `Raja Ram Mohan Roy, born in 1772 in Radhanagar, Bengal Presidency (now in West Bengal, India), emerged as a luminary in the Indian socio-religious milieu of the late 18th and early 19th centuries. Revered as the "Father of the Indian Renaissance," Roy left an indelible mark on Indian society through his relentless advocacy for social reform and enlightenment. 

Roy was a vocal opponent of practices like Sati, child marriage, and caste discrimination, viewing them as regressive hindrances to societal progress. He founded the Brahmo Sabha in 1828, which evolved into the Brahmo Samaj, a reformist movement advocating for monotheism, rationality, and social equality.He also established schools that blended Western pedagogy with traditional Indian learning, fostering a generation of students imbued with both modern knowledge and moral values.

A prolific writer and journalist, Roy utilized the burgeoning print media of his time to disseminate his ideas far and wide. His newspapers and journals, including "Sambad Kaumudi" and "Mirat-ul-Akbar," became platforms for discourse on social, religious, and political reform, catalyzing intellectual awakening across the Indian subcontinent.

In the realm of religious thought, Roy sought to reconcile faith with reason. He critiqued idol worship and superstition, advocating for a rational interpretation of religious texts and the pursuit of a universal, moral religion grounded in ethical principles.

Though Roy passed away in 1833, his legacy endures as a beacon of enlightenment and social progress.
`;
export const Dance = `The 18th century marked a significant period in the revival of classical Indian dance forms. This revival was fueled by a combination of factors, including the decline of Mughal power, the emergence of regional kingdoms, and the growing influence of Hindu religious and cultural revival movements. During this time, classical dance forms such as Bharatanatyam, Odissi, Kathak, Kuchipudi, and Manipuri experienced a renewed interest and appreciation among both the elite and common people.
One of the key catalysts for the revival of classical Indian dance forms was the patronage of regional rulers who sought to preserve indigenous cultural traditions. Kings and nobles in princely states such as Tanjore, Travancore, Jaipur, and Manipur became enthusiastic supporters of classical dance, establishing dance schools, academies, and performance venues to nurture and promote these art forms. 
Furthermore, the revival of classical Indian dance forms was closely intertwined with religious and cultural movements. The Bhakti Movement, for example, emphasized the importance of devotional expression through music, dance, and poetry, inspiring a resurgence of interest in classical dance as a form of spiritual practice and cultural expression.


The establishment of dance academies and institutions provided formal education and professional opportunities for aspiring dancers, contributing to the professionalization and standardization of classical dance practices.Dance masters, known as gurus, played a crucial role in transmitting the knowledge and techniques of classical dance to future generations.

Today, classical Indian dance forms continue to thrive as vibrant and dynamic art forms, celebrated for their beauty, grace, and spiritual significance. 
`;
export const TipuSultan = `Tipu Sultan, reigning from 1750 to 1799, was a prominent ruler of the Kingdom of Mysore in southern India during the late 18th century. He succeeded his father, Hyder Ali, and is remembered as a formidable military strategist and innovator, known for his resistance against British encroachment and his efforts to modernize and strengthen the Mysorean state.

One of Tipu Sultan's most enduring legacies is his efforts to unite Indian rulers against foreign domination. He waged several wars against the British East India Company, most notably during the Anglo-Mysore Wars, where he inflicted significant losses on British forces and temporarily halted their expansion into southern India. Tipu Sultan's military campaigns were characterized by innovative tactics, such as the use of rocket artillery, which earned him the nickname "Tiger of Mysore" among British adversaries.

He introduced administrative innovations, such as land revenue systems, coinage reforms, and centralized administration, which improved governance and facilitated economic development. Tipu Sultan also promoted trade and industry, establishing diplomatic relations with foreign powers and encouraging the growth of commerce and manufacturing within his kingdom.

Tipu Sultan was a patron of the arts, culture, and literature, fostering a vibrant cultural milieu at his court. He supported the development of indigenous crafts, music, and literature, patronizing poets, scholars, and artists who enriched Mysorean cultural heritage. 

Tipu Sultan's reign saw the flourishing of Indo-Islamic architecture, with the construction of palaces, mosques, and fortifications that reflected his eclectic aesthetic tastes and architectural innovations.
His complex legacy continues to inspire scholarly inquiry and public discourse, reflecting the enduring significance of his contributions to the history of South India and the struggle against British imperialism.
`;
export const PeshwaBalajiBajiRao = `Peshwa Balaji Baji Rao, who reigned from 1720 to 1761, was a pivotal figure in the Maratha Empire's history during the 18th century. As the Peshwa, or prime minister, his era was characterized by his astute statesmanship, military prowess, and administrative reforms, which helped consolidate Maratha power and expand its influence across the Indian subcontinent.
Balaji Baji Rao's most notable achievement was his successful leadership during the Third Battle of Panipat in 1761, a decisive conflict between the Marathas and the Durrani Empire of Afghanistan. Although the Marathas ultimately suffered a defeat in the battle, Balaji Baji Rao's resilience and determination earned him admiration and respect among his contemporaries.
In addition to his military exploits, Balaji Baji Rao implemented significant administrative reforms for strengthening Maratha governance. He established a system of revenue collection, known as the ryotwari system, which streamlined taxation and improved fiscal management within the empire.
Furthermore, Balaji Baji Rao was a patron of the arts, literature, and culture, fostering a vibrant cultural milieu at the Maratha court. He supported the development of Marathi literature, patronizing poets, scholars, and artists who enriched Maratha cultural heritage. 
Despite his many accomplishments, Balaji Baji Rao's life was cut short by his untimely death in 1761, shortly after the Battle of Panipat. His death marked the end of an era in Maratha history and left a void in the empire's leadership.
`;

export const DadabhaiNaoroji = `Dadabhai Naoroji, a towering figure in India's struggle for independence, was born in Bombay in 1825. Naoroji's journey was multifaceted, marked by his roles as an educator, political leader, and social reformer.
Naoroji's significance in Indian politics was highlighted by his groundbreaking election to the British Parliament in 1892, representing Finsbury Central as a member of the Liberal Party. This historic achievement made him the first Indian to hold a seat in the British Parliament, paving the way for future generations of Indian leaders to engage with the British political system.
Throughout his parliamentary tenure, Naoroji tirelessly advocated for Indian interests, shedding light on the economic exploitation of India by the British colonial administration. His seminal work, the "Drain Theory," articulated how India's wealth was being systematically siphoned off to Britain, leaving the Indian populace impoverished. 
He was one of the founding members of the Indian National Congress in 1885, a pivotal organization that played a central role in India's struggle for independence. As an integral part of the Congress, Naoroji worked alongside other luminaries to articulate the aspirations of the Indian people and demand self-governance.
Despite facing numerous challenges and setbacks, Naoroji remained steadfast in his commitment to India's freedom and progress. His legacy continues to inspire generations of Indians, serving as a reminder of the power of perseverance, integrity, and dedication in the face of adversity.
Source: Chat GPT, Image Credits:Wikipedia
`;
export const SanyasiRebellion = `The Sanyasi Rebellion, also known as the Fakir-Sannyasi Rebellion, was an uprising that took place in Bengal and Bihar in the late 18th century, primarily between 1770 and 1800. The rebellion was led by a group of ascetics, known as sanyasis or fakirs, who were dissatisfied with the British East India Company's rule and the oppressive policies of the local zamindars (landlords).
The sanyasis were Hindu ascetics who lived a life of renunciation, often wandering from place to place preaching and practicing their beliefs. During the late 18th century, many of them became disillusioned with the increasing land revenue demands imposed by the British and the exploitation they faced under the zamindari system.
The rebellion was characterized by sporadic acts of violence, raids on British and zamindar establishments, and attacks on revenue officials. The rebels aimed to challenge British authority and overthrow the zamindars who were seen as collaborators with the colonial regime.
Although the rebellion lacked central leadership and organization, it spread across large parts of Bengal and Bihar, posing a significant challenge to British control in the region. The rebels often found support among the local peasantry and disaffected groups who were also suffering under British and zamindar oppression.
The British authorities responded to the rebellion with force, deploying military expeditions to suppress the uprising. Despite their efforts, the rebellion continued for several decades, fueled by grievances against British economic policies and social injustices.
Ultimately, the Sanyasi Rebellion was gradually quelled by the British through a combination of military action, negotiation, and co-option of local elites. However, the uprising left a lasting impact on the collective memory of the region, symbolizing resistance against colonial exploitation and oppression.
`;

export const RustomjeeJamsetjeeJeejeebhoy = `Rustomjee Jamsetjee Jeejeebhoy, born in 1783 and passing away in 1859, was a prominent Parsi businessman and philanthropist in 19th-century Bombay (now Mumbai), India. He played a significant role in shaping the economic and social landscape of the city during the colonial period.
Jeejeebhoy hailed from a humble background and began his career as a merchant, trading in commodities such as cotton and opium. Through his astute business acumen and perseverance, he amassed considerable wealth and established himself as one of the leading entrepreneurs of his time.
He was deeply committed to philanthropy and believed in giving back to society. Throughout his life, he generously contributed to various charitable causes, including education, healthcare, and social welfare.
One of Jeejeebhoy's most enduring contributions was in the field of healthcare. He played a key role in the establishment of several hospitals and medical institutions in Bombay, including the Sir Jamsetjee Jeejeebhoy Hospital (now known as Sir J.J. Hospital) and the Grant Medical College. These institutions provided much-needed healthcare services to the people and laid the foundation for modern medical education and research in India.
He supported initiatives aimed at promoting education, especially among the disadvantaged sections of society. He also contributed to the construction of public infrastructure projects, such as roads, bridges, and water supply systems, which helped improve the quality of life for the residents of Bombay.
In recognition of his contributions to society, Jeejeebhoy was knighted by Queen Victoria in 1842, becoming the first Indian to receive a knighthood from the British crown.
`;

export const MaharajaRanjitSingh = `Maharaja Ranjit Singh, born in 1780, was the founder and ruler of the Sikh Empire in the early 19th century. Ranjit Singh's reign, which lasted from 1801 until his death in 1839, was characterized by military prowess, political acumen, and a commitment to secular governance.
Ranjit Singh inherited the leadership of the Sikh Confederacy at a young age, following the death of his father, Maha Singh, in 1792. Despite facing internal strife and external threats, he managed to consolidate his power and establish control over much of Punjab through a series of military campaigns and diplomatic maneuvers.
One of Ranjit Singh's most notable achievements was the unification of the Sikh misls (confederacies) into a cohesive empire, which he accomplished through a combination of military conquests and shrewd diplomacy. Under his leadership, the Sikh Empire emerged as a formidable political entity, encompassing vast territories in present-day Punjab, as well as parts of modern-day Pakistan and India.
Ranjit Singh's rule was characterized by religious tolerance and a policy of secular governance. He employed administrators and generals from different backgrounds, including Hindus, Muslims, and Sikhs, fostering a sense of inclusivity and diversity in his administration.
Ranjit Singh is also remembered for his patronage of the arts, culture, and architecture. He commissioned the construction of several grand buildings, including the iconic Harmandir Sahib (Golden Temple) in Amritsar, which remains one of the holiest sites in Sikhism.
Ranjit Singh's death in 1839 marked the end of an era in Punjab's history. His empire faced internal strife and external pressures in the years following his demise, ultimately succumbing to British annexation in 1849 after the two Anglo-Sikh Wars
`;

export const WahhabiMovement = `The Wahhabi Movement in Malabar, which took place between 1800 and 1832, was a significant socio-religious movement in the Malabar region of present-day Kerala, India. It was inspired by the teachings of Muhammad ibn Abd al-Wahhab, the 18th-century Islamic scholar from Arabia, whose followers sought to purify Islam from what they perceived as deviant practices and beliefs.
The movement gained traction in Malabar primarily among the Mappila Muslim community, who were influenced by the teachings of Wahhabism through traders and scholars from the Arabian Peninsula. The followers of the Wahhabi Movement, known as Wahhabis or Mappilas, aimed to reform and revitalize Islam in the region according to their interpretation of Islamic principles.
The movement also had political implications, as Wahhabi leaders challenged the authority of local rulers and landlords, who they viewed as corrupt and un-Islamic. This led to occasional conflicts between the Wahhabis and the ruling powers, including the British East India Company, which had established control over Malabar by the early 19th century.
The Wahhabi Movement in Malabar reached its peak in the early 19th century, with the establishment of Wahhabi-controlled areas and the imposition of their strict interpretation of Islamic law in certain regions. However, the movement faced opposition from other Muslim groups, as well as from the British colonial authorities, who viewed it as a threat to their authority and interests in the region.
The decline of the Wahhabi Movement in Malabar began in the 1820s, as British forces launched military campaigns to suppress the movement and restore order in the region. By 1832, the British had successfully quelled the rebellion and dismantled Wahhabi strongholds, effectively bringing an end to the movement in Malabar.
Despite its eventual decline, the Wahhabi Movement in Malabar left a lasting impact on the religious and social landscape of the region.
`;
export const UyyalawadaNarasimhaReddy = `Uyyalawada Narasimha Reddy, born in 1806 and executed in 1847, was a prominent figure particularly in the region of Rayalaseema in present-day Andhra Pradesh, India. He is best known for leading a rebellion against the British East India Company in the early 19th century, often referred to as the "Rebellion of Uyyalawada Narasimha Reddy."
Narasimha Reddy hailed from the Uyyalawada village in Kurnool district, which was part of the princely state of Kurnool. He belonged to the Kapu community, a prominent agrarian caste in the region. Narasimha Reddy was well-respected among his people for his courage, leadership, and commitment to social justice.
The rebellion led by Narasimha Reddy erupted in 1846 in response to the oppressive policies of the British East India Company, including heavy taxation, forced labor, and discriminatory practices against the local population.The rebellion gained momentum as Narasimha Reddy and his followers launched attacks on British installations, tax collectors, and other symbols of colonial authority. 
However, the rebellion faced challenges, including the superior military strength of the British forces and internal divisions among the rebels. Despite putting up a fierce resistance, Narasimha Reddy and his followers were eventually defeated by British troops in a series of battles.
Narasimha Reddy was captured by the British in 1847 and subsequently executed, marking the end of the rebellion. His courage and sacrifice inspired future generations of Indians to continue the struggle for independence from British colonial rule.
`;
export const IshwarchandraGupta = `Ishwarchandra Gupta, born in 1812 and passing away in 1859, was a prominent Bengali poet, writer, and journalist during the 19th century Bengal Renaissance. He made significant contributions to Bengali literature and journalism, particularly through his innovative poetry and pioneering efforts in establishing Bengali newspapers.
Gupta was born in Kolkata (then Calcutta) into a family with a literary background. He received his education in Kolkata and developed a deep interest in literature from a young age.
One of Gupta's most notable achievements was his role in revolutionizing Bengali poetry. He experimented with various poetic forms and styles, introducing new themes and techniques that departed from the traditional norms of Bengali literature. His poetry often reflected the social and cultural realities of his time, addressing issues such as poverty, inequality, and social reform.
Gupta also played a pioneering role in the development of Bengali journalism. In 1839, he founded the first Bengali newspaper, "Sambad Prabhakar," which became a platform for promoting social and political awareness among the Bengali-speaking population. 
Gupta's journalistic endeavors earned him widespread acclaim and recognition as a leading figure in the field of Bengali journalism. His contributions helped lay the foundation for the growth of the Bengali press and its role in shaping public opinion and discourse in Bengal.
`;
export const TantiaTop = `Tantia Tope, also known as Tatya Tope, was a prominent Indian freedom fighter and military leader during the Indian Rebellion of 1857, often referred to as the First War of Independence. Born in 1814 in the Maratha-ruled state of present-day Maharashtra, 
Tantia Tope was born as Ramachandra Panduranga to a Brahmin family in the town of Yeola. He received education in Sanskrit and military training in his early years, which laid the foundation for his later role as a military leader.
During the Indian Rebellion of 1857, Tantia Tope emerged as a prominent leader, rallying forces against British rule in various parts of central India.He participated in key battles, including the Siege of Kanpur and the Battle of Kalpi, where his forces inflicted significant losses on the British.
Despite facing setbacks and betrayals, Tantia Tope continued to fight tenaciously against the British forces. His guerilla tactics and ability to mobilize support from local communities posed a formidable challenge to British colonial authority in central India.
However, the British eventually managed to capture Tantia Tope in April 1859 after a prolonged pursuit. He was tried by a military court and sentenced to death for his role in the rebellion. Tantia Tope was executed by hanging on April 18, 1859, in Shivpuri, present-day Madhya Pradesh.
`;
export const NanaSahib = `Nana Sahib, born around 1824 and died in the late 1857 was a significant figure in the Indian Rebellion of 1857, also known as the First War of Independence against British colonial rule. He played a crucial role in leading the rebellion in the region of Kanpur (formerly Cawnpore) in present-day Uttar Pradesh, India.
Nana Sahib, whose original name was Dhondu Pant, was the adopted son of the exiled Maratha Peshwa Baji Rao II. He was denied his rightful pension and inheritance by the British East India Company after the death of Baji Rao II, which fueled his resentment towards British rule and motivated his involvement in the rebellion.
Nana Sahib's most infamous involvement in the rebellion was during the Siege of Cawnpore. In June 1857, British civilians, including women and children, sought refuge in the British garrison in Cawnpore, led by General Wheeler. Nana Sahib, initially seen as a friend to the British, promised them safe passage but ultimately betrayed them. The British, trusting Nana Sahib's word, surrendered, but instead of providing the promised safe conduct, Nana Sahib's forces massacred them.
However, Nana Sahib's role in the massacre remains debated among historians, with some suggesting that he may not have been directly responsible for the atrocity, while others argue that he likely had knowledge of and condoned the actions of his forces.
Despite initial successes, the rebellion was eventually suppressed by British forces, and Nana Sahib fled, disappearing from historical records. His fate remains uncertain, with conflicting accounts suggesting that he may have been killed in battle, captured and executed, or escaped to Nepal or elsewhere.
`;
export const RaniLakshmibaiJhansi = `Rani Lakshmibai of Jhansi, also known as the Rani of Jhansi or Lakshmi Bai, was one of the most prominent figures of the Indian Rebellion of 1857 against British colonial rule. She was born on 19 November 1828 in Varanasi, India, and passed away on 18 June 1858 in Gwalior, India.
Lakshmibai was married to Raja Gangadhar Rao, the Maharaja of Jhansi, and became the queen consort of the princely state of Jhansi in 1842. After the death of her husband in 1853, she was faced with the challenge of defending Jhansi against British annexation, as the Doctrine of Lapse policy was being enforced by the British East India Company to annex princely states with no natural heir.
During the Indian Rebellion of 1857, Rani Lakshmibai emerged as a courageous leader and played a pivotal role in the uprising against British rule.
One of the most iconic moments associated with Rani Lakshmibai is the Siege of Jhansi, during which she led her troops in fierce resistance against the British army. Despite being outnumbered and outgunned, she valiantly defended her kingdom and became a symbol of resistance and patriotism.
After the fall of Jhansi, Rani Lakshmibai continued to lead the rebellion, joining forces with other rebel leaders such as Tantia Tope and Rao Sahib. She fought bravely in several battles, including the Battle of Gwalior, where she was fatally wounded.
Rani Lakshmibai's legacy as a fearless warrior and symbol of Indian nationalism remains deeply revered in India. She is celebrated for her unwavering courage, sacrifice, and commitment to the cause of Indian independence.
`;
export const PindariWar = `Another name for the Pindari War of 1817-1818 is the Third Anglo-Maratha War. The Pindari War, which took place between 1817 and 1818, was a significant military campaign waged by the British East India Company against the Pindaris, a loose confederation of Maratha plunderers and mercenaries who operated in central India during the early 19th century.
The Pindaris, originally employed as irregular cavalry by the Maratha Empire, had become a formidable force of brigands who engaged in looting, plundering, and raiding across central India. They posed a serious threat to the stability and security of the region, particularly to the territories controlled by the British East India Company and its allies.
The British, alarmed by the activities of the Pindaris and seeking to assert their authority in central India, launched a military campaign to suppress them. The campaign was part of the larger strategy of British expansionism and consolidation of control over the Indian subcontinent.
The Pindari War was characterized by a series of military engagements between British forces, led by notable commanders such as Lord Hastings, and the Pindari bands. The British employed a combination of conventional military tactics and diplomacy to weaken and ultimately defeat the Pindaris.
One of the key battles of the Pindari War was the Battle of Kirkee in November 1817, where British forces decisively defeated the Pindaris, inflicting heavy casualties and capturing significant territory. Subsequent engagements further weakened the Pindaris' strength and compelled many of them to surrender or disband.
The Pindari War culminated in the defeat of the Pindaris and the suppression of their activities by British forces. As a result of the war, the British East India Company extended its control over central India and consolidated its dominance in the region.
The Pindari War had far-reaching implications for the political and military landscape of central India. It marked a significant victory for British imperialism and contributed to the decline of Maratha power in the region
`;

export const JamsetjiTata = `Jamsetji Tata (1839-1904) was a visionary Indian industrialist and the founder of the Tata Group, India's largest and most respected conglomerate. Born on March 3, 1839, in Navsari, Gujarat, into a Parsi family, Tata's early education was at Elphinstone College in Bombay (now Mumbai). 
He began his career in his father's trading firm, where he gained significant experience before venturing into his own entrepreneurial pursuits. In 1868, he founded a trading company that laid the foundation for the Tata Group. 
Tata's pioneering efforts led to the establishment of Tata Steel in 1907, India's first steel plant, which symbolized industrial self-sufficiency. He also founded Tata Power in 1910, a major power project that played a crucial role in electrifying Mumbai.
 Recognizing the importance of education and research, Tata funded the establishment of the Indian Institute of Science in Bangalore in 1909, which remains one of India's premier research institutions. 
His commitment to worker welfare and corporate social responsibility set high standards in labor rights and ethical business practices. Additionally, Tata ventured into the hospitality industry by founding the Taj Mahal Palace Hotel in Mumbai in 1903, which set new standards in luxury and service. 
Jamsetji Tata passed away on May 19, 1904, in Bad Nauheim, Germany, but his legacy of industrial innovation and social responsibility continues to shape modern India.
`;
export const IshwarChandraVidyasagar = `Ishwar Chandra Vidyasagar (1820–1891) was a prominent Indian scholar, social reformer, and key figure in the Bengal Renaissance. Born on September 26, 1820, in Birsingha, Bengal, Vidyasagar was renowned for his vast knowledge and earned the title "Vidyasagar," meaning "Ocean of Knowledge."
 He played a transformative role in modernizing the Bengali education system, integrating Western educational concepts while preserving traditional Indian wisdom. Vidyasagar was a vigorous advocate for women's rights, instrumental in passing the Widow Remarriage Act of 1856, and established numerous girls' schools, emphasizing the importance of women's education for societal advancement. 
His literary contributions, including the foundational Bengali text "Borno Porichoy" and translations of Sanskrit classics, significantly enriched Bengali literature. Known for his philanthropy, Vidyasagar utilized his resources to support education and aid the needy, earning widespread respect and admiration.
 His enduring legacy is marked by his commitment to educational and social reforms, which continue to inspire generations. Vidyasagar passed away on July 29, 1891, but his impact on Indian society remains profound and influential.
`;
export const SisterNivedita = `Sister Nivedita, born Margaret Elizabeth Noble (1867–1911), was a dedicated social worker, educator, and fervent supporter of Indian nationalism. Born in Ireland, she was deeply influenced by Swami Vivekananda's teachings and moved to India in 1898, where she was given the name Nivedita, meaning "The Dedicated One." 
She established a girls' school in Calcutta, advocating for women's education and empowerment. During the plague epidemic of 1899, she tirelessly worked on relief efforts, raising awareness about public health and hygiene. 
Nivedita played a significant role in the Indian nationalist movement, supporting the Swadeshi movement and collaborating with key cultural figures like Rabindranath Tagore to revive Indian art and heritage. Her literary works, including "Kali the Mother" and "The Web of Indian Life," aimed to foster a deeper understanding of Indian spirituality and culture. 
Nivedita's legacy endures through her contributions to education, social work, and the promotion of Indian culture and nationalism, making her a revered figure in India's history. She passed away on October 13, 1911, in Darjeeling, India.
`;
export const SyedAhmadKhan = `Sir Syed Ahmad Khan (1817–1898) was a prominent 19th-century Muslim reformer, educator, and philosopher in British India. Born into a family of Mughal aristocrats in Delhi, Sir Syed witnessed the decline of the Mughal Empire and the devastating aftermath of the Indian Rebellion of 1857, which profoundly influenced his life and thinking.
Sir Syed Ahmad Khan is widely regarded as the founder of Muslim nationalism in India. He advocated for modern education among Muslims at a time when many of them were lagging behind in terms of access to Western sciences and knowledge. Believing that education was crucial for the community's progress, he established the Muhammadan Anglo-Oriental College in Aligarh in 1875, which later became Aligarh Muslim University. 
In addition to his educational contributions, Sir Syed was a staunch advocate of Hindu-Muslim unity and social reform. He emphasized the need for both communities to collaborate in order to uplift India as a whole. His efforts towards social reform included challenging orthodox interpretations of Islam and encouraging a rational approach to religious and social issues.
Sir Syed Ahmad Khan's legacy extends beyond educational institutions and social reforms. He was a prolific writer and thinker whose works, such as "Asar-us-Sanadid" and "The Causes of the Indian Revolt," continue to be studied for their insights into Indian history and society.
`;
export const VernacularPress = `The Vernacular Press Act of 1878 marked a pivotal moment in India's colonial history, reflecting British efforts to suppress dissent and control public opinion through legislative means. Enacted during Lord Lytton's tenure as Viceroy of India, the act targeted Indian-language newspapers that were increasingly vocal in their criticism of British policies and administration.
 It empowered the colonial government to clamp down on publications deemed seditious or inflammatory, allowing for warnings, fines, and even the seizure of printing presses and materials for non-compliance. 
This draconian measure was met with widespread condemnation from Indian intellectuals, journalists, and nationalists who viewed it as an assault on freedom of expression and a tool to stifle dissent. 
Leaders like Surendranath Banerjee and Bal Gangadhar Tilak vociferously opposed the act, underscoring its discriminatory nature as it exempted English-language publications that typically supported colonial interests. 
The Vernacular Press Act galvanized the Indian press and fueled nationalist sentiments, ultimately contributing to its repeal in 1881 under pressure from public outcry and growing nationalist movements. Its legacy underscores the pivotal role of media freedom in the struggle against colonial rule and the broader fight for India's independence.
`;
export const MahadevGovindRanade = `Mahadev Govind Ranade (1842-1901) was a distinguished Indian scholar, social reformer, and judge, whose contributions significantly shaped the socio-political landscape of 19th-century India. Born on January 18, 1842, in Niphad, Maharashtra, Ranade pursued his education at Elphinstone College in Mumbai, where he earned a law degree.This academic background paved the way for his judicial career, culminating in his role as a judge in the Bombay High Court, where he was noted for his commitment to justice and fairness.
Ranade authored several important works that analyzed India's economic conditions and historical context, providing a scholarly foundation for future studies in these areas. His book, "Essays on Indian Economics," highlighted the importance of industrialization and economic reforms, advocating for modern agricultural practices and the development of industries.
As a social reformer, Ranade was a leading figure in addressing critical issues such as child marriage, the treatment of widows, and the caste system. In 1867, he co-founded the Prarthana Samaj, a reform movement aimed at promoting social justice and reforming Hindu religious practices.
Ranade's advocacy for women's rights was particularly notable. He was a strong supporter of women's education and worked tirelessly to improve the status of women. He supported the cause of widow remarriage and fought against child marriage, emphasizing the importance of education and empowerment for women.
In the political arena, Ranade played a crucial role in the founding of the Indian National Congress in 1885, which became a pivotal platform for India's struggle for independence.
`;
export const IndigoRevolt = `The Indigo Revolt of 1859 was a significant uprising by indigo farmers in Bengal against the oppressive practices of British planters. Indigo was a highly lucrative cash crop, and British planters forced local farmers to cultivate it under harsh conditions. The farmers were coerced into signing contracts that bound them to grow indigo on three-twentieths of their land, often at uneconomical rates. 
These contracts, known as the "tinkathia" system, led to a cycle of debt and poverty, as the prices paid for indigo were significantly lower than market rates, making it nearly impossible for farmers to repay the loans.
The revolt began in the Nadia district of Bengal and quickly spread to other regions. Farmers, facing unbearable exploitation, refused to grow indigo and defied the planters. They organized meetings, formed groups, and used non-violent methods to resist, including boycotting indigo planting and appealing to local authorities.
 The movement saw widespread participation and even gained support from local zamindars, who sympathized with the farmers' plight and sometimes protected them from the planters' retaliation.
The British initially responded with repression, deploying police and military forces to suppress the revolt. However, the scale of the uprising forced them to address the farmers' grievances. This led to the formation of the Indigo Commission in 1860, which confirmed the exploitative practices and recommended significant changes. As a result, the oppressive contracts were abolished, and the conditions of the indigo farmers gradually improved.
The Indigo Revolt of 1859 is considered a landmark in the history of Indian peasant movements. 
`;
export const INC = `The INC was founded on December 28, 1885, in Bombay (now Mumbai) by Allan Octavian Hume, a retired British civil servant, along with key Indian leaders such as Dadabhai Naoroji, Dinshaw Wacha, and Womesh Chunder Bonnerjee. The initial meeting was attended by 72 delegates, representing various regions and communities of India. Womesh Chunder Bonnerjee presided over the first session.
The primary objective of the INC was to create a platform for educated Indians to engage in civil and political dialogue and to voice their grievances to the British colonial authorities. The early leaders of the Congress were moderate in their approach, advocating for greater Indian participation in governance, economic reforms, and the protection of civil rights through petitions and discussions rather than confrontation.
The INC addressed several critical issues from the outset. It called for the inclusion of more Indians in the civil services and other government positions, the reduction of military expenditure borne by India, the separation of judicial and executive functions, and the introduction of representative legislative bodies.
The establishment of the INC marked the beginning of a new phase in the Indian freedom struggle. The early 20th century saw the rise of more radical leaders within the Congress, such as Bal Gangadhar Tilak, Bipin Chandra Pal, and Lala Lajpat Rai, who advocated for swaraj (self-rule) and more direct forms of resistance against British rule.
The Congress played a crucial role in negotiating the terms of India's independence with the British government, leading to the eventual partition of India and Pakistan in 1947. The legacy of the INC is marked by its pivotal role in uniting diverse groups and communities across India in the quest for self-rule and its contributions to the establishment of a democratic and independent Indian state.
Source: ChatGPT, Image Source: Wikipedia , Image details: The first session of INC
`;
export const INCIndianNationalArmy = `The formation of the Indian Army in 1895 marked a significant restructuring of the British Indian military forces. Prior to this, the army in India was divided among the three Presidency armies of Bengal, Bombay, and Madras, each functioning independently with its own command structure. 
The reorganization was partly a response to the Indian Rebellion of 1857, which exposed significant weaknesses in the existing military structure.Additionally, there was a growing recognition of the need to modernize the army to keep pace with technological advancements and changing military tactics.
On April 1, 1895, the British government officially unified the presidency armies into a single entity, known as the Indian Army.  The Indian Army was divided into four main commands: Bengal, Bombay, Madras, and Punjab, each overseen by a lieutenant general who reported directly to the Commander-in-Chief of India.
The reorganization of 1895 had a lasting impact on the Indian military. The newly formed Indian Army played a crucial role in maintaining internal security within British India and protecting the interests of the British Empire. It was actively involved in several key conflicts, including the Second Boer War, World War I, and World War II. The experience and sacrifices of Indian soldiers during these conflicts contributed to the growing nationalist sentiment and the eventual push for Indian independence.
Source: ChatGPT, Image source: Wikipedia

`;
export const IndianRebellion = `The Indian Rebellion of 1857, also known as the Sepoy Mutiny or the First War of Indian Independence, was a significant uprising against British rule, beginning on May 10, 1857.
 Several factors contributed to the rebellion, including widespread political discontent due to the annexation policies of the British East India Company, economic exploitation through heavy taxation and the destruction of local industries, and social and religious interference with traditional customs. 
The immediate spark was the introduction of the Enfield rifle, whose cartridges were rumored to be greased with cow and pig fat, offending both Hindu and Muslim soldiers. The rebellion started in Meerut and quickly spread to Delhi, where the rebels declared Bahadur Shah II, the last Mughal emperor, as their leader. 
The uprising then extended to other regions, including Kanpur, Lucknow, Jhansi, and Gwalior, with prominent leaders like Rani Lakshmibai, Tantia Tope, and Nana Sahib leading the resistance. Key events included the brutal sieges of Cawnpore and Lucknow. 
Despite the initial success and widespread nature of the rebellion, the British managed to suppress it by mid-1858 through military force and strategic diplomacy. The rebellion's aftermath led to the dissolution of the East India Company, the reorganization of the Indian Army, and significant administrative reforms under direct British Crown rule, marking the beginning of the British Raj.
 This uprising left a lasting legacy, fostering a strong sense of nationalism and laying the groundwork for India's eventual struggle for independence.
Source: ChatGPT, Image source: Wikipedia, image details:India in 1837 and 1857, showing East India Company-governed territories in pink
`;
export const SantalRebellion = `The Santal Rebellion of 1855-1856, also known as the Santal Hool, was a significant tribal uprising against British colonial rule and the exploitative practices of moneylenders and landlords in the Bengal Presidency, particularly in present-day Jharkhand, West Bengal, and Bihar. 
The rebellion was primarily led by the Santal people, an indigenous tribal community facing severe economic hardships due to heavy taxation, fraudulent land deals, and high-interest debts imposed by moneylenders and landlords. 
The British administration's neglect of Santal grievances and the encroachment of non-tribal settlers on their traditional lands further exacerbated their plight. On June 30, 1855, under the leadership of Sidhu and Kanhu Murmu, the Santals declared a rebellion, rallying tens of thousands of tribal people to fight against their oppressors. 
The revolt saw initial successes, with Santals attacking and destroying properties of moneylenders and British officials. However, the British forces eventually suppressed the rebellion through brutal military action, resulting in significant loss of life.
 The Santal Rebellion highlighted the deep-seated issues of economic exploitation and social injustice faced by tribal communities under colonial rule, and it remains a poignant chapter in the history of resistance against oppression in India.
Source: ChatGPT, Image source: Wikipedia
`;
export const IndianNationalAssociation = `The Indian National Association (INA) was formed in 1876 by Surendranath Banerjee and Ananda Mohan Bose in Calcutta (now Kolkata), marking one of the earliest organized efforts to promote Indian political interests and self-governance. 
The INA aimed to create a platform for educated Indians to voice their grievances against British colonial policies and to advocate for reforms. Key issues addressed by the INA included the demand for the inclusion of Indians in the civil services, the reduction of military expenditure borne by India, and the introduction of representative institutions.

 The association organized public meetings, petitions, and lectures to raise political awareness and foster a sense of nationalism among Indians. It also played a crucial role in mobilizing public opinion against repressive measures such as the Vernacular Press Act of 1878 and the lowering of the age limit for Indian Civil Service examinations. 
The INA's efforts laid the groundwork for more extensive political movements and organizations, such as the Indian National Congress, which was founded in 1885. The INA's formation was a significant step in the evolution of the Indian independence movement, as it articulated the aspirations of the Indian middle class and sought to unite diverse groups in the struggle for self-rule.
Source: ChatGPT, Image source: Wikipedia
`;

export const PartitionBengal = `The Partition of Bengal in 1905 was a significant and controversial event in Indian history, orchestrated by the British Viceroy, Lord Curzon. Announced on July 19, 1905, and implemented on October 16, 1905, the partition divided the large province of Bengal into two separate entities: Eastern Bengal and Assam, with a Muslim-majority population, and the western part comprising Bihar and Orissa, with a Hindu-majority population. 
The official reason given for the partition was administrative efficiency, as Bengal was deemed too large to govern effectively. However, the move was widely perceived as an attempt by the British to weaken the burgeoning nationalist movement by driving a wedge between the Hindu and Muslim communities. 
The partition sparked widespread protests and a strong anti-British sentiment across India, leading to the Swadeshi Movement, which involved the boycott of British goods and the promotion of Indian-made products. 
The intense opposition and nationwide unrest ultimately forced the British to annul the partition in 1911. This event highlighted the growing political consciousness among Indians and the increasing demand for self-governance, laying the groundwork for future struggles for independence.

Source -Chat GPT, Image Source: Wikipedia, Image details-Map showing the modern day nation of Bangladesh and Indian states of Bihar, Jharkhand, Orissa, Assam, Meghalaya, Arunachal Pradesh and parts of Nagaland and Manipur within the Province before division.
`;
export const BardoliSatyagraha = `The Bardoli Satyagraha of 1928 was a significant episode in India's struggle for independence, led by Sardar Vallabhbhai Patel. The movement took place in the Bardoli taluka of Gujarat, where the British colonial government had imposed a steep 30% increase in land revenue taxes, despite the region suffering from floods and famine. 
The local peasants, unable to bear this oppressive tax burden, organized a non-violent protest against the government's decision. Under Patel's leadership, the farmers collectively refused to pay the increased taxes, initiating a form of civil disobedience. 
The movement was meticulously organized, with volunteers ensuring that the farmers maintained discipline and unity. The British authorities responded with severe measures, including confiscating property and cattle, and auctioning off lands.
 However, the relentless resolve of the Bardoli farmers, coupled with effective communication and strategic non-violent resistance, garnered widespread support and media attention. The success of the Bardoli Satyagraha forced the British government to set up an inquiry commission, which eventually ruled in favor of the farmers, reversing the tax hike and returning the confiscated properties. 
This victory not only elevated Sardar Patel as a prominent leader in the Indian independence movement, earning him the title "Sardar" (leader), but also demonstrated the power of organized, non-violent resistance and inspired future civil disobedience movements across India.
`;
export const RowlattSatyagraha = `The Rowlatt Satyagraha of 1919 was a significant protest against the repressive Rowlatt Act passed by the British colonial government in India, aimed at curbing civil liberties and suppressing dissent. The Act was named after Sir Sidney Rowlatt, a British judge, and it authorized the government to imprison any person without trial and to detain individuals without evidence.
 This draconian legislation was seen by many Indians as a betrayal of the promises of democratic reforms made during World War I. The Rowlatt Act particularly outraged leaders like Mahatma Gandhi and Bal Gangadhar Tilak, who saw it as an assault on civil rights and an attempt to stifle nationalist aspirations.
In response, Mahatma Gandhi called for a nationwide protest through non-violent civil disobedience. The Satyagraha movement saw widespread participation across India, with strikes, demonstrations, and protests organized in various cities. People from all walks of life, including students, lawyers, merchants, and workers, joined the movement in solidarity against the unjust Act.
Despite the brutal repression and the eventual enactment of the Rowlatt Act, the Satyagraha had a profound impact on the Indian independence movement. It galvanized public opinion against British rule, exposed the repressive nature of colonial governance, and highlighted the unity and resolve of Indians in their quest for freedom.
Source: ChatGPT, Image source: Wikipedia, Image details: The Anarchical and Revolutionary Crimes Act of 1919, popularly known as the Rowlatt Act.
`;
export const MokshagundamVisvesvaraya = `Sir Mokshagundam Visvesvaraya (1861-1962) was a distinguished Indian engineer, scholar, statesman, and the Diwan of Mysore, known for his pivotal role in the modernization of India’s infrastructure and industrial landscape. 
Born on September 15, 1861, in Muddenahalli, Karnataka, Visvesvaraya's early education was marked by excellence, culminating in a degree in civil engineering from the College of Engineering, Pune. 
His engineering acumen first gained prominence with his innovative flood control system for the city of Hyderabad in 1909 and the design and construction of the Krishna Raja Sagara Dam in Mysore, which significantly boosted irrigation and drinking water supply in the region.
 As Diwan of Mysore from 1912 to 1918, he spearheaded various initiatives, including the establishment of the Mysore University, the founding of the Bank of Mysore, and the setting up of industries like the Mysore Iron and Steel Works. 
Visvesvaraya was knighted by the British government in 1915 for his services to the public and was awarded the Bharat Ratna, India’s highest civilian honor, in 1955. His contributions extended beyond engineering, as he played a key role in promoting education and industrialization, leaving an indelible mark on India's development trajectory. His legacy is celebrated annually on September 15, recognized as Engineers' Day in India, in honor of his outstanding contributions to engineering and nation-building.
`;
export const PanditaRamabai = `Pandita Ramabai (1858-1922) was a pioneering Indian social reformer, scholar, and activist who made significant contributions to the empowerment of women and the upliftment of marginalized communities in India. Born on April 23, 1858, in a conservative Brahmin family in Maharashtra, Ramabai's early education was exceptional; she mastered Sanskrit, earning the title "Pandita" for her scholarly prowess. Despite societal constraints, she became a fervent advocate for women's education and rights. Ramabai's life was marked by personal tragedy, including the loss of her parents and husband, which deepened her empathy for the struggles faced by widows and women in distress.
In 1882, she traveled to Britain to study and later moved to the United States, where she converted to Christianity and wrote her seminal work, "The High-Caste Hindu Woman," highlighting the plight of Hindu widows and advocating for their education and emancipation. Upon her return to India, Ramabai founded the Arya Mahila Samaj in Pune, aiming to promote women's education and social reform. In 1889, she established the Mukti Mission at Kedgaon, near Pune, which provided shelter, education, and vocational training to destitute women, widows, and orphans, empowering them to lead independent lives.
Pandita Ramabai's efforts were instrumental in challenging the oppressive social norms of her time, and she faced considerable opposition for her progressive views and actions. Nevertheless, her legacy as a crusader for women's rights and education endures, influencing generations of social reformers in India.
`;
export const JallianwalaBagh = `The Jallianwala Bagh massacre, also known as the Amritsar massacre, occurred on April 13, 1919, and stands as one of the most horrific events in the history of British colonial rule in India.
On this fateful day, a large, peaceful gathering of thousands of unarmed men, women, and children had assembled in Jallianwala Bagh, a public garden in Amritsar, Punjab, to celebrate the Punjabi festival of Baisakhi and to protest against the repressive Rowlatt Act. 
Brigadier General Reginald Dyer, without warning the crowd to disperse, ordered his troops to block the main exits and opened fire on the gathering, directing continuous shooting for about ten minutes. The troops fired around 1,650 rounds of ammunition into the dense crowd, resulting in the deaths of an estimated 379 people, though Indian sources claim the number of fatalities to be over 1,000, with many more wounded.
The narrow exits of the enclosed garden compounded the chaos, leading to a tragic stampede, while numerous individuals jumped into a well to escape the bullets, resulting in additional casualties.
Prominent leaders like Mahatma Gandhi called for non-cooperation and civil disobedience against British rule.The Jallianwala Bagh massacre remains a stark reminder of the brutalities of colonial rule and the high price paid for India’s eventual independence.
`;
export const AIWC = `The All India Women's Conference (AIWC) was established in 1927 as a pivotal organization dedicated to advocating for women's rights and social reform in India. The inaugural conference took place in Poona (now Pune), spearheaded by a group of progressive women led by Margaret Cousins, an Irish-born feminist and theosophist who had made India her home. The primary objective of the AIWC was to address critical issues concerning women's education, social welfare, and legal rights.
 The conference brought together women from various backgrounds, including notable leaders like Sarojini Naidu, Kamaladevi Chattopadhyay, and Hansa Mehta, who played instrumental roles in India's freedom struggle and in promoting gender equality.
The AIWC focused on several key areas like education, social reforms The AIWC (such as child marriage, purdah (the practice of secluding women from public observation), and dowry), legal rights (such as the Sarda Act of 1929, which sought to prevent child marriages by setting a minimum age for marriage) and economic empowerment, aiming to make women economically independent.
The organization also established a network of branches across India, fostering a sense of unity and collective action among women. Over the decades, the AIWC expanded its focus to include issues like health, sanitation, and political participation, becoming a significant force in the broader movement for women's emancipation in India.
By bringing women together from diverse regions and backgrounds, the AIWC not only amplified the voices of women but also laid the groundwork for future advancements in gender equality. Its efforts significantly contributed to the progressive transformation of Indian society and the empowerment of women, leaving an enduring legacy that continues to inspire gender rights activism in India.
Source: ChatGPT, Image Credits: Wiki Commons, Image Details:  Margaret Cousins
`;
export const GhadarMovement = `The Ghadar Movement, founded in 1913, was a revolutionary campaign initiated by Indian expatriates in the United States and Canada aimed at overthrowing British colonial rule in India. The movement began under the leadership of Har Dayal, Sohan Singh Bhakna, and other Indian nationalists who were part of the burgeoning Indian diaspora. 
The Ghadar Party established its headquarters in San Francisco, where it launched the publication of the "Ghadar" newspaper, which became a key tool for spreading revolutionary ideas and galvanizing support among Indian immigrants and soldiers serving in the British Indian Army.
The Ghadarites were motivated by widespread discontent with British colonial policies, including economic exploitation, racial discrimination, and political oppression. Their goal was to incite a mass uprising in India, coordinated through the dissemination of revolutionary literature and the recruitment of Indian expatriates and soldiers.
However, the British intelligence infiltrated the Ghadar network, leading to the arrest and execution of many key leaders and activists. Despite these setbacks, the Ghadar Movement left a lasting impact on the Indian independence struggle.The legacy of the Ghadar Movement is remembered for its bold and uncompromising stand against British rule, highlighting the global dimensions of the Indian independence movement and the contributions of the Indian diaspora.
`;
export const ISI = `The Indian Statistical Institute (ISI) was established in 1931 in Kolkata by the renowned Indian statistician Prasanta Chandra Mahalanobis. The founding of the ISI marked a significant milestone in the development of statistical science in India and aimed to promote research, education, and application of statistics for national development.
Initially started as a small statistical laboratory in the Physics Department of the Presidency College, Kolkata, the ISI rapidly grew in scope and influence. The institute's primary objective was to foster the theoretical and applied aspects of statistics. Mahalanobis's innovative contributions, such as the Mahalanobis distance and his work on large-scale sample surveys, established the ISI as a pioneering institution in statistical research and applications.
Under Mahalanobis's leadership, the ISI played a crucial role in the formulation of India's Five-Year Plans, providing vital statistical data and methodologies that informed economic policy and planning. The institute also established collaborations with various government departments and industries, promoting the use of statistical techniques in diverse fields such as agriculture, health, and industry.
Over time, the institute expanded its presence with additional centers in Delhi, Bangalore, Chennai, and other locations, further cementing its role as a national hub for statistical education and research.
`;
export const KamaladeviChattopadhyay = `Kamaladevi Chattopadhyay (1903-1988) was a remarkable Indian social reformer, freedom fighter, and cultural advocate, whose multifaceted contributions significantly shaped modern India. Born on April 3, 1903, in Mangalore, Karnataka, Kamaladevi was deeply influenced by her progressive family and early exposure to social issues. 
A pioneering feminist, she was one of the first Indian women to stand for legislative office, running for the Madras Provincial Legislative Assembly in 1926. Kamaladevi was actively involved in the Indian independence movement, joining the Salt March in 1930 and playing a crucial role in the Civil Disobedience Movement. Her efforts led to her arrest and imprisonment by the British authorities.
Beyond her political activism, Kamaladevi is celebrated for her immense contribution to the revival and promotion of Indian handicrafts, handlooms, and theatre. She was instrumental in establishing key institutions like the Sangeet Natak Akademi, All India Handicrafts Board, the Crafts Council of India, and the National School of Drama.
 Her initiatives helped preserve traditional crafts and provided livelihoods to countless artisans across India. As a passionate advocate for women's rights, she worked tirelessly to improve the socio-economic conditions of women, founding the All India Women's Conference and pushing for educational and economic opportunities for women.
For her outstanding service, she was awarded numerous honors, including the Padma Bhushan in 1955 and the Padma Vibhushan in 1987. Kamaladevi Chattopadhyay's life and work remain an enduring inspiration, embodying the spirit of resilience, creativity, and dedication to social justice and cultural preservation.
`;
export const PrasantaChandraMahalanobis = `Prasanta Chandra Mahalanobis (1893-1972) was a pioneering Indian scientist and applied statistician whose contributions profoundly influenced the fields of statistics, economics, and planning in India. Born on June 29, 1893, in Calcutta (now Kolkata), he was educated at Presidency College and the University of Calcutta, where he excelled in physics and mathematics. He later studied at the University of Cambridge, where he further honed his mathematical skills and developed a keen interest in statistics.
Mahalanobis returned to India in the early 1920s and embarked on a career that would revolutionize statistical practice in the country. In 1931, he founded the Indian Statistical Institute (ISI) in Calcutta, envisioning it as a center for research, education, and application of statistics to various fields, including agriculture, economics, and planning.
Mahalanobis played a pivotal role in shaping India's economic policies during the post-independence period. He was instrumental in establishing the Planning Commission of India in 1950 and was a key architect of India's Second Five-Year Plan (1956-1961), which emphasized rapid industrialization and economic development through systematic planning and resource allocation based on statistical data.
For his contributions, Mahalanobis received numerous accolades, including the Padma Vibhushan, India's second-highest civilian honor, in 1968.
Prasanta Chandra Mahalanobis's legacy continues to resonate in India and beyond, with the Indian Statistical Institute remaining a prestigious institution dedicated to advancing statistical research and education.
`;
export const SrinivasaRamanujan = `Srinivasa Ramanujan (1887-1920) was an exceptional Indian mathematician whose innate brilliance and self-taught insights revolutionized the field of mathematics. Born on December 22, 1887, in Erode, Tamil Nadu, Ramanujan made groundbreaking contributions to number theory, infinite series, and continued fractions, among other areas. Despite facing significant obstacles, including poverty and limited formal education, he independently formulated numerous complex mathematical theorems and identities, often driven by intuition and divine inspiration as he believed. His collaboration with mathematician G.H. Hardy at Cambridge University in 1914 propelled him onto the international stage, where his discoveries in areas like mock theta functions and partition theory earned him recognition as one of the greatest mathematicians of his time. Ramanujan's work continues to influence and inspire mathematicians worldwide, serving as a testament to the power of pure intellect and perseverance in the pursuit of knowledge. His life and legacy remain a symbol of extraordinary talent and the limitless potential of the human mind in the realm of mathematical inquiry.
 His discoveries expanded the frontiers of mathematical knowledge, introducing new ideas that continue to influence fields like number theory and mathematical physics.
Among his notable achievements are the Ramanujan-Hardy asymptotic formula for partitions.His "Lost Notebook," containing unpublished results found after his death, further demonstrated his extraordinary insights into mathematical patterns and series.
His legacy is commemorated through numerous awards and honors, including the Fellowship of the Royal Society, the highest honor for a mathematician, and the establishment of the Ramanujan Prize by the International Centre for Theoretical Physics in Trieste, Italy, which recognizes young mathematicians who display exceptional talent.
Despite his tragically early death at the age of 32, Srinivasa Ramanujan's impact on mathematics remains enduring, with his formulas and conjectures continuing to spark new discoveries and investigations into the mysteries of numbers and mathematical structures.
`;
export const KandukuriVeeresalingam = `Kandukuri Veeresalingam (1848-1919) was a prominent social reformer and writer from Rajahmundry, Andhra Pradesh, whose pioneering efforts significantly impacted Indian society during the colonial era. Recognizing the plight of women in traditional Hindu society, Veeresalingam dedicated his life to advocating for their rights and empowerment. He was a vocal critic of social evils such as child marriage and the illiteracy of women, advocating instead for their education and liberation from oppressive customs.
In 1874, Veeresalingam published "Rajasekhara Charitamu," the first modern Telugu novel, which addressed issues of women's rights and societal reform. His literary works, including essays, plays, and articles, challenged conservative norms and promoted progressive ideas. In 1881, he established the first school for girls in India, the Hindu High School for Girls in Dowlaiswaram, aiming to provide education and skills that would enable women to lead independent lives.
Veeresalingam also campaigned vigorously for widow remarriage, believing it was essential for women's social and economic well-being. Despite facing opposition from orthodox quarters, he remained steadfast in his advocacy for social reform until his death on May 27, 1919. His legacy continues to inspire movements for women's rights and social equality in India, cementing his reputation as a visionary who laid the groundwork for gender empowerment and progressive social change.
`;
export const NandalalBose = `Nandalal Bose (1882-1966) was a celebrated Indian painter and artist whose work left an indelible mark on the Indian art scene during the early to mid-20th century. Born on December 3, 1882, in Bihar, Bose was deeply influenced by the Bengal School of Art, particularly under the guidance of Abanindranath Tagore. Known for his mastery of both traditional and modern art forms, Bose's paintings often depicted Indian mythological themes, rural life, and socio-political issues with a distinctive blend of classical techniques and contemporary styles. His emphasis on indigenous themes and revival of traditional Indian art forms earned him widespread acclaim, and he played a pivotal role in the nationalist art movement, using his art as a medium to express and foster a sense of Indian identity and cultural pride. Bose's contributions extended beyond painting; he was also a respected educator who served as the principal of the Kala Bhavana, Visva-Bharati University, founded by Rabindranath Tagore. His legacy continues to influence Indian art, inspiring generations of artists with his unique blend of artistic vision and cultural revivalism.
`;
export const MeghnadSaha = `Meghnad Saha (1893-1956) was a distinguished Indian physicist and astrophysicist whose pioneering work significantly advanced our understanding of stellar atmospheres and the composition of stars. Born on October 6, 1893, in Shaoratoli, Bengal (now in Bangladesh), Saha showed exceptional promise in mathematics and physics from an early age. He completed his education at Presidency College, Calcutta, where he later became a professor and conducted groundbreaking research.
Saha's most notable contribution to astrophysics is the Saha ionization equation, which he formulated in 1920. This equation describes the relationship between the ionization states of atoms in a gas and the temperature and pressure of the gas.
In addition to his work on the Saha ionization equation, he made significant contributions to the study of thermal ionization and the analysis of stellar spectra. His research not only deepened our understanding of the physical properties of stars but also influenced the development of spectroscopic techniques in astronomy.
Beyond his scientific achievements, Saha was a staunch advocate for scientific research and education in India. He served as the President of the Indian Science Congress in 1934 and was instrumental in shaping science policy in India.
Meghnad Saha's legacy continues to be celebrated in India and worldwide. He received numerous accolades during his lifetime, including the Padma Bhushan in 1954, in recognition of his contributions to science. The Saha Institute of Nuclear Physics in Kolkata, founded in his honor, remains a leading center for research in physics and astrophysics in India.
`;
export const RajkumariAmritKaur = `Rajkumari Amrit Kaur (1889-1964) was a prominent Indian freedom fighter, social reformer, and politician who played a significant role in shaping India's social and political landscape during the independence movement and post-independence era. Born into the royal family of Kapurthala, Punjab, on February 2, 1889, Kaur received a privileged upbringing but chose a path dedicated to public service and social reform.
Kaur's journey as a social reformer began with her involvement in the women's rights movement and the struggle for Indian independence. She joined Mahatma Gandhi's Non-Cooperation Movement in the 1920s and became an active participant in civil disobedience campaigns against British colonial rule. Her commitment to social justice and equality led her to work closely with leaders such as Jawaharlal Nehru and Sarojini Naidu, advocating for women's education, healthcare reform, and the abolition of untouchability.
After India gained independence in 1947, Kaur continued to make significant contributions to public life. She was appointed as the Minister of Health in the interim government of India and later served as the first Health Minister of independent India from 1947 to 1957. During her tenure, Kaur focused on advancing public health initiatives, expanding healthcare infrastructure, and promoting policies to improve sanitation and combat diseases such as tuberculosis.
Kaur's dedication to public service extended beyond her role as a minister. She was actively involved in international health organizations and played a crucial role in shaping India's healthcare policies and programs. Kaur's legacy is also marked by her efforts in founding and supporting institutions such as the All India Institute of Medical Sciences (AIIMS) in New Delhi, which has since become one of India's premier medical institutions.
Throughout her life, Rajkumari Amrit Kaur remained committed to the ideals of social justice, public service, and women's empowerment. Her contributions to India's independence struggle and her pioneering efforts in healthcare and social reform continue to inspire generations of Indians, making her a revered figure in Indian history and a symbol of resilience, compassion, and leadership.
`;
export const KarachiResolution = `The Karachi Resolution of 1931, also known as the "Resolution on Fundamental Rights and Economic Policy," was a pivotal document in India's quest for independence from British rule. Adopted during the Indian National Congress session held in Karachi from March 26 to 31, 1931, the resolution represented a definitive shift in the Congress's approach to the nationalist movement.
Key details of the Karachi Resolution include:
The resolution unequivocally demanded complete independence for India, marking a departure from earlier demands for dominion status or limited autonomy within the British Empire. It declared January 26, 1930, as the inaugural Independence Day, celebrated thereafter as such by the Indian National Congress.
Building on Mahatma Gandhi's call for nonviolent resistance, the resolution reiterated Congress's commitment to civil disobedience and non-cooperation with British authorities until India achieved full sovereignty.
The resolution emphasized national unity across religious, linguistic, and regional diversities within India. It recognized the importance of protecting fundamental rights and promoting social justice for all citizens of India.
It outlined the Congress's vision for economic development, advocating for policies that would promote industrialization, agricultural reforms, and improved living standards for the Indian population.
The resolution sought international support for India's struggle for independence, appealing to the global community to recognize India's aspirations for self-determination and sovereignty.
The Karachi Resolution galvanized widespread support across India and solidified the Congress's position as the leading political force in the nationalist movement. It provided a clear and unified vision for India's future as a free nation, setting the stage for subsequent negotiations and actions that would ultimately lead to independence in 1947.
`;
export const PingaliVenkayya = `Pingali Venkayya (1876-1963) was an Indian freedom fighter and the designer of the Indian national flag. Born on August 2, 1876, in Bhatlapenumarru, a small village in Andhra Pradesh, Venkayya had a multifaceted career as an agriculturist, geologist, educationalist, and linguist. However, his most enduring legacy is his contribution to the creation of the Indian national flag.
Venkayya showed early academic promise and went on to pursue his studies at the Cambridge Mission School. His diverse interests led him to learn Japanese and study geology, agriculture, and education, which further broadened his intellectual horizons.
Venkayya's involvement in the Indian freedom movement was significant. He participated in the Anglo-Boer War in Africa as part of the British Indian Army, which provided him with a broader perspective on colonialism and the importance of national symbols in unifying a country's populace.
Venkayya's most notable contribution came during the freedom struggle, where he dedicated himself to the design of a national flag. He presented various designs to Mahatma Gandhi, who was deeply impressed by his work. After several iterations and consultations, the final design was adopted by the Indian National Congress on July 22, 1947, shortly before India gained independence.
The national flag designed by Venkayya featured three horizontal stripes: saffron at the top, white in the middle, and green at the bottom. In the center of the white stripe was a navy blue Ashoka Chakra, a 24-spoke wheel, symbolizing the eternal wheel of law and dharma. Each color had a symbolic meaning: saffron for courage and sacrifice, white for truth and peace, and green for faith and chivalry. The Ashoka Chakra represented righteousness and progress.
After India's independence, Venkayya lived a relatively quiet life. Despite his significant contribution, he did not receive the widespread recognition he deserved during his lifetime. He passed away on July 4, 1963.
Pingali Venkayya's legacy as the designer of the Indian national flag remains a source of pride for the nation. His work is celebrated as a symbol of India's unity and independence.
`;
export const DirectActionDayriots = `Direct Action Day riots of 1946, which is also known as the Great Calcutta Killings. The Direct Action Day riots marked one of the bloodiest episodes in the waning days of British colonial rule in India, particularly in the region of Bengal.
The Muslim League, led by Muhammad Ali Jinnah, called for Direct Action Day to demand the creation of Pakistan, a separate state for Muslims, as a response to the deadlock with the Indian National Congress over the structure of post-independence India.The call for Direct Action was intended as a peaceful demonstration, but tensions between the Hindu and Muslim communities in Bengal were already high.
On August 16, 1946, massive riots broke out in Calcutta (now Kolkata), the capital of Bengal. What began as a political demonstration quickly escalated into communal violence.
Over the next few days, brutal clashes occurred between Hindus and Muslims. Entire neighborhoods were engulfed in the violence, with homes, businesses, and places of worship being attacked and destroyed.
The riots resulted in the deaths of approximately 4,000 people, though unofficial estimates suggest the number could be much higher. Tens of thousands were injured, and many more were displaced.
The violence in Calcutta set off a chain reaction of communal riots in other parts of Bengal and beyond, exacerbating the already volatile situation as the subcontinent moved towards partition.
The riots highlighted the deep-seated religious and communal divides that the British colonial policy of "divide and rule" had exacerbated over the decades.
The events of Direct Action Day and the subsequent violence underscored the urgency and complexity of the partition process, ultimately leading to the creation of the separate states of India and Pakistan in 1947.

The Direct Action Day riots were a grim prelude to the widespread violence and displacement that would accompany the partition of India in 1947.
The Bengal massacre of 1946 remains a dark chapter in the history of India and Pakistan, symbolizing the catastrophic impact of communalism and the devastating consequences of political and religious divisions.
`;
export const MoplahRebellion = `Moplah (or Mappila) Rebellion of 1921, involved a series of violent uprisings in the Malabar region of Kerala.The Moplahs (or Mappilas) were Muslim tenants in the Malabar region of Kerala, who faced severe oppression and exploitation by the predominantly Hindu landlords (jenmis) and the British colonial administration.

The Khilafat Movement, which sought to protect the Ottoman Caliphate after World War I, and the Non-Cooperation Movement led by Mahatma Gandhi, provided a broader political context that galvanized the Moplahs.

The Moplah Rebellion began in August 1921 as an agrarian uprising but quickly escalated into a violent communal conflict.Initial protests against the British authorities and landlords turned into attacks on Hindu landlords and other symbols of authority.T

he violence led to the killing of numerous landlords and their supporters, as well as the destruction of property. Reports of atrocities committed by the rebels included forced conversions, massacres, and pillaging.

The British colonial government responded with a heavy hand, deploying military force to suppress the rebellion.The rebellion was eventually crushed by the end of 1921, but not without significant loss of life and widespread destruction.

The rebellion left deep scars in the communal fabric of Malabar, with lasting impacts on Hindu-Muslim relations in the region.The Moplah Rebellion of 1921, often referred to in Kerala's history, remains a controversial and complex event, reflecting the multifaceted nature of social and political upheaval in colonial India.
Source: ChatGPT, Image Credits: Wiki Commons, Image Details: South Malabar, areas in red show Taluks affected by the rebellion
`;
export const AzadHindFauj = `The Azad Hind Fauj, also known as the Indian National Army (INA), was a significant force in the Indian independence movement during World War II. Established in 1942 by Mohan Singh in Southeast Asia with the support of Imperial Japan, the INA aimed to secure India's independence from British rule through armed struggle.
It gained prominence under the leadership of Subhas Chandra Bose, a charismatic and determined leader who had previously been a prominent figure in the Indian National Congress. Bose took command of the INA in 1943 after escaping house arrest in India and making his way to Japan.
The primary objective of the INA was to liberate India from British colonial rule. Subhas Chandra Bose envisioned a free India and rallied support with the slogan "Chalo Dilli" (March to Delhi).The INA's ideology was based on the principles of nationalism, patriotism, and the unification of all Indians irrespective of religion or caste.
The INA fought alongside Japanese forces in several key battles against the British and Allied forces like in the Burma (now Myanmar) front, particularly in the battles of Imphal and Kohima in 1944. Despite initial successes, the INA faced severe challenges, including difficult terrain, supply shortages, and eventually, the overwhelming strength of the Allied forces.
The INA's fortunes waned as the tide of World War II turned against the Axis powers. Following the defeat of Japanese forces in Southeast Asia, the INA soldiers were captured, and the movement collapsed. Bose's mysterious death in a plane crash in 1945 further demoralized the INA forces.
The legacy of the INA is complex but significant. While it did not achieve its immediate goal of militarily liberating India, it played a crucial role in inspiring Indian nationalism and demonstrating the possibility of armed resistance against colonial rule.
Source: ChatGPT, Image Credits: Wiki Commons, Image details: Azad Hind Fauj Flag
`;
export const TVSundaramIyengar = `T.V. Sundaram Iyengar (1877-1955) was a visionary industrialist and entrepreneur who played a pivotal role in transforming India's automotive industry. Born in Thirukkurungudi in Tamil Nadu, Sundaram Iyengar began his career as a lawyer but soon turned his attention to business. In 1911, he founded T.V. Sundaram Iyengar and Sons Limited, initially focusing on bus transport services. His company introduced efficient and reliable bus services, revolutionizing public transportation in South India.
Recognizing the potential of the automotive sector, Sundaram Iyengar diversified his business interests. He founded the Southern Roadways Limited in 1946, which later evolved into TVS Motor Company, one of India's largest two-wheeler manufacturers. His commitment to quality and innovation laid the foundation for the TVS Group, which expanded into various sectors including automotive components, finance, and electronics.
Sundaram Iyengar's legacy extends beyond his business achievements. He was known for his ethical business practices, employee welfare initiatives, and contributions to community development. His emphasis on quality, customer satisfaction, and social responsibility continues to influence the TVS Group's operations today. Sundaram Iyengar passed away in 1955, but his entrepreneurial spirit and contributions to India's industrial growth remain influential, making him a revered figure in the history of Indian industry.
`;
export const ArdeshirBurjorjiSorabjiGodrej = `Ardeshir Burjorji Sorabji Godrej (1868-1936) was a pioneering Indian industrialist and the founder of the Godrej Group, one of India's most iconic and diversified conglomerates. Born on March 26, 1868, in Bombay (now Mumbai), Ardeshir initially studied law but soon realized his true passion lay in entrepreneurship and innovation.
His entrepreneurial journey began with an unsuccessful venture into medical equipment. Undeterred by this initial failure, he turned his attention to the manufacture of locks, recognizing a gap in the market for high-quality, indigenous products.
In 1897, Ardeshir founded Godrej & Boyce Manufacturing Company, with a focus on producing high-quality locks. His dedication to innovation and quality quickly paid off, and Godrej locks became synonymous with security and reliability. Ardeshir's ingenuity was further demonstrated in 1902 when he designed and manufactured the first Indian safe, which became a huge success and solidified the company's reputation.
Under Ardeshir's leadership, the Godrej Group expanded its product range. In 1918, he ventured into the manufacture of soap, creating India's first soap made from vegetable oil, a significant departure from the animal fat-based soaps prevalent at the time which not only catered to the Indian market's needs but also adhered to the ethical and religious sensibilities of a large segment of the population.
Ardeshir Godrej passed away in 1936, leaving behind a legacy of industrial excellence and innovation.Today, the Godrej Group operates in diverse sectors, including consumer goods, real estate, appliances, and agriculture, continuing to uphold the values and principles established by its founder.
`;
export const KakoriConspiracy = `The Kakori Conspiracy, also known as the Kakori Train Robbery, was a landmark event in the Indian independence movement, orchestrated by the Hindustan Republican Association (HRA) on August 9, 1925. The HRA, a revolutionary group aiming to overthrow British rule through armed resistance, targeted a train carrying government funds near the village of Kakori, close to Lucknow in present-day Uttar Pradesh.
Led by key revolutionaries such as Ram Prasad Bismil, Ashfaqulla Khan, and Chandrasekhar Azad, the group successfully halted the train and seized the government treasury onboard. The heist aimed to challenge British authority, inspire broader participation in the independence struggle, and finance further revolutionary activities. In response, the British government launched a massive crackdown, resulting in the arrest and subsequent trial of several HRA members. 
Ram Prasad Bismil, Ashfaqulla Khan, Roshan Singh, and Rajendra Lahiri were sentenced to death, while others received long prison sentences. The Kakori Conspiracy significantly galvanized the Indian independence movement, symbolizing the courage and sacrifice of young revolutionaries and highlighting the repressive measures of the British colonial regime.
Source: ChatGPT, Image Credits: Wiki Commons Image Details: Seen in this group photo are: (from left to right)1.Yogesh Chatterji, 2.Prem Krishna Khanna, 3.Mukundi Lal Gupta, 4.Vishnu Sharan Dublish, 5.Suresh Chandra Bhattacharya, 6.Ram Krishna Khatri, 7.Manmath Nath Gupta, 8.Raj Kumar Sinha, 9.Roshan Singh, 10.Ram Prasad 'Bismil', 11.Rajendra Lahiri, 12.Govind Charan Kar, 13.Ram Dulare Trivedi, 14.Ram Nath Pandey, 15.Shachindra Nath Sanyal, 16.Bhupendra Nath Sanyal & 17.Pranvesh Chatterji
`;
export const SatyendraNathBose = `Satyendra Nath Bose was an eminent Indian physicist whose pioneering work in quantum mechanics and theoretical physics has left an indelible mark on modern science. Born on January 1, 1894, in Calcutta (now Kolkata), India, Bose was a prodigious student who excelled in mathematics and science. 
He earned his Bachelor’s and Master’s degrees from the University of Calcutta, where his academic brilliance shone brightly. Bose’s most significant contribution to physics came in 1924 when he derived Planck’s quantum radiation law without using classical physics. This groundbreaking work led to the development of Bose-Einstein statistics, which describe the statistical distribution of indistinguishable particles known as bosons.
Unable to get his paper published initially, Bose sent it directly to Albert Einstein, who recognized its significance. Einstein translated the paper into German and submitted it to the prestigious Zeitschrift für Physik journal. The collaboration between Bose and Einstein paved the way for the theoretical prediction of a new state of matter, the Bose-Einstein condensate. This theoretical prediction was experimentally confirmed in 1995, earning the researchers a Nobel Prize in Physics, although Bose himself did not receive the Nobel.
Bose’s work extended beyond quantum mechanics. He made notable contributions to statistical mechanics, X-ray diffraction, and the theory of relativity. He was a dedicated educator and a professor at the University of Dhaka and later at the University of Calcutta, where he influenced generations of students and researchers. His profound impact on science was recognized through numerous honors, including the Padma Vibhushan, one of India’s highest civilian awards.
Satyendra Nath Bose passed away on February 4, 1974, in Kolkata, leaving behind a rich legacy of scientific innovation and discovery. The term "boson," named in his honor, stands as a testament to his lasting impact on the scientific community.
Source: ChatGPT, image source: wikipedia image details: 
`;
export const LakshmiSehgal = `Lakshmi Sehgal was a prominent Indian freedom fighter, physician, and politician, best known for her role as a leading figure in the Indian National Army (INA) during the struggle for India's independence. 
Born on October 24, 1914, in Madras (now Chennai), Lakshmi Swaminathan was raised in a progressive family that valued education and social justice. She pursued a medical degree from Madras Medical College and later specialized in gynecology and obstetrics.
In 1940, while practicing medicine in Singapore, she became involved with the Indian independence movement. She met Subhas Chandra Bose, the charismatic leader of the INA, who was forming an army to fight against British rule. Inspired by his vision, she joined the INA and was appointed as the leader of its women's regiment, named the Rani of Jhansi Regiment, in honor of the legendary warrior queen. She took on the name Lakshmi Sahgal and became widely known as Captain Lakshmi.
Captain Lakshmi played a crucial role in recruiting and training women soldiers, symbolizing the active participation of women in the fight for independence. Her leadership and dedication were instrumental in mobilizing support for the INA's cause and challenging traditional gender roles in Indian society. 
After World War II and the dissolution of the INA, Lakshmi Sahgal returned to India, where she continued her medical practice and remained active in public service and politics. She joined the Communist Party of India (Marxist) and worked tirelessly for social justice, women's rights, and healthcare for the underprivileged.
Lakshmi Sahgal passed away on July 23, 2012, in Kanpur, Uttar Pradesh, leaving behind a legacy of courage, commitment, and service. Her life exemplified the spirit of selfless dedication to the causes of national freedom and social equity, making her an enduring icon in India's history.
Source: ChatGPT, image source: wikipedia
`;
export const PVNarasimhaRao = `Pamulaparthi Venkata Narasimha Rao, commonly known as P.V. Narasimha Rao, was an influential Indian politician who served as the 9th Prime Minister of India from 1991 to 1996. 
Born on June 28, 1921, in Karimnagar, Hyderabad State (now in Telangana), Rao was a scholar, lawyer, and polyglot, proficient in several languages including Telugu, Hindi, Marathi, Urdu, Oriya, Bengali, Gujarati, Kannada, Sanskrit, Tamil, and French.
Rao's tenure as Prime Minister is most notable for his far-reaching economic reforms that transformed India's economy. Faced with a severe economic crisis upon taking office, Rao, along with his Finance Minister Manmohan Singh, initiated a series of liberalization policies that dismantled the License Raj, reduced trade barriers, and encouraged foreign investment.
These reforms shifted India from a closed, socialist economy to a more open and market-driven one, setting the foundation for rapid economic growth and modernization.
In addition to economic reforms, Rao's tenure was marked by significant developments in India's foreign policy. He sought to strengthen relations with major global powers, particularly the United States, and played a key role in improving India's ties with its Southeast Asian neighbors through the Look East Policy. Rao also worked to enhance India's nuclear capabilities while maintaining a cautious approach to nuclear testing.
Domestically, Rao faced numerous challenges, including managing the political fallout from the Babri Masjid demolition in 1992, which led to widespread communal riots. Despite these difficulties, he maintained a focus on administrative and governance reforms, aiming to decentralize power and improve public sector efficiency.
Rao's contributions to Indian politics extended beyond his prime ministership. He held several key positions, including Chief Minister of Andhra Pradesh, Minister of External Affairs, Minister of Home Affairs, and Minister of Defence. His intellectual prowess and deep understanding of Indian and global politics earned him the reputation of being a "modern-day Chanakya."
P.V. Narasimha Rao passed away on December 23, 2004, but his impact on India's economic and political landscape remains significant. His reforms and policies continue to influence India's growth trajectory and its position in the global economy.
Source: ChatGPT, image source: wikipedia
`;
export const HargobindSingHkhorana = `Har Gobind Khorana, an Indian-American biochemist, made seminal contributions to our understanding of the genetic code and its function in protein synthesis. Born on January 9, 1922, in Raipur, Punjab (now in Pakistan), Khorana pursued his higher education with determination, earning a scholarship to study chemistry at the University of Punjab in Lahore.
He furthered his education at the University of Liverpool, where he completed his Ph.D. in 1948, and subsequently worked with scientists in Switzerland and Cambridge, England, honing his research skills.
Khorana's groundbreaking work began to take shape in the late 1950s and early 1960s when he joined the University of Wisconsin-Madison. Here, he started to decode how nucleotides in DNA determined the amino acid sequences in proteins. 
His most notable achievement came in the 1960s when he, along with Robert W. Holley and Marshall W. Nirenberg, elucidated the genetic code. They demonstrated how sequences of three nucleotides, called codons, correspond to specific amino acids, forming the basis for protein synthesis.
In 1968, Khorana's contributions were recognized with the Nobel Prize in Physiology or Medicine, which he shared with Holley and Nirenberg. His work had far-reaching implications, laying the foundation for modern genetic engineering and biotechnology. Khorana also synthesized the first artificial gene, a pioneering achievement that opened new avenues in genetic research and synthetic biology.
Beyond his Nobel-winning work, Khorana continued to make significant scientific contributions. He worked at the Massachusetts Institute of Technology (MIT) from 1970 until his retirement in 2007, furthering research in molecular biology and genetics. He passed away on November 9, 2011, in Concord, Massachusetts, leaving behind a profound legacy in the field of molecular biology.
Source: ChatGPT, image source: wikipedia 


`;
export const HarishChandra = `Harish-Chandra, born Harish Chandra Mehrotra on October 11, 1923, in Kanpur, India, was a highly influential mathematician whose work in representation theory and harmonic analysis on semisimple Lie groups has had a profound and lasting impact on modern mathematics. 
From a young age, Harish-Chandra exhibited extraordinary intellectual abilities. He pursued his initial studies in physics at the University of Allahabad, where he earned his master's degree. His academic journey took him to the University of Cambridge, where he studied under the renowned physicist Paul Dirac, leading to a Ph.D. in theoretical physics in 1947.
During his time at Cambridge, Harish-Chandra's interests began to shift towards pure mathematics, particularly the mathematical structures underlying physical theories. This shift became more pronounced after he joined the Institute for Advanced Study in Princeton, New Jersey, in 1949. Here, under the intellectual influence of Herman Weyl and other leading mathematicians, Harish-Chandra made his transition from theoretical physics to mathematics complete.
Harish-Chandra's research was characterized by rigorous logic, deep insights, and an extraordinary capacity for abstraction. His work laid the groundwork for much of the modern theory of Lie groups and influenced a broad range of mathematical disciplines, from number theory to algebraic geometry and mathematical physics.
Despite his monumental contributions to mathematics, Harish-Chandra was known for his humility and dedication to his work. His achievements were recognized by his election to prestigious societies, including the Royal Society of London and the National Academy of Sciences in the United States. He was awarded the Cole Prize in Algebra by the American Mathematical Society in 1954, a testament to his profound impact on the field.
Harish-Chandra passed away on October 16, 1983, in Princeton, New Jersey. His legacy continues to be celebrated in the mathematical community, with institutions such as the Harish-Chandra Research Institute in Allahabad, India, named in his honor.
Source: ChatGPT, image source: wikipedia
`;
export const IGPatel = `Indraprasad Gordhanbhai Patel, commonly known as I.G. Patel, was a distinguished Indian economist and a significant figure in both national and international economic spheres. 
Born on November 11, 1924, in Vadodara, Gujarat, Patel's academic journey began with a degree in economics from Bombay University, followed by a D.Phil. from the University of Cambridge. 
Patel's career was marked by his pivotal roles in various high-profile economic positions. He served as the Chief Economic Adviser to the Government of India during a crucial period in the 1960s, where his guidance was instrumental in shaping India's economic policies. He was also the Secretary of the Department of Economic Affairs, where he played a key role in the formulation of India's Five-Year Plans and other economic strategies aimed at fostering growth and development.
In 1972, Patel was appointed as the Deputy Administrator of the United Nations Development Programme (UNDP), where he contributed to global development initiatives. However, his most notable tenure was as the 14th Governor of the Reserve Bank of India (RBI) from 1977 to 1982. During his time at the RBI, Patel navigated the Indian economy through challenging times, including managing the repercussions of the oil shocks of the 1970s and maintaining financial stability.
Following his RBI tenure, Patel's expertise was sought on the global stage when he became the Director of the London School of Economics (LSE) from 1984 to 1990, being the first person from outside the Western world to hold this prestigious position. His leadership at LSE further cemented his reputation as a global economic thinker.
Patel's contributions were widely recognized, and he received numerous accolades, including the Padma Vibhushan in 1991, one of India's highest civilian honors. His work not only influenced economic policies within India but also had a significant impact on international economic thought and practice.
Source: ChatGPT, image source: wiki commons,
`;
export const PRBrahmananda = `P.R. Brahmananda or Palahalli Ramaiya (P.R.) Brahmananda was a distinguished Indian economist, widely recognized for his significant contributions to economic theory and policy in India. Born on September 27, 1926, Brahmananda's academic prowess and intellectual rigor became evident early in his career. 
Brahmananda's work largely focused on development economics, monetary theory, and economic planning.One of Brahmananda's most notable contributions was his work on the "wage-goods model" of development, which emphasized the importance of agricultural and consumer goods production in the overall economic growth strategy. 
This model highlighted the necessity of balancing industrial growth with agricultural productivity to ensure sustainable and inclusive development. His emphasis on the role of monetary policy in economic stability also brought significant attention to the interplay between inflation and growth.
Throughout his career, Brahmananda held several prominent academic and advisory positions. He was a professor at the University of Mumbai, where he influenced generations of students with his profound understanding of economic theories and practical policy implications.
Brahmananda was also a critic of the excessive reliance on centralized planning and often advocated for more market-oriented reforms while recognizing the state’s role in addressing market failures and ensuring equitable distribution of resources. His balanced approach to economic policy made him a respected figure among both policymakers and academics.
Source: ChatGPT, image source: wiki commons,
`;
export const SundarLalBahuguna = `Sundar Lal Bahuguna, born on January 9, 1927, in Maroda village, Tehri Garhwal, Uttarakhand, was an eminent Indian environmentalist and a prominent leader of the Chipko Movement, a grassroots environmental campaign aimed at protecting the forests of the Himalayan region.
 Inspired by Gandhian principles of non-violence and self-sufficiency, Bahuguna dedicated his life to advocating for the conservation of nature and the rights of local communities.
The Chipko Movement, which began in the early 1970s, involved villagers, particularly women, hugging trees to prevent them from being felled by loggers. Bahuguna's leadership and eloquent advocacy brought national and international attention to the movement, highlighting the critical importance of forests in maintaining ecological balance and supporting the livelihoods of rural communities. 
His efforts played a crucial role in the eventual implementation of a 15-year ban on commercial logging in the Himalayan forests by the Indian government.
Bahuguna was also a vocal critic of large-scale dam projects, particularly the Tehri Dam, which he opposed due to its environmental and social impacts, including displacement of local communities and ecological degradation. He undertook a series of fasts and marches, including a 5,000-kilometer trans-Himalayan foot march, to raise awareness about the importance of sustainable development and the protection of the fragile Himalayan ecosystem.
Throughout his life, Bahuguna received several accolades for his environmental activism, including the Padma Shri in 1981, the Padma Vibhushan in 2009, and the Jamnalal Bajaj Award in 1986.He passed away on May 21, 2021, leaving behind a profound impact on environmental activism in India and beyond.
Source: ChatGPT, image source: wikipedia
`;
export const ElattuvalapilSreedharan = `Elattuvalapil Sreedharan, often referred to as the "Metro Man of India," is a distinguished Indian civil engineer known for his pivotal role in the development of major infrastructure projects in India. 
Born on June 12, 1932, in Palakkad, Kerala, Sreedharan's illustrious career began after he graduated from the Government Engineering College, Kakinada. He first garnered national attention with his work on the restoration of the Pamban Bridge in Tamil Nadu, which was completed in just 46 days after it was damaged by a cyclone in 1964. However, it was his leadership in the Konkan Railway project and the Delhi Metro that cemented his legacy.
Sreedharan served as the Managing Director of the Delhi Metro Rail Corporation (DMRC) from 1995 to 2012. Under his guidance, the Delhi Metro project was completed on time and within budget, setting new standards for public transportation in India and earning him both national and international acclaim.
In recognition of his contributions, Sreedharan has received numerous awards, including the Padma Shri in 2001 and the Padma Vibhushan in 2008, two of India’s highest civilian honors.
 After retiring from DMRC, he continued to contribute to various metro projects across the country, including those in Kochi, Jaipur, and Lucknow, offering his expertise to ensure their success. Sreedharan's career is a testament to the impact of visionary leadership and dedication in the field of civil engineering, making him an iconic figure in India's journey towards modernization and infrastructural development.
Source: ChatGPT, image source: wiki commons
`;
export const ElaBhatt = `Ela Bhatt, born on September 7, 1933, in Ahmedabad, Gujarat, is a renowned Indian social activist and founder of the Self-Employed Women's Association (SEWA), an influential trade union for women in the informal sector. 
With a background in law, Bhatt's early career was shaped by her work at the Textile Labour Association (TLA) in Ahmedabad, where she witnessed the struggles of self-employed women. Determined to address their issues, she founded SEWA in 1972, aiming to empower women by providing them with a collective voice, access to social security, and economic independence.
Under Bhatt's leadership, SEWA grew into a powerful movement, advocating for better working conditions, fair wages, and financial inclusion for millions of women. SEWA's innovative approach combined union activities with cooperative and microfinance initiatives, offering women services such as banking, healthcare, childcare, and legal aid. This holistic model not only improved the livelihoods of its members but also enhanced their social status and confidence.
Bhatt's work extended beyond SEWA as she played a crucial role in the International Labour Organization (ILO) and co-founded Women's World Banking, which promotes financial inclusion for women globally. 
Her contributions to social justice and women's empowerment have been widely recognized, earning her numerous awards, including the Padma Shri in 1985, the Padma Bhushan in 1986, and the Ramon Magsaysay Award in 1977.
Source: ChatGPT, image source: wikipedia
`;
export const TheBombayPlan = `The Bombay Plan, formally known as the "A Plan of Economic Development for India," was a significant economic proposal developed in 1944 by eight of India's leading industrialists and economic thinkers.
This group included prominent figures such as J.R.D. Tata, G.D. Birla, Lala Shri Ram, Kasturbhai Lalbhai, Ardeshir Dalal, John Mathai, Purshottamdas Thakurdas, and A.D. Shroff. Amidst the final years of British colonial rule, these industrialists sought to outline a comprehensive strategy for India's post-independence economic development.
The Bombay Plan advocated for a mixed economy, combining both private and public sectors, to accelerate the nation's industrialization and economic growth. It emphasized the importance of substantial government intervention and planning to guide the economic transition, reflecting a pragmatic approach rather than a purely capitalist or socialist model. 
Key recommendations included heavy investment in infrastructure, such as power, transport, and irrigation, and the development of key industries like steel, cement, and chemicals.Additionally, the plan stressed the importance of land reforms and agricultural development to support rural economies and ensure food security.
The Bombay Plan also called for measures to boost productivity, including technological advancements, skill development, and the establishment of research institutions. It underscored the necessity of protecting nascent industries through tariffs and trade policies while fostering an environment conducive to private enterprise and investment.
Although the Bombay Plan was never formally adopted as government policy, its principles significantly influenced India's subsequent Five-Year Plans and economic policies. It highlighted the need for a coordinated approach to economic development and the role of both public and private sectors in nation-building.
Source: ChatGPT, image source: wikipedia image details: Gateway of India, mumbai
`;
export const TheHirakudDam = `The Hirakud Dam, located on the Mahanadi River in Odisha, India, is one of the longest earthen dams in the world and a significant engineering marvel. Completed in 1953, it was the first major multipurpose river valley project undertaken in independent India, designed primarily for flood control, irrigation, and hydroelectric power generation.
The construction of Hirakud Dam was initiated in 1946 under the guidance of Sir Mokshagundam Visvesvaraya, the chief engineer for the project. The dam spans about 25.8 kilometers (16 miles) and has two observation towers at each end, known as Gandhi Minar and Nehru Minar, symbolizing India's struggle for independence and its commitment to modern infrastructure development. 
The dam's reservoir, known as Hirakud Reservoir, covers an area of about 743 square kilometers (287 square miles) and is one of the largest artificial lakes in Asia.
The primary objectives of Hirakud Dam include mitigating floods in the Mahanadi Delta, which were devastating to the region, particularly during the monsoon season. The dam's storage capacity of over 4.74 billion cubic meters (167 billion cubic feet) helps regulate the river flow, reducing the impact of floods downstream and ensuring a steady water supply for irrigation during the dry season. This has significantly benefited agriculture in the region, promoting crop diversification and increasing agricultural productivity.
Another important aspect of Hirakud Dam is its hydroelectric power generation capacity. The dam's powerhouse has three turbines with a combined capacity of 307.5 megawatts, contributing to Odisha's electricity grid. The generated power is crucial for meeting the energy demands of industries, urban centers, and rural electrification in the state.
Beyond its practical benefits, Hirakud Dam has also become a tourist attraction, drawing visitors for its scenic views, boating facilities on the reservoir, and its historical significance in India's post-independence development. 
The dam has played a vital role in transforming the socio-economic landscape of Odisha, supporting livelihoods through agriculture, fisheries, and tourism while also serving as a symbol of India's engineering prowess and commitment to water resource management.
Source: ChatGPT, image source: wikipedia 
`;
export const EPSE = `The establishment of public sector enterprises in the 1950s to 1970s was a significant phase in the economic history of many developing countries, especially India. This period was characterized by a strong emphasis on state-led development as governments aimed to achieve rapid industrialization, reduce poverty, and ensure equitable distribution of wealth.
In India, the foundation of public sector enterprises was laid with the introduction of the Industrial Policy Resolution of 1956, which aimed to accelerate economic growth and social justice. 
This policy identified specific industries to be developed by the state, including heavy machinery, telecommunications, defense, and energy. These sectors were deemed critical for national development and too important to be left to the private sector. 
As a result, the government established several key public sector units (PSUs) like Indian Telephone Industries Limited (ITI), Steel Authority of India Limited (SAIL), Bharat Heavy Electricals Limited (BHEL), Oil and Natural Gas Corporation (ONGC), and Indian Oil Corporation (IOC).
The 1960s and 1970s saw further expansion of the public sector. The government continued to invest heavily in infrastructure, heavy industries, and other key areas, often using PSEs as instruments of state policy to control resources and direct economic activity. This period also saw the establishment of several financial institutions to support these enterprises, including banks and insurance companies, which were nationalized to ensure that credit and financial resources were available for public sector growth.
Public sector enterprises were expected to operate in areas where the private sector was unwilling or unable to invest, thus playing a pivotal role in building the economic foundation of the country.
However, the emphasis on public sector enterprises also led to several challenges. Many PSEs faced issues of inefficiency, overstaffing, and lack of competition. The monopolistic nature of some of these enterprises led to complacency, and they were often criticized for not being as innovative or productive as their private sector counterparts. 
Despite these challenges, the period from the 1950s to the 1970s was crucial in laying the groundwork for future economic development and industrialization in many developing nations.
In summary, the establishment of public sector enterprises during the 1950s to 1970s was driven by the need for rapid industrialization, social equity, and economic self-reliance.
Source: ChatGPT, image source: wikipedia Image details: Indian Telephone Industries Limited (ITI)
`;

export const FirstGeneralElections = `The first general elections in India, held between 1951 and 1952, were a landmark event that marked the establishment of the world's largest democracy. After gaining independence from British colonial rule in 1947, India adopted a new Constitution on January 26, 1950, becoming a republic. 
This Constitution laid the foundation for a democratic political system with universal adult suffrage, which allowed every adult citizen of India, regardless of gender, caste, or education level, to vote.
The Election Commission of India, established in March 1950 with Sukumar Sen as the first Chief Election Commissioner, faced the monumental task of preparing for the elections. The challenges were immense: a largely illiterate population, a vast and diverse geographical expanse, and a need for an accurate electoral roll. To register the 173 million eligible voters, election officials went door-to-door, ensuring that every eligible adult was listed. Voting was to be conducted using ballot boxes marked with symbols to aid illiterate voters in identifying their preferred candidates.
The elections were held in multiple phases over nearly four months, from October 25, 1951, to February 21, 1952. In total, 489 seats in the Lok Sabha, the lower house of Parliament, were contested.
The political landscape at the time was dominated by the Indian National Congress (INC), led by Jawaharlal Nehru. The INC, which had been at the forefront of the struggle for independence, was widely expected to win. Other participants included the Communist Party of India, the Socialist Party, the Bharatiya Jana Sangh (the precursor to the Bharatiya Janata Party), various regional parties, and numerous independent candidates.
The results of the elections saw the Indian National Congress winning a decisive victory, securing 364 out of the 489 seats in the Lok Sabha. Jawaharlal Nehru, a charismatic leader with widespread popular support, became the first elected Prime Minister of India. The Communist Party of India emerged as the second-largest party with 16 seats, followed by the Socialist Party with 12 seats.
The successful conduct of the first general elections was hailed globally as a monumental achievement. It demonstrated India's commitment to democratic principles and set a strong foundation for its democratic processes.
Source: ChatGPT, image source: wikipedia Image details: A voter in front of several ballot boxes marked with the candidates’ symbols in the Indian general election 1951/52.
`;
export const BhoodanMovement = `The Bhoodan Movement, also known as the Land Gift Movement, was a landmark social reform initiative in India, spearheaded by Vinoba Bhave, a disciple of Mahatma Gandhi. 
Launched on April 18, 1951, in the village of Pochampally, Telangana, the movement aimed to address the severe landlessness and poverty among rural peasants by encouraging wealthy landowners to voluntarily donate a portion of their land to the landless. 
Vinoba Bhave began his journey by walking from village to village, persuading landowners to give at least one-sixth of their land to those without any, based on the Gandhian principle of trusteeship, where the wealthy were seen as custodians of their land, responsible for its equitable distribution.
The movement quickly gained momentum as Vinoba Bhave traversed thousands of miles on foot, mobilizing support from both landowners and landless peasants. The response was substantial, with millions of acres of land being donated over the years. By the 1960s, the movement had spread across various parts of India, involving diverse communities and creating a significant impact on rural society.
 The Bhoodan Movement not only provided land to the needy but also promoted the idea of social justice and communal harmony, reducing tensions between different socio-economic classes in rural areas.
Despite its initial success, the movement faced challenges such as bureaucratic hurdles in land redistribution, resistance from some landowners, and the varying fertility and productivity of the donated land.
Nevertheless, the Bhoodan Movement remains a remarkable example of a non-violent approach to social reform, deeply rooted in Gandhian philosophy. It highlighted the potential for collective goodwill and community-driven solutions to address socio-economic disparities.
Source: ChatGPT, image source: wiki commons, Image details: Acharya Vinoba bhave who initiated Bhoodan movement
`;
export const INWCinema = `Indian New Wave Cinema, also known as Parallel Cinema, emerged in the late 1940s and gained prominence in the 1950s through the 1970s as an alternative to mainstream Bollywood films. 
This movement was characterized by its realistic approach, socially relevant themes, and an emphasis on artistic expression over commercial success. It drew inspiration from Italian Neorealism and French New Wave, focusing on the everyday lives of ordinary people, often highlighting issues such as poverty, social injustice, and human struggles.
One of the seminal figures of Indian New Wave Cinema was Satyajit Ray, whose debut film "Pather Panchali" (1955) garnered international acclaim and established a new direction for Indian filmmaking. Ray's work, along with that of contemporaries like Ritwik Ghatak and Mrinal Sen, emphasized narrative simplicity, naturalistic performances, and the use of non-professional actors and real locations, which contrasted sharply with the formulaic and star-driven productions of Bollywood.
Parallel Cinema also found expression in other regional languages. In Hindi, filmmakers such as Shyam Benegal, Govind Nihalani, and Mani Kaul were instrumental in shaping the movement. Benegal's "Ankur" (1974) and Nihalani's "Aakrosh" (1980) are notable examples of films that addressed socio-political issues with a raw, unfiltered lens. In Bengali, apart from Ray and Ghatak, directors like Aparna Sen and Buddhadeb Dasgupta contributed significantly to the movement. Malayalam cinema also saw a parallel wave with directors like Adoor Gopalakrishnan and Shaji N. Karun, who brought international recognition to Indian cinema with their thought-provoking films.
The movement was supported by institutions such as the Film and Television Institute of India (FTII) and the National Film Development Corporation (NFDC), which provided platforms and funding for independent filmmakers. Parallel Cinema often relied on minimalistic production techniques and smaller budgets, which fostered creative storytelling and innovation.This opened up new possibilities for Indian cinema, demonstrating that films could be both artistically significant and socially impactful.
Source: ChatGPT, image source: wikipedia image details: From left to right- Satyajit ray, Mrinal Sen, Ritwik Ghatak.
`;
export const StatesReorganisationAct = `The States Reorganisation Act of 1956 was a pivotal legislative measure in India that significantly restructured the boundaries and organization of Indian states and territories. 
This act came into effect on November 1, 1956, following extensive demands for linguistic-based state boundaries that had been growing since India's independence in 1947. The primary aim was to create states that would provide a more coherent and culturally unified administrative framework, thus promoting efficient governance and addressing regional aspirations.
The process began with the formation of the States Reorganisation Commission (SRC) in 1953, chaired by Fazal Ali, with H.N. Kunzru and K.M. Panikkar as members. The commission was tasked with examining the feasibility of reorganizing states on linguistic lines and recommending a structure that would foster administrative efficiency and national integration.
Based on the SRC's recommendations, the States Reorganisation Act led to significant changes in the map of India. The most notable changes included the creation of new states and the alteration of existing state boundaries to align with linguistic demographics. 
Andhra Pradesh was established as the first state formed on linguistic lines in 1953, preceding the act, setting a precedent for the reorganization that followed.The Act resulted in the creation of 14 states and 6 union territories, replacing the previous arrangement of Part A, B, C, and D states.
States like Bombay were reorganized to form Maharashtra and Gujarat, and the state of Kerala was created by merging the Malayalam-speaking regions. Similarly, Karnataka was formed by merging Kannada-speaking areas from different regions, and Tamil Nadu was consolidated from Tamil-speaking regions.
The States Reorganisation Act of 1956 was a landmark in India's administrative history. It not only addressed the aspirations of linguistic groups but also aimed to strengthen national unity by creating states that were more homogeneous and manageable.
Source: ChatGPT, image source: wikipedia image details: Administrative divisions of India in 1951`;

export const SilentValleyMovement = `The Silent Valley Movement was a significant environmental campaign in India during the late 1970s and early 1980s, aimed at protecting the Silent Valley, an ecologically rich tropical rainforest in the Palakkad district, Western Ghats of Kerala. 
The movement emerged in response to the Kerala State Electricity Board's proposal to construct a hydroelectric dam on the Kunthipuzha River, which would have submerged a large portion of the valley. This area, known for its unique biodiversity, including several endangered species and dense forests, was threatened by the dam project, which sparked widespread concern among environmentalists, scientists, and the general public.
The movement was spearheaded by local activists, environmental groups, and notable figures such as the poet activist Sugathakumari, who played a crucial role in raising awareness about the environmental impact of the project. The Kerala Sasthra Sahithya Parishad (KSSP) and other organizations also actively campaigned against the dam, highlighting the ecological importance of the Silent Valley and advocating for its conservation.
The campaign gained national and international attention, drawing support from prominent figures like Salim Ali, the renowned ornithologist, and M. S. Swaminathan, the father of the Green Revolution in India. In 1980, following extensive public pressure and environmental impact studies, Prime Minister Indira Gandhi intervened and declared that the Silent Valley would be protected. In 1983, the Silent Valley National Park was officially established, ensuring the preservation of its unique ecosystem.
The Silent Valley Movement is considered one of India's pioneering environmental movements, setting a precedent for subsequent conservation efforts in the country.The movement also helped foster greater environmental consciousness and policies geared towards sustainable development in India.
Source: ChatGPT, image source: wiki commons, Image details:The Nilgiri Mountains as seen from Silent Valley National Park
`;
export const WhiteRevolution = `The White Revolution, also known as Operation Flood, was a groundbreaking initiative launched in 1970 that transformed India's dairy industry and made the country one of the largest milk producers in the world. 
Spearheaded by the National Dairy Development Board (NDDB) and led by Dr. Verghese Kurien, often referred to as the "Father of the White Revolution," this ambitious program aimed to create a nationwide milk grid and significantly boost milk production to meet the growing demand.
The primary objective of the White Revolution was to increase milk production, improve rural incomes, and ensure the availability of milk and milk products across India. The strategy involved the establishment of a cooperative network of dairy farmers, who were organized into village-level cooperatives linked to district-level unions and state-level federations.
Central to the success of the White Revolution was the model of the Gujarat Cooperative Milk Marketing Federation (GCMMF) and its brand, Amul. This model demonstrated the effectiveness of cooperative management and collective marketing, ensuring that farmers received fair prices for their milk.
The White Revolution led to the creation of milk production infrastructure, including milk collection centers, chilling plants, and processing units, which facilitated efficient milk collection, processing, and distribution. Advanced technologies and practices were introduced, such as artificial insemination for cattle, balanced cattle feed, and improved animal health services, which contributed to increased productivity.
As a result of these efforts, India's milk production soared from about 22 million metric tons in the early 1970s to over 100 million metric tons by the late 1990s. The success of Operation Flood not only ensured the availability of milk and milk products throughout India but also enhanced the nutritional intake of millions of people. Additionally, it provided economic stability and improved livelihoods for millions of rural dairy farmers, many of whom were small-scale producers and women.
Dr. Verghese Kurien's visionary leadership and the cooperative model of Operation Flood have left an enduring legacy, continuing to influence dairy policies and rural development programs in India and beyond.
Source: ChatGPT, image source: wikipedia image details: The Amul trinity (left to right): Verghese Kurien, Tribhuvandas Kishibhai Patel, and Harichand Megha Dalaya.
`;

export const PokhranI = `Pokhran-I, also known as "Smiling Buddha," was India's first successful nuclear bomb test, conducted on May 18, 1974. The test took place at the Pokhran Test Range in the Thar Desert of Rajasthan, under the leadership of Prime Minister Indira Gandhi. 
This test marked India as the sixth nation to develop and successfully detonate a nuclear device, showcasing its technological prowess and strategic capabilities.
The project was carried out in utmost secrecy under the aegis of the Indian Atomic Energy Commission, headed by Dr. Homi Sethna, and the Bhabha Atomic Research Centre (BARC), led by Dr. Raja Ramanna. 
The code name "Smiling Buddha" was chosen for the operation to symbolize the peaceful intentions behind India's nuclear program, emphasizing that the test was for self-defense and to maintain regional stability rather than for aggressive purposes.
The device was an implosion-type nuclear weapon using plutonium-239, which had been produced in the CIRUS reactor at BARC. The test was conducted underground to contain radioactive fallout and minimize environmental impact. 
Upon detonation, the explosion yielded an estimated 8-12 kilotons of TNT, causing a significant shockwave and demonstrating India's advanced scientific and technological capabilities in the field of nuclear physics.
Pokhran-I was a defining moment in India's history, establishing it as a nuclear-capable state and significantly altering the strategic balance in South Asia. The test laid the groundwork for India's subsequent nuclear policy and its quest for self-reliance in defense technology, culminating in the series of tests conducted during Pokhran-II in 1998.
Source: ChatGPT, image source: wikipedia Image details: APSARA reactor and plutonium reprocessing facility at BARC as photographed by a US satellite on 19 February 1966`;

export const TheMandalCommission = `The Mandal Commission, officially known as the Socially and Educationally Backward Classes Commission (SEBC), was established in India in 1979 by the Janata Party government under Prime Minister Morarji Desai.
 It was headed by B.P. Mandal, a former Chief Minister of Bihar, and was tasked with identifying the socially or educationally backward classes in India and recommending measures to advance their status. 
The commission conducted extensive research, using social, economic, and educational indicators to assess backwardness. In its report, submitted in 1980, the Mandal Commission recommended that reservations in government jobs and educational institutions be extended to Other Backward Classes (OBCs), proposing a 27% quota, which would raise the total reservation to 49.5% including the existing 22.5% for Scheduled Castes and Scheduled Tribes. 
The implementation of the report's recommendations in 1990 by Prime Minister V.P. Singh's government sparked widespread protests and debates across the country, highlighting the complexities and sensitivities surrounding affirmative action policies in India. 
The Mandal Commission's work significantly impacted India's socio-political landscape, leading to increased political mobilization among OBC communities and ongoing discussions about social justice and equality.
Source:Chat GPT, image source: wiki commons, Image details: B.P. Mandal, Chairman Of Mandal Commission and Ex-Cm Of Bihar
`;
export const ILPR = `The Industrial Licensing Policy Reforms of the 1980s in India marked a significant shift towards economic liberalization and deregulation, aiming to stimulate industrial growth and improve efficiency in the economy. 
Prior to these reforms, the Indian industrial sector was heavily regulated under the Industrial Development and Regulation Act of 1951, which required businesses to obtain licenses for setting up industries, expanding capacities, and diversifying production. This system, part of the broader "License Raj," was intended to control industrial growth, prevent monopolies, and ensure balanced regional development. However, it led to bureaucratic delays, inefficiencies, and stifled entrepreneurial activity.
Recognizing the need for change, the Indian government initiated a series of policy reforms during the 1980s to liberalize the industrial sector. The key reforms included the relaxation of licensing requirements for several industries and simplification of procedures for obtaining licenses. These measures aimed to reduce the administrative burden on industries, encourage private investment, and foster a more competitive business environment.
One of the significant steps was the introduction of the Industrial Policy Statement of 1980, which emphasized deregulation and the promotion of competition. It allowed greater flexibility for companies to adapt to market conditions and respond to technological advancements. The policy also recognized the need to attract foreign investment and technology by relaxing restrictions on foreign collaborations and equity participation.
The reforms also targeted the public sector, encouraging greater autonomy and accountability for public enterprises. The emphasis was on improving efficiency and profitability through better management practices and performance-based incentives. Additionally, certain sectors previously reserved for the public sector, such as telecommunications, power, and heavy industries, were gradually opened up to private participation.
Source: ChatGPT, image source: wikipedia image details: Maithri Aquatech Factory located in Hyderabad, Telangana, India`;

export const AsianGames = `The 9th Asian Games, held in New Delhi, India, from November 19 to December 4, 1982, marked a significant milestone in the history of Asian sports and showcased India's capacity to host large-scale international events. 
This was the second time India hosted the Asian Games, with the first being the inaugural event in 1951. The selection of New Delhi as the host city prompted a massive effort to upgrade and construct the necessary infrastructure. 
Major venues included the newly built Jawaharlal Nehru Stadium, the Indira Gandhi Indoor Stadium, and the Talkatora Indoor Stadium, which were equipped with modern facilities to meet international standards.
The Indian Olympic Association (IOA) led the organizational efforts, with substantial support from the Indian government. Prime Minister Indira Gandhi played a crucial role in ensuring the success of the Games, emphasizing the event’s importance for national pride and international reputation. 
The opening ceremony on November 19, 1982, was a grand affair held at the Jawaharlal Nehru Stadium, featuring cultural performances that highlighted India’s rich heritage and a parade of athletes that celebrated the unity of Asian nations. The closing ceremony on December 4, 1982, was equally spectacular, marking the end of a well-organized event that left a lasting impression on all participants.
China dominated the medal tally, winning a total of 153 medals, including 61 golds, followed by Japan and South Korea. India, as the host nation, performed admirably, securing the fifth position with 57 medals, including 13 golds. Among the standout performances was that of Indian athlete P.T. Usha, who won silver medals in the 100m and 200m sprints, emerging as a national hero and inspiring future generations of athletes.
The 1982 Asian Games also saw the introduction of new sports, including women's events in archery and the inclusion of handball and equestrian events, reflecting a broader and more inclusive approach to the Games. The event was not only a celebration of athletic prowess but also a platform for promoting gender equality in sports.
In summary, the 1982 Asian Games were a landmark event that demonstrated India's organizational capabilities and commitment to sports development.
Source: ChatGPT, image source: wikipedia 
`;
export const INSAT1B = `INSAT-1B, part of the Indian National Satellite System (INSAT), was a landmark achievement in India’s space program, marking a significant step in the country’s telecommunications, broadcasting, and meteorological capabilities. 
Launched on August 30, 1983, by NASA's Space Shuttle Challenger (mission STS-8), INSAT-1B was the second satellite in the INSAT series, following the unsuccessful launch of INSAT-1A.
INSAT-1B was built by Ford Aerospace and carried a suite of transponders for various applications.This multi-purpose satellite played a crucial role in enhancing India's communication infrastructure, providing direct-to-home television broadcasting, and improving weather forecasting and disaster warning services.
The launch of INSAT-1B marked a significant turnaround after the failure of INSAT-1A, which faced technical issues post-launch in 1982. INSAT-1B's successful deployment demonstrated India's growing capabilities in satellite technology and its ability to leverage international collaborations effectively.
INSAT-1B became operational in October 1983, and its impact was immediately felt across various sectors in India. It revolutionized television broadcasting by enabling the transmission of national programs to remote and rural areas, thus fostering greater national integration and information dissemination. 
In telecommunications, it facilitated long-distance telephone and data transmission, significantly improving connectivity across the country. The meteorological data provided by the VHRR was invaluable for weather prediction, agricultural planning, and managing natural disasters.
INSAT-1B remained in service until 1990, laying the foundation for future advancements in India's space capabilities. Its success paved the way for subsequent satellites in the INSAT series, contributing to the nation's socio-economic development and positioning India as a key player in the global space arena.
Source: ChatGPT, image source: wikipedia Image details: INSAT-1B before launch
`;
export const IITs = `The establishment of the Indian Institutes of Technology (IITs) was a pivotal development in India’s higher education and technological landscape, aimed at creating a cadre of world-class engineers and technologists to drive the nation’s industrial and economic progress. 
The concept of IITs was first proposed in the early 1940s, by a 22-member committee, headed by Nalini Ranjan Sarkar, and the vision for these premier institutions was solidified in the aftermath of India's independence in 1947. The first IIT, IIT Kharagpur, was established in 1951 in West Bengal which was formed by the government to develop a blueprint for technical education in India.
Dr. B.C. Roy, the then Chief Minister of West Bengal, played a crucial role in transforming the site of the Hijli Detention Camp into IIT Kharagpur, symbolizing the transition from colonial repression to empowerment through education.
Following this, four more IITs were established during the 1950s and 1960s: IIT Bombay (1958), IIT Madras (1959), IIT Kanpur (1959), and IIT Delhi (1961). These institutions were set up with the assistance of various foreign governments, such as the USSR for IIT Bombay, West Germany for IIT Madras, the United States for IIT Kanpur, and the UK for IIT Delhi, each contributing to the infrastructure and academic collaboration.
The IITs were envisioned not merely as technical schools but as institutes of national importance, aimed at fostering innovation, research, and development in various fields of engineering and technology. They were granted substantial autonomy and were modeled on the lines of leading global technological institutions, with a rigorous selection process to admit the brightest students from across the country.
Over the decades, IITs have expanded their presence, with 23 institutes now operating across India. They have become synonymous with excellence in technical education and research, contributing significantly to India’s scientific and technological advancements.
The IIT alumni network is highly influential, with many graduates holding key positions in academia, industry, and government globally. The establishment of IITs has thus been instrumental in shaping India’s technological prowess and its competitive edge in the global economy.
Source: ChatGPT, image source: wikipedia Image details: Nalini Ranjan Sarkar, who recommended the set up of IIT's, along the lines of the MIT
`;

export const TessyThomas = `Dr. Tessy Thomas is a renowned Indian scientist and aerospace engineer who has made significant contributions to India's missile technology. Born in April 1963 in Alappuzha, Kerala, she is often referred to as the "Missile Woman of India" for her pivotal role in the Agni series of ballistic missile projects.
Dr. Thomas's academic background includes a Bachelor's degree in engineering from the Government Engineering College, Thrissur, and a Master's degree in guided missile technology from the Institute of Armament Technology, Pune.
She joined the Defence Research and Development Organisation (DRDO) in 1988 and quickly rose through the ranks due to her exceptional skills and dedication. Dr. Thomas became the project director for the Agni-IV missile, making her the first woman in India to lead a missile project. 
Throughout her career, Dr. Tessy Thomas has received numerous awards and recognitions for her contributions to science and technology. She has been honored with the DRDO Scientist of the Year award in 2008, the Lal Bahadur Shastri National Award for Excellence in Public Administration, Academics, and Management in 2012, and the Padma Shri, one of India's highest civilian awards, in 2019.
Dr. Thomas's achievements have shattered stereotypes and inspired many young women to pursue careers in science and engineering. Her work continues to play a critical role in advancing India's defense technology and promoting gender equality in STEM fields. As a distinguished scientist and a role model, Dr. Tessy Thomas's legacy is a testament to her perseverance, expertise, and visionary leadership in the field of missile technology.
Source: ChatGPT, image source: wikipedia 
`;
export const JavedAbidi = `Javed Abidi was a pioneering Indian disability rights activist who made significant strides in advocating for the rights and inclusion of people with disabilities. Born on June 11, 1965, in Aligarh, Uttar Pradesh, Abidi was diagnosed with spina bifida, a congenital disorder, which led to his lifelong use of a wheelchair. 
Despite his physical challenges, he pursued higher education, earning a degree in political science from St. Stephen's College, Delhi University, and further studies in the United States.
Abidi's activism began in earnest when he returned to India in the early 1990s. He joined the Rajiv Gandhi Foundation in 1993, where he spearheaded initiatives focused on disability issues.
 His passion and dedication led him to become the founder and director of the National Centre for Promotion of Employment for Disabled People (NCPEDP) in 1996. Under his leadership, the NCPEDP worked tirelessly to advocate for policy changes, raise awareness, and promote employment opportunities for people with disabilities.
One of Abidi's most notable achievements was his role in the passage of the Persons with Disabilities (Equal Opportunities, Protection of Rights and Full Participation) Act in 1995. This landmark legislation laid the foundation for disability rights in India, ensuring legal protection and promoting the inclusion of disabled individuals in various aspects of society. He was also instrumental in advocating for the inclusion of disability in the Census of India, which helped gather vital data to better address the needs of the disabled community.
Throughout his life, Javed Abidi received numerous accolades and recognition for his contributions to disability rights. He was a member of the World Bank's External Advisory Panel for Disability and Development and served on various national and international committees focused on disability issues.
Javed Abidi passed away on March 4, 2018, leaving behind a legacy of advocacy, empowerment, and social change. His work significantly advanced the rights and inclusion of people with disabilities in India, inspiring countless individuals and organizations to continue the fight for equality and accessibility.
Source: ChatGPT, image source: wikipedia 
`;
export const VijayPandurangBhatkar = `Dr. Vijay Pandurang Bhatkar is one of India’s most distinguished computer scientists, best known for his pivotal role in spearheading the development of India’s first supercomputer, PARAM, in the late 1980s and early 1990s. 
Born on October 11, 1946, in Muramba, Maharashtra, Dr. Bhatkar's academic journey began with a Bachelor's degree in Electrical Engineering from Visvesvaraya National Institute of Technology, Nagpur, followed by a Master's degree from the Indian Institute of Technology (IIT), Delhi. He later earned a Ph.D. in Engineering from IIT, Delhi, focusing on the field of Computer Science.
Dr. Bhatkar's most notable achievement came during his tenure as the director of the Centre for Development of Advanced Computing (C-DAC), Pune. In response to the US government's technology embargo in 1987, which denied India access to Cray supercomputers, Dr. Bhatkar led the indigenous effort to develop a supercomputer. 
The result was the PARAM 8000, which was launched in 1991 and placed India among the world’s elite group of supercomputing nations. This achievement not only showcased India’s technological prowess but also fostered a culture of innovation and self-reliance in the field of high-performance computing.
Throughout his career, Dr. Bhatkar has been a driving force behind numerous national and international projects and has held prominent positions such as the Chairman of the National Supercomputing Mission, Chancellor of Nalanda University, and the Chairman of the Board of Governors at IIT Delhi. He has also been an advocate for the use of technology in rural development, education, and healthcare, emphasizing the importance of bridging the digital divide.
In recognition of his contributions, Dr. Bhatkar has received several prestigious awards, including the Padma Bhushan in 2015, the Padma Shri in 2000, and the Maharashtra Bhushan Award in 1999. His work continues to inspire and influence the field of information technology and computer science in India, making him a revered figure in the nation's scientific community.
Source: ChatGPT, image source: wikipedia 
Anshu Gupta`;
export const AnshuGupta = `Anshu Gupta, the founder of Goonj, is a distinguished social entrepreneur renowned for his innovative approach to addressing urban-rural divide and transforming the concept of development and disaster relief in India. 
Born on August 24, 1970, Gupta's journey into social work began after he completed his Master's degree in Mass Communication from the Indian Institute of Mass Communication (IIMC). His professional stint in advertising and public relations provided him with the skills and insights that he would later channel into his social initiatives.
In 1999, moved by the plight of the marginalized and the vast potential of urban waste to address rural poverty, Gupta founded Goonj. Goonj is a non-profit organization that aims to address basic but neglected issues of the poor by turning urban waste into a resource for rural development. The organization collects unused materials, including clothes, shoes, books, and other essentials from urban households, and redistributes them in rural and disaster-hit areas, thus bridging a critical gap.
One of Gupta's pioneering concepts is the 'Cloth for Work' initiative, where villagers engage in community-building activities like constructing roads, digging wells, and building bridges, in exchange for clothes and other essentials. This initiative not only addresses immediate material needs but also fosters a sense of dignity and self-worth among the recipients by valuing their labor.
Under Gupta's leadership, Goonj has grown into a significant force in disaster relief and rehabilitation, employing a model that emphasizes community participation and sustainable development.
Anshu Gupta's innovative approach and relentless dedication to social equity have earned him numerous accolades, including the prestigious Ramon Magsaysay Award in 2015, often considered Asia’s Nobel Prize. His work continues to inspire a new generation of social entrepreneurs and highlights the power of grassroots initiatives in driving sustainable change.
Source: ChatGPT, image source: wikipedia 
`;
export const KUllasKaranth = `Dr. K. Ullas Karanth is a renowned wildlife biologist and conservationist, celebrated for his pioneering work in the field of tiger conservation in India. Born on October 24, 1948, in the picturesque region of Malenadu in Karnataka, Karanth developed an early fascination with the natural world, inspired by the rich biodiversity of his homeland and the writings of nature authors. 
He pursued his education in engineering, earning a Bachelor's degree in Mechanical Engineering from the National Institute of Technology Karnataka. However, his passion for wildlife led him to shift his career path dramatically.
Karanth's significant contributions to wildlife conservation began when he joined the Wildlife Conservation Society (WCS) and completed his Master's degree in Wildlife Ecology from the University of Florida, followed by a Ph.D. from Mangalore University. His research has been instrumental in advancing scientific knowledge about the ecology and behavior of tigers and other large carnivores in India.
One of Karanth's most notable achievements is the development and implementation of rigorous scientific methodologies for estimating tiger populations using camera traps and radio telemetry. His work has not only influenced tiger conservation strategies in India but has also set benchmarks for wildlife studies globally.
As the Director of the Centre for Wildlife Studies in Bangalore, Karanth has led numerous research projects aimed at understanding and mitigating human-wildlife conflicts, preserving biodiversity, and promoting sustainable development in forested landscapes. He has authored several influential books and over 150 scientific papers, sharing his extensive knowledge and advocating for the protection of wildlife.
Dr. Karanth's dedication and contributions have been recognized with numerous awards, including the prestigious J. Paul Getty Wildlife Conservation Prize, the Padma Shri, and the Karnataka Rajyotsava Award. His relentless efforts continue to inspire conservationists worldwide, underscoring the critical importance of scientific research and community engagement in preserving our planet's natural heritage.
Source: ChatGPT, image source: wikipedia 
`;
export const NandanNilekani = `Nandan Nilekani is a prominent Indian entrepreneur, bureaucrat, and politician, renowned for his significant contributions to the development and implementation of public data infrastructure as a service. 
Born on June 2, 1955, in Bangalore, Karnataka, Nilekani co-founded Infosys, one of India's largest IT services companies, in 1981. He served as its CEO from 2002 to 2007, during which Infosys grew exponentially and became a global leader in IT services and consulting.
In 2009, Nilekani left Infosys to take on a public service role as the Chairman of the Unique Identification Authority of India (UIDAI). Under his leadership, the Aadhaar project was launched, which aimed to provide a unique identification number to every Indian citizen. 
This biometric-based system has now enrolled over a billion people, making it the world’s largest biometric ID system. Aadhaar serves as a foundational public data infrastructure, enabling streamlined delivery of government services, subsidies, and benefits directly to beneficiaries, thereby reducing corruption and inefficiencies.
Nilekani's vision has been a strong advocate for leveraging public data infrastructure to drive inclusive growth and innovation. He conceptualized the idea of India Stack, a suite of open APIs (Application Programming Interfaces) that includes Aadhaar authentication, e-KYC, digital signatures, and the Unified Payments Interface (UPI). India Stack has revolutionized how businesses and government services are delivered, promoting financial inclusion and digital empowerment.
One of the most transformative components of India Stack is the UPI, launched in 2016 by the National Payments Corporation of India (NPCI). UPI has simplified digital transactions by enabling instant money transfers between bank accounts using mobile devices. This platform has democratized digital payments, driving financial inclusion and supporting the growth of digital businesses across India.
Nilekani has also played a crucial role in promoting data empowerment and protection through the Data Empowerment and Protection Architecture (DEPA). DEPA enables individuals to securely access and share their data, promoting transparency and user control over personal information.
His efforts have been recognized globally, earning him numerous awards and honors, including being listed among Time magazine’s 100 most influential people in the world in 2006 and 2009 and Padma Bhushan in 2006.Through his visionary leadership and relentless advocacy for technology-driven solutions, Nandan Nilekani has significantly transformed India’s digital landscape.
Source: ChatGPT, image source: wikipedia 
`;
export const ArunaRoy = `Aruna Roy is a prominent Indian social activist and former civil servant known for her tireless efforts in promoting transparency, accountability, and social justice. 
Born on June 26, 1946, in Chennai, Tamil Nadu, she pursued her education at Indraprastha College, Delhi University, and later joined the Indian Administrative Service (IAS) in 1968. After serving for several years, she resigned in 1975 to work directly with grassroots movements and marginalized communities.
Roy's most notable contribution is her role in the Right to Information (RTI) movement in India. She co-founded the Mazdoor Kisan Shakti Sangathan (MKSS) in 1990, a grassroots organization based in Rajasthan that focuses on issues of rural development, workers' rights, and social justice. Under her leadership, MKSS initiated public hearings or "Jan Sunwais" to expose corruption and demand transparency in local governance.
Her persistent advocacy and grassroots mobilization were instrumental in the enactment of the Right to Information Act in 2005, a landmark legislation that empowers citizens to seek information from public authorities, thereby promoting transparency and accountability in government functioning. The RTI Act has been a powerful tool in combating corruption and enhancing democratic participation in India.
Aruna Roy has also been involved in other significant social movements, including the National Campaign for People's Right to Information (NCPRI) and the National Alliance of People's Movements (NAPM). Her work extends to issues like social security for unorganized workers, and she has been a vocal advocate for the Mahatma Gandhi National Rural Employment Guarantee Act (MGNREGA).
Her contributions have been widely recognized, earning her several accolades, including the Ramon Magsaysay Award for Community Leadership in 2000. Aruna Roy continues to inspire and influence the discourse on human rights, social justice, and participatory democracy in India, advocating for the empowerment of the underprivileged and the importance of an accountable and transparent government.
Source: ChatGPT, image source: wikipedia 
`;
export const UPI = `The Unified Payments Interface (UPI), launched by the National Payments Corporation of India (NPCI) in April 2016, is a revolutionary real-time payment system that has transformed the financial landscape of India. 
UPI enables instant money transfers between bank accounts using mobile devices, bypassing the need for traditional banking details like IFSC codes or account numbers, and instead relying on virtual payment addresses (VPAs), mobile numbers, and QR codes. 
With its interoperability, UPI allows users to link multiple bank accounts to a single UPI ID, facilitating seamless transactions 24/7. The system’s robust security measures, including two-factor authentication, ensure the safety of user data. 
UPI supports a wide range of services, from peer-to-peer transfers and merchant payments to utility bill payments and mobile recharges, driving financial inclusion and reducing reliance on cash. Its open API architecture has fostered innovation in the fintech sector, leading to the development of numerous user-friendly applications by banks and payment service providers.
 Major players like BHIM, Google Pay, PhonePe, and Paytm have popularized UPI, contributing to its exponential growth and making it a cornerstone of India’s digital economy. UPI’s impact on promoting transparency, reducing transaction costs, and enhancing economic activity underscores its significance as a transformative digital payment solution.
Source: ChatGPT, image source: wikipedia 
`;
export const VShanta = `Dr. V. Shanta (11 March 1927 – 19 January 2021) was a renowned Indian oncologist and a tireless advocate for cancer care and research. She dedicated her life to the cause of cancer treatment and played a pivotal role in making cancer care accessible to the underprivileged in India. 
Dr. Shanta began her career at the Cancer Institute (WIA) in Chennai, founded by her mentor Dr. Muthulakshmi Reddy. Over the decades, she transformed the institute into a world-class cancer care center, offering comprehensive services including prevention, treatment, and research.
Under her leadership, the Cancer Institute became known for its affordable and high-quality care, focusing on serving economically disadvantaged patients. Dr. Shanta was instrumental in the development of several national cancer control programs and policies, working closely with government agencies to implement effective cancer prevention and treatment strategies across India.
Dr. Shanta's dedication extended beyond medical practice; she was deeply involved in educating the public about cancer prevention and in training healthcare professionals in oncology. 
Her efforts have been recognized with numerous awards, including the Ramon Magsaysay Award in 2005, Padma Shri in 1986, the Padma Bhushan in 2006, and the Padma Vibhushan in 2016, India's fourth, third, and second-highest civilian honors respectively. Dr. V. Shanta's legacy is one of compassion, excellence, and unwavering commitment to the fight against cancer, leaving an indelible mark on the field of oncology in India and beyond.
Source: ChatGPT, image source: wikipedia 
`;
export const RajendraSingh = `Rajendra Singh, born on August 6, 1959, is a prominent Indian environmentalist and water conservationist, often referred to as the "Waterman of India." He is renowned for his pioneering work in revitalizing traditional water management systems and rejuvenating rivers in the arid regions of Rajasthan. 
Singh's journey began in 1985 when he founded the non-governmental organization Tarun Bharat Sangh (TBS) in Alwar, Rajasthan. Under his leadership, TBS undertook the restoration of johads (traditional water storage ponds), check dams, and other rainwater harvesting structures.
Singh's efforts led to the revival of five major rivers in Rajasthan, including the Arvari, Ruparel, Sarsa, Bhagani, and Jahajwali, transforming the once drought-stricken areas into fertile regions with improved groundwater levels and agricultural productivity. 
He is one of the members of the National Ganga River Basin Authority (NGRBA) which was set up in 2009, by the Government of India as an empowered planning, financing, monitoring and coordinating authority for the Ganges, in exercise of the powers conferred under the Environment (Protection) Act, 1986.
Rajendra Singh's work has had a profound impact on water security and rural development in Rajasthan, earning him national and international recognition. In 2001, he was awarded the Ramon Magsaysay Award for Community Leadership. In 2015, he received the Stockholm Water Prize, often considered the Nobel Prize for water, for his exceptional contributions to water resource management and sustainable development.
Singh continues to be an influential advocate for water conservation, environmental sustainability, and community-led natural resource management, inspiring similar initiatives across India and around the world. His dedication to preserving and restoring water ecosystems underscores the critical importance of sustainable practices in addressing global water challenges.
Source: ChatGPT, image source: wikipedia 
`;
export const IGMDP = `India's Integrated Guided Missile Development Program (IGMDP), launched in 1983 under the leadership of Dr. A.P.J. Abdul Kalam, marked a significant milestone in the country's defense technology sector. The program aimed to achieve self-sufficiency in missile technology and reduce dependence on foreign technology.The IGMDP focused on five core missile systems:
1. Prithvi: A tactical surface-to-surface missile with various versions capable of carrying nuclear and conventional warheads. Prithvi's development provided India with a versatile battlefield missile with different ranges.
2. Agni: A family of medium to intercontinental-range ballistic missiles designed to deliver nuclear payloads. The Agni series has evolved significantly, enhancing India's strategic deterrence capability.
3. Akash: A surface-to-air missile system designed to defend against aerial threats. Akash can target aircraft and drones and has been integrated into the Indian armed forces' air defense network.
4. Trishul: A short-range surface-to-air missile intended for naval and land-based applications. Although it faced several development challenges, it laid the groundwork for future air defense systems.
5. Nag: An anti-tank guided missile designed to target armored vehicles with high precision. Nag's development has provided the Indian Army with an advanced weapon against enemy tanks.
The IGMDP's success can be attributed to the collaborative efforts of various Indian research organizations, including the Defence Research and Development Organisation (DRDO), and the support from the Indian government. 
The program significantly bolstered India's indigenous defense manufacturing capabilities, leading to advancements in missile technology, propulsion systems, avionics, and guidance mechanisms.
Under the IGMDP, India not only achieved technological self-reliance but also established itself as a formidable player in missile technology on the global stage.
Source: ChatGPT, image source: wikipedia Image details:Agni-V missile during rehearsal of Republic Day Parade 2013
`;
export const INSArihan = `INS Arihant, launched in 2009, is India's first indigenously built nuclear-powered ballistic missile submarine (SSBN). A key component of India's strategic deterrence capabilities, INS Arihant represents a significant milestone in the country's naval and defense advancements. 
The submarine is part of the Advanced Technology Vessel (ATV) project, a highly classified initiative undertaken by the Indian Navy in collaboration with the Defence Research and Development Organisation (DRDO), Bhabha Atomic Research Centre (BARC), and other key organizations.
INS Arihant is equipped with nuclear-tipped ballistic missiles, providing India with a second-strike capability, a crucial element of a credible nuclear deterrent. The submarine's design allows it to remain submerged for extended periods, enhancing its stealth and survivability. This capability ensures that India can maintain a secure retaliatory strike capability even in the face of a first-strike scenario.
The development and commissioning of INS Arihant involved significant technological and engineering challenges, including the miniaturization of a nuclear reactor to fit within the confines of a submarine. The successful integration of this technology marked a substantial achievement for India's defense sector, demonstrating the country's growing prowess in advanced military technology.
INS Arihant was officially commissioned into service in 2016, after extensive sea trials. It plays a pivotal role in India's nuclear triad, complementing land-based missiles and air-launched nuclear weapons. This triad is essential for maintaining a robust and flexible deterrent posture.
The commissioning of INS Arihant not only bolsters India's strategic defense capabilities but also enhances its status as a major maritime power.
Source: ChatGPT, image source: wikipedia
`;
export const PolioEradicationCampaign = `The Polio Eradication Campaign in India stands as one of the most remarkable public health achievements in the country’s history. 
Launched in 1995, this extensive initiative was driven by a collaboration between the Government of India, the World Health Organization (WHO), UNICEF, Rotary International, and numerous health organizations. The campaign aimed to immunize every child under the age of five against polio, a crippling disease that had caused widespread disability and mortality.
At its peak, the campaign involved the mobilization of millions of health workers and volunteers, who traveled across urban, rural, and remote areas to administer the oral polio vaccine (OPV) to children. 
National Immunization Days (NIDs) became a crucial part of the strategy, with multiple rounds of vaccination ensuring that every child received the necessary doses. The campaign also focused on surveillance and rapid response to any detected cases, implementing immediate localized vaccination drives, known as "mop-up" campaigns, to contain the virus.
Challenges such as logistical issues, vaccine hesitancy, and reaching children in conflict zones or nomadic populations were met with innovative solutions and relentless determination. Extensive awareness campaigns were conducted to educate parents about the importance of vaccination, and the use of social mobilizers helped to build trust within communities.
By 2011, India had reported its last case of wild poliovirus, and in 2014, the WHO declared India polio-free. The Polio Eradication Campaign in India remains a testament to the country's capability to tackle formidable health challenges through persistent and collaborative efforts.
Source: ChatGPT, image source: wikipedia Image details: A child is vaccinated on Pulse Polio Day in Gwalior.
`;
export const KargilWar = `The Kargil War, also known as the Kargil Conflict, took place between May and July 1999 in the Kargil district of Jammu and Kashmir. The war began with the covert infiltration of Pakistani soldiers and Kashmiri militants into positions on the Indian side of the Line of Control (LoC), which serves as the de facto border between India and Pakistan in the disputed region of Kashmir.
The intrusion was initially detected by local shepherds and subsequently confirmed by the Indian Army, prompting a swift military response. India launched Operation Vijay to reclaim the occupied territories, leading to intense fighting in harsh and challenging conditions. 
Indian soldiers had to battle to recapture strategic peaks and ridges, often engaging in close combat at altitudes of over 18,000 feet. The Indian Air Force played a crucial role through Operation Safed Sagar, providing air support to ground troops and targeting enemy positions and supply lines.
The Kargil War attracted significant international attention, with global powers urging both nations to exercise restraint and seek a peaceful resolution. Facing international condemnation and mounting evidence of its direct involvement in the conflict, Pakistan eventually agreed to withdraw its remaining troops from the Indian side of the LoC.
By late July 1999, Indian forces had successfully reclaimed most of the occupied territories, marking the end of the conflict. The Kargil War officially concluded on July 26, 1999, a day now commemorated annually in India as Kargil Vijay Diwas.
The Kargil War remains a pivotal episode in the history of India-Pakistan relations, characterized by strategic challenges, acts of bravery, and significant geopolitical consequences. 
Source: Chat GPT, Image source: Wiki commons
`;
export const ShankarShanmugam = `Shankar Shanmugam, born on August 17, 1963, in Kumbakonam, Tamil Nadu, is a renowned Indian filmmaker, screenwriter, and producer known for his significant contributions to the Tamil cinema industry. 
Often referred to simply as Shankar, he began his career in the film industry as an assistant director before making his directorial debut with the blockbuster "Gentleman" in 1993. This film not only marked his entry into the world of directing but also set the tone for his future works, which are characterized by grand visual spectacles, innovative storytelling, and socially relevant themes. 
Over the years, Shankar has become known for his ambitious projects and technological advancements in Indian cinema. His film "Enthiran" (2010), starring Rajinikanth, was a groundbreaking science fiction movie that set new benchmarks for visual effects and production values in Indian cinema. 
The sequel, "2.0" (2018), continued this trend, becoming one of the most expensive and visually stunning films ever made in India. Shankar's films often feature elaborate song and dance sequences, high-octane action scenes, and a unique blend of entertainment and message-driven content.
In addition to his directorial ventures, Shankar has also produced several successful films through his production company, S Pictures, promoting new talent and diverse genres in Tamil cinema. His contributions have earned him numerous awards and accolades, making him one of the most influential and respected filmmakers in India. 
Shankar's commitment to pushing the boundaries of Indian cinema while addressing pressing social issues has solidified his reputation as a visionary director and a pioneering force in the industry.
`;
export const ArjanSingh = `Air Marshal Arjan Singh, a revered figure in Indian military history, was born on April 15, 1919, in Lyallpur, Punjab (now in Pakistan). He joined the Royal Indian Air Force (RIAF) in 1938 and quickly distinguished himself as a skilled and courageous pilot. 
Singh's leadership and valor were prominently displayed during World War II, where he led air operations in Burma, earning the Distinguished Flying Cross in 1944. After India gained independence, he played a crucial role in transforming the fledgling Indian Air Force (IAF) into a formidable force. 
As the Chief of Air Staff in 1965, during the Indo-Pak War, Singh's strategic acumen and exemplary command were pivotal in achieving air superiority for India, contributing significantly to the nation's defense.
In recognition of his outstanding service, he was promoted to the rank of Marshal of the Indian Air Force in 2002, making him the first and only officer of the IAF to attain this five-star rank, equivalent to a Field Marshal in the Army. 
Beyond his military career, Singh also served as India's Ambassador to Switzerland and the Vatican, and as the Lieutenant Governor of Delhi, showcasing his versatility and commitment to public service. His contributions to the development and modernization of the IAF are monumental, including his efforts in improving training, infrastructure, and operational capabilities. 
Singh passed away on September 16, 2017, at the age of 98, leaving behind a legacy of dedication, bravery, and an indomitable spirit that continues to inspire generations of Indian Air Force personnel. His life and career remain a testament to the highest ideals of military service and national pride.
`;
export const RahulBhatia = `Rahul Bhatia, an influential figure in the aviation industry, is renowned for his pivotal role in the success of IndiGo Airlines, India’s largest and most profitable airline. Born in 1960, Bhatia pursued engineering in Canada, followed by an MBA, which equipped him with the skills and knowledge to embark on an impressive entrepreneurial journey.
In 1989, Bhatia founded InterGlobe Enterprises, a conglomerate with diversified interests in aviation, hospitality, travel technology, and real estate. Under his visionary leadership, InterGlobe grew into a significant player in multiple sectors. However, his most notable achievement came with the establishment of IndiGo Airlines in 2006, in partnership with aviation veteran Rakesh Gangwal.
Bhatia's strategic vision for IndiGo was clear from the outset: to create a low-cost carrier that offers reliability, efficiency, and customer satisfaction. His meticulous approach to cost management, operational efficiency, and disciplined execution set IndiGo apart in a highly competitive industry. Bhatia emphasized punctuality, low fares, and a streamlined operational model, which resonated well with Indian travelers.
Under his stewardship, IndiGo rapidly expanded its fleet and route network, becoming the market leader in Indian aviation. By focusing on a single aircraft model, the Airbus A320, IndiGo optimized maintenance and operational costs. Bhatia’s insistence on maintaining high standards of service while keeping costs low enabled IndiGo to sustain profitability even during challenging times for the aviation industry.
In summary, Rahul Bhatia's achievements and contributions to IndiGo Airlines and the broader aviation industry highlight his strategic brilliance, innovative mindset, and unwavering commitment to excellence. His leadership has not only made IndiGo a household name in India but also a case study in successful low-cost airline operations globally.
`;
export const LieutenantManojKumarPandey = `Lieutenant Manoj Kumar Pandey was a distinguished officer in the Indian Army, remembered for his extraordinary valor and ultimate sacrifice during the Kargil War in 1999. Born on June 25, 1975, in Sitapur, Uttar Pradesh, Pandey exhibited leadership qualities and a strong sense of duty from an early age.
He was commissioned into the 1/11 Gorkha Rifles of the Indian Army in 1997 after graduating from the National Defence Academy. During Operation Vijay, he played a pivotal role in the recapture of the Jubar Top and the Khalubar hills, which were strategically significant positions occupied by Pakistani intruders.
In a series of daring assaults, Lieutenant Pandey led his men with exemplary courage, often under intense enemy fire. On the night of July 3, 1999, during the assault on Khalubar, he led his platoon in a fierce battle, showing indomitable spirit and fearlessness. Despite being grievously injured, he continued to inspire and lead his troops until he succumbed to his injuries. His bravery and leadership were instrumental in the successful capture of enemy positions, contributing significantly to India’s victory in the Kargil War.
For his conspicuous bravery, unwavering leadership, and supreme sacrifice, Lieutenant Manoj Kumar Pandey was posthumously awarded the Param Vir Chakra, India's highest military decoration for valor. 
His legacy endures as a symbol of courage and patriotism, inspiring future generations of Indian soldiers and citizens. The story of his life and heroism is a testament to the spirit of selfless service and dedication to the nation.
`;
export const AnilKakodkar = `Dr. Anil Kakodkar is a distinguished Indian nuclear scientist known for his significant contributions to India's nuclear energy program and his leadership in advancing the country’s nuclear capabilities. 
Born on November 11, 1943, in Barwani, Madhya Pradesh, Kakodkar earned his degree in mechanical engineering from the College of Engineering, Pune, and later joined the Bhabha Atomic Research Centre (BARC) in 1964. His career at BARC saw him rise through the ranks, eventually becoming its Director in 1996. 
As Chairman of the Atomic Energy Commission of India and Secretary to the Government of India’s Department of Atomic Energy from 2000 to 2009, Kakodkar was instrumental in strategizing India's nuclear policy and fostering advancements in nuclear research and technology. He was a key figure in the development of the indigenous Pressurized Heavy Water Reactor (PHWR) technology and the thorium-based Advanced Heavy Water Reactor (AHWR), aimed at utilizing India's abundant thorium resources for sustainable energy.
Kakodkar's leadership was crucial during the Pokhran-II nuclear tests in 1998, which firmly established India as a nuclear power. His advocacy for self-reliance in nuclear technology has significantly reduced India's dependence on foreign nuclear fuel and technology. 
In recognition of his contributions, he has received numerous accolades, including the Padma Shri in 1998, the Padma Bhushan in 1999, and the Padma Vibhushan in 2009, India's third-highest civilian award.
Dr. Anil Kakodkar's legacy extends beyond his technical achievements; he has been a strong proponent of using nuclear energy for peaceful purposes and sustainable development. His efforts in nuclear education and policy have inspired a generation of scientists and engineers, reinforcing India's position as a leader in the global nuclear community.
`;
export const EasterineKire = `Easterine Kire is an acclaimed Naga writer, poet, and academic, celebrated for her significant contributions to literature and her efforts in preserving and promoting the culture and history of Nagaland, a state in northeastern India. 
Born in 1959 in Kohima, Nagaland, Kire pursued her education in English literature, obtaining her PhD from the University of Poona. She began her literary career with poetry and short stories that vividly depicted the life, traditions, and socio-political issues of the Naga people. Her writing is known for its lyrical quality, depth of emotion, and the seamless blend of folklore with contemporary themes.
Kire's first novel, "A Naga Village Remembered," published in 2003, is often credited as the first novel by a Naga writer in English. It chronicles the history and experiences of the Naga people, bringing their rich oral traditions to a wider audience. This work was followed by several other notable novels, including "Mari," "Bitter Wormwood," and "When the River Sleeps," the latter of which won the prestigious Hindu Literary Prize in 2015. "When the River Sleeps" is particularly renowned for its spiritual depth and exploration of indigenous beliefs.
Beyond fiction, Easterine Kire has also authored works on Naga folklore and oral traditions, aiming to document and preserve the rapidly changing cultural landscape of Nagaland. Her scholarly works and storytelling have played a crucial role in giving voice to the Naga people and their unique heritage on a global platform.
Easterine Kire's writing not only provides profound insights into the life and traditions of the Naga people but also serves as a bridge, connecting indigenous narratives with the broader tapestry of world literature. Her efforts have significantly enriched the literary landscape and fostered a deeper appreciation of Naga culture and history
`;
export const RaghunathMashelkar = `Dr. Raghunath Anant Mashelkar is a renowned Indian chemical engineer, scientist, and an influential leader in the field of science and technology. Born on January 1, 1943, in Goa, Mashelkar's journey from humble beginnings to becoming one of India's most respected scientists is truly inspirational. 
He obtained his undergraduate degree in chemical engineering from the University of Bombay and later completed his PhD at the University of Bombay, focusing on polymer science and engineering.
Dr. Mashelkar has made significant contributions to the field of polymer science and engineering, particularly in areas such as rheology and non-Newtonian fluid mechanics. His work has been widely recognized and has earned him numerous accolades, including prestigious fellowships in various national and international scientific academies.
One of his most notable roles was as the Director General of the Council of Scientific and Industrial Research (CSIR) in India from 1995 to 2006. Under his leadership, CSIR emerged as one of the world’s largest publicly funded R&D organizations, with a focus on innovation and industrial collaboration. He was instrumental in the transformation of CSIR, fostering a culture of entrepreneurship and pushing for policy changes that encouraged innovation and intellectual property protection in India.
Dr. Mashelkar has been a vocal advocate for the importance of intellectual property rights, particularly in the context of developing countries. He played a key role in several high-profile patent battles, including the landmark case against the revocation of the patent on the anti-cancer drug Glivec, which underscored the importance of balancing public health needs with intellectual property rights.
He has served on various national and international committees and boards, influencing science policy and innovation strategies. His contributions have been recognized with numerous awards, including the Padma Shri (1991), Padma Bhushan (2000), and Padma Vibhushan (2014), among India's highest civilian honors.Dr. Mashelkar's legacy extends beyond his scientific achievements to his impact on science policy and education in India.
`;
export const RaniRampal = `Rani Rampal is a celebrated Indian field hockey player and former captain of the Indian women's national team, known for her exceptional skill, leadership, and contributions to the sport.
Born on December 4, 1994, in Shahbad, Haryana, Rani's journey from a modest background to becoming one of the most prominent figures in women's hockey is truly inspiring. She started playing hockey at a young age, driven by a passion for the sport and the support of her family and coach, Baldev Singh.
Rani made her debut for the national team at just 15 years old, becoming the youngest player to represent India at the 2010 World Cup. Her remarkable agility, strategic gameplay, and goal-scoring ability quickly set her apart. She played a pivotal role in leading India to numerous victories, including a historic silver medal at the 2018 Asian Games and a gold medal at the 2017 Asia Cup. Under her captaincy, the Indian women's team also qualified for the 2016 Rio Olympics after a 36-year hiatus and reached the quarterfinals of the 2020 Tokyo Olympics, their best-ever finish at the Games.
Rani's individual accolades include being named the 'World Games Athlete of the Year' in 2020 and receiving prestigious awards such as the Arjuna Award in 2016 and the Padma Shri in 2020. Her leadership on and off the field has been instrumental in raising the profile of women's hockey in India, inspiring young athletes across the country.
Beyond her achievements, Rani Rampal's story is a testament to resilience and determination, overcoming socio-economic challenges to achieve excellence. She continues to be a role model, advocating for the empowerment of women through sports and contributing to the development of hockey in India.
`;
export const AditiAshok = `Aditi Ashok is a trailblazing Indian professional golfer who has significantly impacted the world of golf with her remarkable talent and achievements. Born on March 29, 1998, in Bangalore, Karnataka, Aditi began playing golf at the age of five, inspired by her parents. 
Demonstrating prodigious skill from a young age, she quickly ascended through the ranks of amateur golf, winning numerous national and international titles. Her dedication and hard work paid off when she turned professional in 2016 at the age of 18.
Aditi made history by becoming the first Indian golfer to qualify for the Ladies European Tour (LET) and subsequently the LPGA Tour. She garnered attention with her impressive performances, including three LET titles: the Hero Women's Indian Open (2016), the Qatar Ladies Open (2016), and the Fatima Bint Mubarak Ladies Open (2017). Her success on the international stage brought visibility to Indian golf, inspiring many young athletes in the country.
Aditi's most notable achievement came at the 2020 Tokyo Olympics, held in 2021 due to the COVID-19 pandemic, where she finished fourth in the women's individual stroke play event. Her near-podium finish captured the hearts of millions and brought unprecedented attention to golf in India. Aditi's calm demeanor, precision, and strategic gameplay under pressure were widely praised, highlighting her as one of the sport's rising stars.
Off the course, Aditi is known for her humility and commitment to growing the sport in India. She actively participates in initiatives to promote golf among young girls, aiming to break stereotypes and encourage wider participation. Aditi Ashok's journey is marked by her pioneering spirit and relentless pursuit of excellence, making her a significant figure in Indian sports and a role model for aspiring golfers worldwide.
`;
export const MontekSinghAhluwalia = `Montek Singh Ahluwalia is a prominent Indian economist and policy-maker known for his significant contributions to India's economic reforms and policy planning. 
Born on November 24, 1943, in Rawalpindi, British India (now in Pakistan), Ahluwalia completed his education at St. Stephen's College, Delhi, and later earned an M.A. in Economics from the University of Oxford, where he was a Rhodes Scholar. His distinguished career began at the World Bank, but he is best known for his role in shaping India's economic policy during his tenure with the Government of India.
Ahluwalia served in various key positions, including as the Special Secretary to the Prime Minister, Secretary of the Ministry of Finance, and Deputy Chairman of the Planning Commission. 
His tenure at the Planning Commission, from 2004 to 2014, was particularly notable as he played a crucial role in drafting the country's Five-Year Plans, which set the agenda for national development priorities and public investment. Under his leadership, the Planning Commission focused on inclusive growth, aiming to reduce poverty and improve social indicators alongside economic expansion.
He was a key architect of the economic liberalization policies initiated in the early 1990s, which transformed India's economy by reducing state control, encouraging foreign investment, and promoting private sector-led growth. These reforms led to significant improvements in India's economic performance, including higher growth rates, increased foreign reserves, and expanded trade.
Ahluwalia's expertise and insight have also been sought internationally; he has been involved with various global economic forums and organizations, including the International Monetary Fund (IMF) and the World Bank. He has authored numerous articles and papers on economic policy and development issues and is regarded as a thought leader in economic planning and reform.Throughout his career, Montek Singh Ahluwalia has been recognized for his intellectual rigor, pragmatic approach to policy-making, and his contributions to India's economic modernization. 
`;
export const VijayKedia = `Vijay Kedia is a renowned Indian investor and entrepreneur, widely recognized for his acumen in stock market investments and his significant contributions to the Indian financial landscape. 
Born on November 4, 1959, in Kolkata, West Bengal, Kedia started his journey in the stock market at a young age, inspired by his family's involvement in the trading business. Despite facing initial setbacks and financial difficulties, he persevered and eventually established himself as one of India's most successful and respected investors.
Kedia is the founder of Kedia Securities, through which he manages his investments and actively engages in the Indian stock market. His investment philosophy is characterized by a long-term perspective, a deep understanding of the companies he invests in, and a focus on quality management and sustainable business models. 
He is known for identifying multibagger stocks—companies whose shares multiply in value over time—long before they become popular among mainstream investors. His notable investments include companies like Atul Auto, Cera Sanitaryware, and Aegis Logistics, which have delivered substantial returns.
Apart from his investment prowess, Kedia is also a sought-after speaker and mentor, sharing his insights and experiences with budding investors and financial professionals. He emphasizes the importance of patience, continuous learning, and staying grounded despite market fluctuations. His investment strategies and philosophies are often compared to those of legendary investors like Warren Buffett, and he is regarded as a thought leader in the Indian investment community.
Kedia's success story is a testament to his resilience, disciplined approach, and ability to navigate the complexities of the stock market. He continues to inspire and influence a new generation of investors in India, contributing to the broader understanding and growth of the financial markets in the country.
`;
export const SoumyaSwaminathan = `Dr. Soumya Swaminathan is a distinguished Indian pediatrician and clinical scientist known for her extensive work in public health and her leadership roles in global health organizations. 
Born on May 2, 1959, in Chennai, Tamil Nadu, she is the daughter of the renowned agricultural scientist M.S. Swaminathan. Dr. Swaminathan completed her medical degree (MBBS) from the Armed Forces Medical College in Pune and further specialized in pediatrics, earning her MD from the All India Institute of Medical Sciences (AIIMS) in New Delhi. She also completed a postdoctoral fellowship in pediatric pulmonology at the University of Southern California.
Dr. Swaminathan's career is marked by her dedication to addressing infectious diseases, particularly tuberculosis (TB), HIV, and maternal and child health issues. She has made significant contributions to clinical research, policy formulation, and the implementation of public health programs. 
From 2015 to 2017, Dr. Swaminathan served as the Director-General of the Indian Council of Medical Research (ICMR), where she played a pivotal role in advancing medical research and strengthening India's public health infrastructure.
In 2017, Dr. Swaminathan was appointed as the Deputy Director-General for Programmes at the World Health Organization (WHO), and in 2019, she became the first Chief Scientist of WHO. In this role, she has been a central figure in the global response to the COVID-19 pandemic, advocating for evidence-based policies, equitable access to vaccines, and robust public health measures.
Dr. Soumya Swaminathan S contributions to medicine and public health have been recognized with numerous awards and honors. She continues to be an influential voice in global health, committed to improving healthcare outcomes and addressing the challenges of infectious diseases and health inequities worldwide. Her career reflects a profound commitment to science, public service, and the well-being of populations globally.
`;
export const IITF = `The India International Trade Fair (IITF) is one of the largest and most prominent trade fairs in South Asia, organized annually by the India Trade Promotion Organisation (ITPO) at Pragati Maidan in New Delhi.
 Established in 1980, IITF serves as a comprehensive multi-sectoral platform that showcases the diverse range of India's industrial and commercial capabilities. It typically runs for a fortnight in November, attracting participants and visitors from around the globe. The fair features a wide array of products and services across sectors such as textiles, electronics, food processing, healthcare, and tourism.
IITF is renowned for its theme-based pavilions, which highlight the latest innovations and trends in various industries, and for promoting the concept of 'Make in India.' It facilitates extensive business interactions, networking opportunities, and international collaborations, fostering trade and investment.
 With participation from numerous countries, state governments, and private enterprises, IITF serves as a vital hub for launching new products, exploring market opportunities, and enhancing brand visibility. The fair also hosts seminars, conferences, and cultural events, making it a significant event for both business and cultural exchange. Over the years, IITF has played a crucial role in boosting India's trade and commerce, reflecting the country's economic progress and development on a global stage.
`;
export const NASSCOM = `The National Association of Software and Service Companies (NASSCOM) was founded in 1988 by a group of forward-thinking industry leaders with the vision of establishing India as a global IT and business process outsourcing (BPO) hub.
At its inception, the Indian IT industry faced numerous challenges, including limited infrastructure, regulatory barriers, and a lack of skilled workforce. NASSCOM was formed to address these issues by advocating for favorable policies, facilitating access to global markets, and fostering a skilled talent pool. 
Through its concerted efforts, NASSCOM successfully lobbied the government to implement reforms that encouraged foreign investment and streamlined regulatory processes. It also played a pivotal role in promoting the Indian IT sector globally, establishing India as a preferred destination for software development and IT services. 
NASSCOM's initiatives in areas such as quality standards, intellectual property rights, and cybersecurity have been instrumental in building trust with international clients. Additionally, NASSCOM has been proactive in driving innovation and entrepreneurship through initiatives like the 10,000 Startups program, which aims to nurture and scale startups. 
Over the years, NASSCOM has evolved into a key player in shaping India's digital economy, continuously working to enhance the industry's global competitiveness and leadership in emerging technologies such as artificial intelligence, cloud computing, and blockchain. Its efforts have not only propelled the growth of the IT and BPO sectors but also significantly contributed to India's economic development and its reputation as a global technology leader.
`;
export const Y2K = `In India, Y2K preparedness was a monumental effort involving government agencies, private sector companies, and the IT industry, ensuring a smooth transition into the year 2000. 
Recognizing the potential for widespread disruption, the Indian government formed a high-level task force, the National Y2K Action Force, to coordinate and oversee the remediation efforts across various sectors, including finance, telecommunications, transportation, and utilities. This task force worked closely with industry bodies such as the National Association of Software and Service Companies (NASSCOM) to develop and implement a comprehensive Y2K compliance strategy.
Indian IT companies, renowned for their software development and services expertise, played a pivotal role in addressing the Y2K challenge both domestically and internationally. Companies like Tata Consultancy Services (TCS), Infosys, and Wipro undertook extensive projects to identify and fix Y2K-related issues in software and hardware systems for clients around the world. This period marked a significant boost for the Indian IT industry, showcasing its capabilities on a global stage and laying the foundation for its subsequent growth.
Within the country, major sectors such as banking, railways, and aviation conducted rigorous testing and remediation to ensure their systems were Y2K-compliant. The Reserve Bank of India (RBI) issued guidelines and monitored the preparedness of financial institutions to prevent any disruption in banking services. Public awareness campaigns were launched to inform citizens and mitigate any panic related to potential system failures.
On the eve of January 1, 2000, India successfully navigated the Y2K transition with minimal disruptions, thanks to the extensive preparations and coordinated efforts of all stakeholders. The experience not only highlighted India's IT prowess but also strengthened the country's infrastructure and readiness for future technological challenges. The Y2K preparedness drive demonstrated India's capacity for large-scale project management and fostered confidence in its ability to tackle complex global issues.
`;
export const GoldenQuadrilateralProject = `The Golden Quadrilateral Project is one of India's most ambitious infrastructure initiatives, aimed at developing a high-speed road network connecting the four major metropolitan cities of Delhi, Mumbai, Chennai, and Kolkata, thereby forming a quadrilateral. The project was planned in 1999, launched in 2001, and was completed in 7 January 2012.
Launched in 2001 by the then Prime Minister Atal Bihari Vajpayee, this project was part of the National Highways Development Project (NHDP) and aimed to boost economic growth by improving connectivity and reducing transportation costs and travel time. Spanning approximately 5,846 kilometers, the Golden Quadrilateral comprises four-lane highways designed to facilitate efficient movement of goods and passengers across the country.
The project has had a profound impact on India's economic landscape, promoting trade, industry, and tourism. By linking key industrial, agricultural, and cultural regions, it has significantly enhanced accessibility and regional development. The improved road infrastructure has also led to the growth of ancillary industries such as logistics, warehousing, and retail, contributing to job creation and regional economic upliftment.
Technologically advanced construction methods and stringent quality standards were employed to ensure durability and safety. The project faced challenges such as land acquisition, environmental concerns, and coordination between various state governments and agencies. Despite these hurdles, the Golden Quadrilateral was largely completed by 2012, with ongoing upgrades to expand sections to six lanes to accommodate increasing traffic volumes.
Overall, the Golden Quadrilateral Project has been instrumental in transforming India's road transport infrastructure, fostering economic integration, and contributing to the nation's socio-economic development. It stands as a testament to India's capability to execute large-scale infrastructure projects, significantly enhancing the country's connectivity and economic dynamism.
`;
export const InternationalSolarAlliance = `The International Solar Alliance (ISA) was established on November 30, 2015, as a collaborative platform to promote solar energy and address energy needs globally, particularly in solar-rich countries located between the Tropics of Cancer and Capricorn. This initiative was first proposed by Indian Prime Minister Narendra Modi in a speech in November 2015 at Wembley Stadium in which he referred to sunshine countries as Suryaputra ("Sons of the Sun")
The initiative was co-founded by India and France during the 21st Conference of Parties (COP21) to the United Nations Framework Convention on Climate Change (UNFCCC) in Paris. The ISA aims to facilitate the deployment of over 1,000 gigawatts of solar energy and the mobilization of $1 trillion in solar investments by 2030.
Headquartered in Gurugram, India, the ISA focuses on reducing the cost of solar power generation through bulk procurement, promoting research and development, and enhancing the capacity for solar energy production and deployment. 
The alliance operates on key areas such as solar applications for agriculture, affordable finance at scale, mini-grids, rooftop installations, and solar mobility. Through its programs and initiatives, the ISA aims to accelerate the adoption of solar energy, thus contributing to energy security, sustainable development, and climate change mitigation.
Since its inception, the ISA has grown rapidly, with over 120 countries signing the framework agreement, demonstrating a strong global commitment to renewable energy. The alliance has launched various projects and partnerships, collaborating with international financial institutions, governments, and private sector players to drive the solar agenda forward. The establishment of the International Solar Alliance marks a significant step towards achieving global energy transition and fostering sustainable growth through solar energy.
`;
