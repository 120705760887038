import gsap from "gsap";
import { useLayoutEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery, useScreenSize } from "../../utils/responsive";

gsap.registerPlugin(ScrollTrigger);

export const CircularView = ({
  id,
  color,
  prevColor,
  firstColor,
  secondColor,
  fYear,
  sYear,
  punchLine,
}) => {
  const firstYear = fYear ? fYear.split("") : [];
  const secondYear = sYear ? sYear.split("") : [];
  const yearRef = useRef([]);
  const { responsiveWidth } = useMediaQuery();
  const wrapperRef = useRef(null);
  const scrollTriggers = useRef([]);
  const screenSize = useScreenSize();

  const addToRefs = (el) => {
    if (el && !yearRef.current.includes(el)) {
      yearRef.current.push(el);
    }
  };

  useLayoutEffect(() => {
    const wrapper = wrapperRef.current;
    const offsetPercent = 8;

    const borderAnimations = [
      gsap.fromTo(
        `#firstBorder${id}`,
        { scale: 0 },
        {
          scale: 10,
          scrollTrigger: {
            trigger: wrapper,
            start: "top 30%",
            end: "60% 60%",
            scrub: 2,
            anticipatePin: 1,
          },
        }
      ),
      gsap.fromTo(
        `#secondBorder${id}`,
        { scale: 0 },
        {
          scale: 10,
          scrollTrigger: {
            trigger: wrapper,
            start: "top 20%",
            end: "70% 30%",
            scrub: 2,
            anticipatePin: 1,
          },
        }
      ),
      gsap.fromTo(
        `#thirdBorder${id}`,
        { scale: 0 },
        {
          scale: 10,
          scrollTrigger: {
            trigger: wrapper,
            start: "top top",
            end: "70% 30%",
            scrub: 2,
            anticipatePin: 1,
          },
        }
      ),
    ];

    scrollTriggers.current.push(
      ...borderAnimations.map((anim) => anim.scrollTrigger)
    );

    const reversedYearRef = [...yearRef.current].reverse();
    reversedYearRef.forEach((el, index) => {
      const yearAnim = gsap.to(el, {
        yPercent: -50,
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: `40% ${45 + index * offsetPercent}%`,
          end: `40% ${30 + index * offsetPercent}%`,
          scrub: 2,
          anticipatePin: 1,
        },
      });
      scrollTriggers.current.push(yearAnim.scrollTrigger);
    });

    // Use gsap.ticker for better control
    gsap.ticker.add(() => {
      scrollTriggers.current.forEach((trigger) => {
        if (trigger) trigger.update();
      });
    });

    return () => {
      scrollTriggers.current.forEach((trigger) => trigger.kill());
      gsap.ticker.remove();
    };
  }, [id, fYear, sYear]);

  return (
    <div
      className="flex items-center justify-center relative"
      style={{
        height: "150vh",
        willChange: "transform",
        backgroundColor: prevColor,
      }}
      ref={wrapperRef}
    >
      <div className="z-40 pb-10 absolute">
        <div
          style={{
            fontSize: responsiveWidth(31),
          }}
          className="overpass"
        >
          {punchLine}
        </div>
        <div style={{ color: "black" }} className="flex justify-center">
          {firstYear.map((char, index) => (
            <div
              className="overflow-hidden"
              key={`firstYear-${index}`}
              style={{
                height:
                  screenSize === "sm" || screenSize === "xs"
                    ? responsiveWidth(110)
                    : responsiveWidth(210),
                width:
                  screenSize === "sm" || screenSize === "xs"
                    ? responsiveWidth(70)
                    : responsiveWidth(150),
              }}
            >
              <div
                ref={addToRefs}
                style={{
                  fontSize:
                    screenSize === "sm" || screenSize === "xs"
                      ? responsiveWidth(100)
                      : responsiveWidth(200),
                }}
              >
                <span className="box">{char}</span>
                <span className="box">{secondYear[index]}</span>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            fontSize: responsiveWidth(16),
          }}
          className="overpass"
        >
          Please scroll to continue...
        </div>
      </div>
      <div
        id={`firstBorder${id}`}
        className=" rounded-full absolute z-10"
        style={{
          willChange: "transform",
          backgroundColor: firstColor,
          width: responsiveWidth(356),
          height: responsiveWidth(356),
        }}
      ></div>
      <div
        id={`secondBorder${id}`}
        className="rounded-full absolute z-20"
        style={{
          willChange: "transform",
          backgroundColor: secondColor,
          width: responsiveWidth(356),
          height: responsiveWidth(356),
        }}
      ></div>
      <div
        id={`thirdBorder${id}`}
        className="rounded-full absolute z-30"
        style={{
          backgroundColor: color,
          willChange: "transform",
          width: responsiveWidth(356),
          height: responsiveWidth(356),
        }}
      ></div>
    </div>
  );
};
