import { Component } from "react";
import {
  portraits1600,
  portraits1700,
  portraits1750,
  portraits1800,
  portraits1850,
  portraits1900,
  portraits1950,
  portraits1990,
} from "../images";
import {
  AIWC,
  AditiAshok,
  AnilKakodkar,
  AnshuGupta,
  ArdeshirBurjorjiSorabjiGodrej,
  ArjanSingh,
  ArunaRoy,
  AsianGames,
  AzadHindFauj,
  BandaSinghBahadur,
  BardoliSatyagraha,
  BattlePlassey,
  BengalFamine,
  Bhakti,
  BhoodanMovement,
  BishnoiCommunity,
  ChhatrasalBundela,
  DadabhaiNaoroji,
  Dance,
  DirectActionDayriots,
  EPSE,
  EasterineKire,
  ElaBhatt,
  ElattuvalapilSreedharan,
  FirstGeneralElections,
  GhadarMovement,
  GoldenQuadrilateralProject,
  GuruGobindSingh,
  Gurugranth,
  HargobindSingHkhorana,
  HarishChandra,
  IGMDP,
  IGPatel,
  IITF,
  IITs,
  ILPR,
  INC,
  INCIndianNationalArmy,
  INSAT1B,
  INSArihan,
  INWCinema,
  ISI,
  IndianNationalAssociation,
  IndianRebellion,
  IndigoRevolt,
  InternationalSolarAlliance,
  IshwarChandraVidyasagar,
  IshwarchandraGupta,
  JallianwalaBagh,
  JamsetjiTata,
  JavedAbidi,
  KUllasKaranth,
  KakoriConspiracy,
  KamaladeviChattopadhyay,
  KandukuriVeeresalingam,
  KarachiResolution,
  KargilWar,
  LakshmiSehgal,
  LieutenantManojKumarPandey,
  Madhavrao,
  MahadevGovindRanade,
  MaharajaAjitSingh,
  MaharajaJagatSingh2,
  MaharajaRanjitSingh,
  MeghnadSaha,
  MokshagundamVisvesvaraya,
  MontekSinghAhluwalia,
  MoplahRebellion,
  NASSCOM,
  NanaSahib,
  NandalalBose,
  NandanNilekani,
  NizamUlMulk,
  PRBrahmananda,
  PVNarasimhaRao,
  PanditaRamabai,
  PartitionBengal,
  PazhassiRaja,
  PeshwaBalajiBajiRao,
  PindariWar,
  PingaliVenkayya,
  PokhranI,
  PolioEradicationCampaign,
  PrasantaChandraMahalanobis,
  RaghunathMashelkar,
  RahulBhatia,
  RajaRamMohanRoy,
  RajendraSingh,
  RajkumariAmritKaur,
  RaniLakshmibaiJhansi,
  RaniMangammal,
  RaniRampal,
  RowlattSatyagraha,
  RustomjeeJamsetjeeJeejeebhoy,
  SantalRebellion,
  SanyasiRebellion,
  Saraighat,
  SatyendraNathBose,
  ShankarShanmugam,
  Shivaji,
  SilentValleyMovement,
  SilkIndustryBengal,
  Sinhagad,
  SisterNivedita,
  SoumyaSwaminathan,
  SrinivasaRamanujan,
  StatesReorganisationAct,
  SundarLalBahuguna,
  SyedAhmadKhan,
  TVSundaramIyengar,
  Tansen,
  TantiaTop,
  Tarabai,
  TessyThomas,
  TheBattleofAnjengo,
  TheBishnupurRebellion,
  TheBombayPlan,
  TheHirakudDam,
  TheMandalCommission,
  TipuSultan,
  Todarmal,
  TreatyAllahabad,
  TreatyMangalore,
  TreatySalbai,
  UPI,
  UyyalawadaNarasimhaReddy,
  VShanta,
  VernacularPress,
  VijayKedia,
  VijayPandurangBhatkar,
  Virji,
  WahhabiMovement,
  WhiteRevolution,
  Y2K,
} from "./details";
import {
  AhilyaFort,
  CST,
  GateWayOfIndia,
  HavaMahal,
  KeerthiStambha,
  LotusTemple,
  ShaniwarWada,
  StatueOfUnity,
} from "../svg";

export const data1600 = [
  {
    images: [portraits1600.P1600_1, portraits1600.P1600_2],

    names: ["Ramtanu Pandey", "Raja Todarmal"],
    years: ["1493-1589", "1500-1589"],
    details: [Tansen, Todarmal],
  },
  {
    images: [portraits1600.P1600_3, portraits1600.P1600_4],

    names: ["Virji Vora", "Compilation of Guru Granth Sahib"],
    years: ["1590-1670", "1604"],
    details: [Virji, Gurugranth],
  },
  {
    images: [portraits1600.P1600_5, portraits1600.P1600_6],

    names: ["Battle Of Sinhagad", "Battle Of Saraighat"],
    years: ["1670", "1671"],
    details: [Sinhagad, Saraighat],
  },
  {
    images: [portraits1600.P1600_7, portraits1600.P1600_8],

    names: ["Banda Singh Bahadur", "Nizam- ul- mulk"],
    years: ["1670-1716", "1671-1748"],
    details: [BandaSinghBahadur, NizamUlMulk],
  },
  {
    images: [portraits1600.P1600_9, portraits1600.P1600_10],

    names: ["Rani Tarabai", "Chhatrapati Shivaji Maharaj"],
    years: ["1678-1750", "1630-1680"],
    details: [Tarabai, Shivaji],
  },
];

export const data1700 = [
  {
    images: [portraits1700.P1700_1, portraits1700.P1700_2],
    names: ["Guru Gobind Singh", "Chhatrasal Bundela"],
    years: ["1666-1708", "1649-1731"],
    details: [GuruGobindSingh, ChhatrasalBundela],
  },
  {
    images: [portraits1700.P1700_3, portraits1700.P1700_4],
    names: ["Maharaja Ajit Singh of Marwar", "Rani Mangammal"],
    years: ["1679-1724", "1689-1704"],
    details: [MaharajaAjitSingh, RaniMangammal],
  },
  {
    images: [portraits1700.P1700_5, portraits1700.P1700_6],
    names: [
      "Maharaja Jagat Singh II of Mewar",
      "The Golden Era of Bengal Silk Industry",
    ],
    years: ["1709-1751", "1717-1757"],
    details: [MaharajaJagatSingh2, SilkIndustryBengal],
  },
  {
    images: [portraits1700.P1700_7, portraits1700.P1700_8],
    names: [
      "Battle of Anjengo",
      "The Bishnoi Community's Environmental Conservation Efforts",
    ],
    years: ["1721", "1730"],
    details: [TheBattleofAnjengo, BishnoiCommunity],
  },
  {
    images: [portraits1700.P1700_9, portraits1700.P1700_10],
    names: ["The Bishnupur Rebellion", "Madhavrao Peshwa 1"],
    years: ["1742-1748", "1745-1772"],
    details: [TheBishnupurRebellion, Madhavrao],
  },
];
export const data1750 = [
  {
    images: [portraits1750.P1, portraits1750.P2],
    names: ["Peshwa Balaji Rao", "Tipu Sultan"],
    years: ["1720-1761", "1751-1799"],
    details: [PeshwaBalajiBajiRao, TipuSultan],
  },
  {
    images: [portraits1750.P3, portraits1750.P4],
    names: ["Pazhassi Raja", "Battle of Plassey"],
    years: ["1753-1805", "1757"],
    details: [PazhassiRaja, BattlePlassey],
  },
  {
    images: [portraits1750.P5, portraits1750.P6],
    names: ["Treaty of Allahbad", "The Bengal Famine 1770"],
    years: ["1765", "1770"],
    details: [TreatyAllahabad, BengalFamine],
  },
  {
    images: [portraits1750.P7, portraits1750.P8],
    names: ["Raja Ram Mohan Roy", "Treaty of Salbai"],
    years: ["1772-1833", "1782"],
    details: [RajaRamMohanRoy, TreatySalbai],
  },
  {
    images: [portraits1750.P9, portraits1750.P10],
    names: [
      "Treaty of Mangalore",
      "The revival of Indian Classical Dance forms",
    ],
    years: ["1784", "1750s"],
    details: [TreatyMangalore, Dance],
  },
  {
    images: [portraits1750.P11],
    names: ["Bhakti movement"],
    years: ["17th to 18th century"],
    details: [Bhakti],
  },
];
export const data1800 = [
  {
    images: [portraits1800.P1, portraits1800.P2],
    names: ["The Sanyasi Rebellion", "Maharaja Ranjit Singh"],
    years: ["1770-1800", "1780-1839"],
    details: [SanyasiRebellion, MaharajaRanjitSingh],
  },
  {
    images: [portraits1800.P3, portraits1800.P4],
    names: ["Wahhabi Movement", "Uyyalawada Narasimha Reddy"],
    years: ["1800-1832", "1806-1847"],
    details: [WahhabiMovement, UyyalawadaNarasimhaReddy],
  },
  {
    images: [portraits1800.P5, portraits1800.P6],
    names: ["Ishwarchandra Gupta", "Tantia Tope"],
    years: ["1812-1859", "1814-1859"],
    details: [IshwarchandraGupta, TantiaTop],
  },
  {
    images: [portraits1800.P7, portraits1800.P8],
    names: ["Pindari War", "Nana Sahib"],
    years: ["1817-1818", "1824"],
    details: [PindariWar, NanaSahib],
  },
  {
    images: [portraits1800.P9, portraits1800.P10],
    names: ["Rustomjee Jamsetjee Jeejeebhoy", "Dadabhai Naoroji"],
    years: ["1825-1917", "1825-1917"],
    details: [RustomjeeJamsetjeeJeejeebhoy, DadabhaiNaoroji],
  },
  {
    images: [portraits1800.P11],
    names: ["Lakshmi Bai of Jhansi"],
    years: ["1828-1858"],
    details: [RaniLakshmibaiJhansi],
  },
];

export const data1850 = [
  {
    images: [portraits1850.P1, portraits1850.P2],
    names: ["Sir Syed Ahmad Khan", "Ishwar Chandra Vidyasagar"],
    years: ["1817-1898", "1820-1891"],
    details: [SyedAhmadKhan, IshwarChandraVidyasagar],
  },
  {
    images: [portraits1850.P3, portraits1850.P4],
    names: ["Jamsetji Tata", "Mahadev Govind Ranade"],
    years: ["1839-1904", "1842-1901"],
    details: [JamsetjiTata, MahadevGovindRanade],
  },
  {
    images: [portraits1850.P5, portraits1850.P6],
    names: ["The Indigo Revolt", "The Santhal Rebellion"],
    years: ["1859-1860", "1855-1856"],
    details: [IndigoRevolt, SantalRebellion],
  },
  {
    images: [portraits1850.P7, portraits1850.P8],
    names: ["Indian Rebellion", "Sister Nivedita"],
    years: ["1857-1858", "1867–1911"],
    details: [IndianRebellion, SisterNivedita],
  },
  {
    images: [portraits1850.P9, portraits1850.P10],
    names: ["Indian National Association", "The Vernacular Press Act"],
    years: ["1876", "1878"],
    details: [IndianNationalAssociation, VernacularPress],
  },
  {
    images: [portraits1850.P11, portraits1850.P12],
    names: ["Formation of INC", "Formation of Indian Army"],
    years: ["1885", "1895"],
    details: [INC, INCIndianNationalArmy],
  },
];

export const data1900 = [
  {
    images: [portraits1900.P1, portraits1900.P2],
    names: ["Partition of Bengal", "The Ghadar Movement"],
    years: ["1905", "1913"],
    details: [PartitionBengal, GhadarMovement],
  },
  {
    images: [portraits1900.P3, portraits1900.P4],
    names: ["Rowlatt Satyagrah", "Jallianwala Bagh massacre"],
    years: ["1919", "13 April, 1919"],
    details: [RowlattSatyagraha, JallianwalaBagh],
  },
  {
    images: [portraits1900.P5, portraits1900.P6],
    names: ["Kandukuri Veeresalingam", "Srinivasa Ramanujan"],
    years: ["16 April 1848 – 27 May 1919", "1887-1920"],
    details: [KandukuriVeeresalingam, SrinivasaRamanujan],
  },
  {
    images: [portraits1900.P7, portraits1900.P8],
    names: ["Moplah (or Mappila) Rebellion", "Pandita Ramabai Sarasvati "],
    years: ["1921", "23 April 1858 – 5 April 1922"],
    details: [MoplahRebellion, PanditaRamabai],
  },
  {
    images: [portraits1900.P9, portraits1900.P10],
    names: ["Kakori Conspiracy", "All India Women's Conference (AIWC)"],
    years: ["1925", "1927"],
    details: [KakoriConspiracy, AIWC],
  },
  {
    images: [portraits1900.P11, portraits1900.P12],
    names: ["Bardoli Satyagrah", "The Indian Statistical Institute"],
    years: ["1928", "1931"],
    details: [BardoliSatyagraha, ISI],
  },
  {
    images: [portraits1900.P13, portraits1900.P14],
    names: ["The Karachi Resolution", "The Azad Hind Fauj"],
    years: ["1931", "1942"],
    details: [KarachiResolution, AzadHindFauj],
  },
  {
    images: [portraits1900.P15, portraits1900.P16],
    names: ["Ardeshir Burjorji Sorabji Godrej", "Direct Action Day riots"],
    years: ["1868–1936", "1946"],
    details: [ArdeshirBurjorjiSorabjiGodrej, DirectActionDayriots],
  },
  {
    images: [portraits1900.P17, portraits1900.P18],
    names: ["T.V. Sundaram Iyengar", "Meghnad Saha"],
    years: ["22 March 1877 – 28 April 1955", "1893-1956"],
    details: [TVSundaramIyengar, MeghnadSaha],
  },
  {
    images: [portraits1900.P19, portraits1900.P20],
    names: ["Prasanta Chandra Mahalanobis", "Kamaladevi Chattopadhyay"],
    years: ["1893-1972", "1903-1988"],
    details: [PrasantaChandraMahalanobis, KamaladeviChattopadhyay],
  },
  {
    images: [portraits1900.P21, portraits1900.P22],
    names: ["Sir M. Visvesvaraya", "Pingali Venkayya"],
    years: ["1861-1962", "2 August 1876 – 4 July 1963"],
    details: [MokshagundamVisvesvaraya, PingaliVenkayya],
  },
  {
    images: [portraits1900.P23, portraits1900.P24],
    names: ["Rajkumari Amrit Kaur", "Nandalal Bose"],
    years: [
      "2 February 1887 – 6 February 1964",
      "3 December 1882 – 16 April 1966",
    ],
    details: [RajkumariAmritKaur, NandalalBose],
  },
];

export const data1950 = [
  {
    images: [portraits1950.P1, portraits1950.P2],
    names: ["Satyendra Nath Bose", "Lakshmi Sehgal"],
    years: [
      " 1 January 1894 – 4 February 1974",
      "24 October 1914 – 23 July 2012",
    ],
    details: [SatyendraNathBose, LakshmiSehgal],
  },
  {
    images: [portraits1950.P3, portraits1950.P4],
    names: ["P. V. Narasimha Rao", "Hargobind singh khorana"],
    years: [
      "28 June 1921 – 23 December 2004",
      "9 January 1922 – 9 November 2011",
    ],
    details: [PVNarasimhaRao, HargobindSingHkhorana],
  },
  {
    images: [portraits1950.P5, portraits1950.P6],
    names: ["Harish-Chandra Mehrotra ", "Indraprasad Gordhanbhai Patel"],
    years: [
      "11 October 1923 – 16 October 1983",
      "11 November 1924 – 17 July 2005",
    ],
    details: [HarishChandra, IGPatel],
  },
  {
    images: [portraits1950.P7, portraits1950.P8],
    names: ["Palahalli Ramaiya (P.R.) Brahmananda", "Sunderlal Bahuguna"],
    years: [
      "27 September 1926 – 23 January 2003",
      "9 January 1927 – 21 May 2021",
    ],
    details: [PRBrahmananda, SundarLalBahuguna],
  },
  {
    images: [portraits1950.P9, portraits1950.P10],
    names: ["Elattuvalapil Sreedharan", "Ela Bhatt"],
    years: ["12 June, 1932", "7 September 1933 – 2 November 2022"],
    details: [ElattuvalapilSreedharan, ElaBhatt],
  },
  {
    images: [portraits1950.P11, portraits1950.P12],
    names: ["Bombay Plan", "Hirakud Dam Construction"],
    years: ["1944-1945", "15 March 1946"],
    details: [TheBombayPlan, TheHirakudDam],
  },

  {
    images: [portraits1950.P13, portraits1950.P14],
    names: [
      "Establishment of Public Sector Enterprises",
      "First General Elections",
    ],
    years: ["1950s-1970s", "25 October 1951 - 21 February 1952"],
    details: [EPSE, FirstGeneralElections],
  },
  {
    images: [portraits1950.P15, portraits1950.P16],
    names: ["The Bhoodan Movement", "Indian New Wave Cinema (Parallel Cinema)"],
    years: ["18 April, 1951", "1952–1992 "],
    details: [BhoodanMovement, INWCinema],
  },
  {
    images: [portraits1950.P17, portraits1950.P18],
    names: ["States Reorganisation Act", "The Silent Valley Movement"],
    years: ["1956", "1966"],
    details: [StatesReorganisationAct, SilentValleyMovement],
  },
  {
    images: [portraits1950.P19, portraits1950.P20],
    names: ["White revolution", "Pokhran-I (Smiling Buddha)"],
    years: ["January 13, 1970", "18 May 1974"],
    details: [WhiteRevolution, PokhranI],
  },
  {
    images: [portraits1950.P21, portraits1950.P22],
    names: ["Mandal Commission", "Industrial Licensing Policy Reforms"],
    years: ["1979", "1980s "],
    details: [TheMandalCommission, ILPR],
  },
  {
    images: [portraits1950.P23, portraits1950.P24],
    names: ["Asian Games", "INSAT-1B Launch"],
    years: ["19 November to 4 December 1982", "30 August, 1983"],
    details: [AsianGames, INSAT1B],
  },
  {
    images: [portraits1950.P25],
    names: ["Establishment of the Indian Institutes of Technology (IITs)"],
    years: ["2–3 December 1984"],
    details: [IITs],
  },
];
export const data1990 = [
  {
    images: [portraits1990.P1, portraits1990.P2],
    names: ["Tessy Thomas", "Javed Abidi"],
    years: [" April 1963", "11 June 1965 – 4 March 2018"],
    details: [TessyThomas, JavedAbidi],
  },
  {
    images: [portraits1990.P3, portraits1990.P4],
    names: ["Vijay Pandurang Bhatkar", "Anshu Gupta"],
    years: ["11 October 1946", "24 August, 1970"],
    details: [VijayPandurangBhatkar, AnshuGupta],
  },
  {
    images: [portraits1990.P5, portraits1990.P6],
    names: ["K. Ullas Karanth", "Nandan Nilekani"],
    years: ["24 October, 1948", "2 June, 1955"],
    details: [KUllasKaranth, NandanNilekani],
  },
  {
    images: [portraits1990.P7, portraits1990.P8],
    names: ["Aruna Roy", "Viswanathan Shanta"],
    years: ["26 June, 1946", "11 March 1927 – 19 January 2021"],
    details: [ArunaRoy, VShanta],
  },
  {
    images: [portraits1990.P9, portraits1990.P10],
    names: ["Rajendra Singh", "Arjan Singh"],
    years: [" 6 August 1959", "15 April 1919 – 16 September 2017"],
    details: [RajendraSingh, ArjanSingh],
  },
  {
    images: [portraits1990.P11, portraits1990.P12],
    names: ["Shankar Shanmugam", "Rahul bhatia"],
    years: ["17 August, 1963", "1960"],
    details: [ShankarShanmugam, RahulBhatia],
  },
  {
    images: [portraits1990.P13, portraits1990.P14],
    names: ["Lieutenant Manoj Kumar Pandey", "Anil Kakodkar"],
    years: ["25 June 1975 – 3 July 1999", "11 November 1943"],
    details: [LieutenantManojKumarPandey, AnilKakodkar],
  },

  {
    images: [portraits1990.P15, portraits1990.P16],
    names: ["Easterine Kire", "Dr. Raghunath Mashelkar"],
    years: ["29 March 1959 ", "1 January 1943"],
    details: [EasterineKire, RaghunathMashelkar],
  },
  {
    images: [portraits1990.P17, portraits1990.P18],
    names: ["Rani Rampal", "Aditi Ashok"],
    years: ["4 December 1994", "29 March 1998"],
    details: [RaniRampal, AditiAshok],
  },
  {
    images: [portraits1990.P19, portraits1990.P20],
    names: ["Montek Singh Ahluwalia", "Vijay Kedia"],
    years: ["24 November 1943", "4 November 1959"],
    details: [MontekSinghAhluwalia, VijayKedia],
  },
  {
    images: [portraits1990.P21, portraits1990.P22],
    names: [
      "Dr. Soumya Swaminathan",
      "India's Integrated Guided Missile Development Program",
    ],
    years: ["2 May 1959", "1983"],
    details: [SoumyaSwaminathan, IGMDP],
  },
  {
    images: [portraits1990.P23, portraits1990.P24],
    names: ["Polio Eradication Campaign", "Kargil War"],
    years: ["1995", "May- July 1999"],
    details: [PolioEradicationCampaign, KargilWar],
  },
  {
    images: [portraits1990.P25, portraits1990.P26],
    names: ["INS Arihant ", "Unified Payments Interface"],
    years: ["2009", "2016"],
    details: [INSArihan, UPI],
  },
  {
    images: [portraits1990.P27, portraits1990.P28],
    names: ["The India International Trade Fair (IITF)", "NASSCOM"],
    years: ["1980", "1988"],
    details: [IITF, NASSCOM],
  },
  {
    images: [portraits1990.P29, portraits1990.P30],
    names: ["Y2K preparedness", "Golden Quadrilateral Project"],
    years: ["2000", "2001-2012"],
    details: [Y2K, GoldenQuadrilateralProject],
  },
  {
    images: [portraits1990.P31],
    names: ["International Solar Alliance"],
    years: ["2015"],
    details: [InternationalSolarAlliance],
  },
];

export const PeriodData = [
  {
    period: "1600",
    prevPeriod: "0000",
    data: data1600,
    bgColor: "#FDFDE3",
    prevBgColor: "white",
    firstColor: "#FFD5A0",
    secondColor: "#FFECBD",
    periodColor: "#E23F63",
    punchLine: "Golden era of innovation",
    statue: {
      name: "Kirti Stambh",
      place: "Chittor, Rajasthan",
      year: "12th Century CE",
      imageWidth: 350,
      mobileImageWidth: 250,
      image: KeerthiStambha,
    },
  },
  {
    period: "1700",
    prevPeriod: "1690",
    data: data1700,
    bgColor: "#F9FFF5",
    prevBgColor: "#FDFDE3",
    firstColor: "#ACCCB7",
    secondColor: "#D5E6CA",
    periodColor: "#B1A42A",
    punchLine: "Trade flourishes, economy grows",
    statue: {
      name: "Shaniwar Wada",
      place: "Pune, Maharashtra",
      year: "1732",
      image: ShaniwarWada,
      imageWidth: 550,
      mobileImageWidth: 250,
    },
  },
  {
    period: "1750",
    prevPeriod: "1740",
    data: data1750,
    bgColor: "#FEF4FA",
    prevBgColor: "#F9FFF5",
    firstColor: "#B9A6B2",
    secondColor: "#FFE2DA",
    periodColor: "#B34689",
    punchLine: "Colonial challenges, cultural resilience",
    statue: {
      name: "Ahilya Fort",
      place: "Maheshwar, Madhya Pradesh",
      year: "1766",
      image: AhilyaFort,
      fontColor: "#B34689",
      imageWidth: 800,
      mobileImageWidth: 250,
      opacity: 0.7,
    },
  },
  {
    period: "1800",
    prevPeriod: "1790",
    data: data1800,
    bgColor: "#F8FAFF",
    prevBgColor: "#FEF4FA",
    firstColor: "#A4ABBD",
    secondColor: "#D3DEF5",
    periodColor: "#3E527C",
    punchLine: "Reforms ignite India's progress.",
    statue: {
      name: "Hawa Mahal",
      place: "Jaipur, Rajasthan",
      year: "1799",
      imageWidth: 650,
      mobileImageWidth: 250,
      image: HavaMahal,
      fontColor:'#3E527C'
    },
  },
  {
    period: "1850",
    prevPeriod: "1840",
    data: data1850,
    bgColor: "#F3FEE8",
    prevBgColor: "#F8FAFF",
    firstColor: "#6C8F90",
    secondColor: "#9FB18E",
    periodColor: "#789E53",
    punchLine: "Industrialization sparks India's transformation.",
    statue: {
      name: "Chatrapati Shivaji Termnial",
      place: "Mumbai, Maharashtra",
      year: "1887",
      imageWidth: 800,
      mobileImageWidth: 250,
      image: CST,
      fontColor:'#3E527C'
    },
  },
  {
    period: "1900",
    prevPeriod: "1890",
    data: data1900,
    bgColor: "#FFF4F4",
    prevBgColor: "#F3FEE8",
    firstColor: "#E38ED9",
    secondColor: "#FFC5FF",
    periodColor: "#970000",
    punchLine: "",
    statue: {
      name: "Gateway of India",
      place: "Mumbai, Maharashtra",
      year: "1924",
      imageWidth: 550,
      mobileImageWidth: 250,
      image: GateWayOfIndia,
      fontColor: "#970000",
    },
  },
  {
    period: "1950",
    prevPeriod: "1940",
    data: data1950,
    bgColor: "#FFF7E1",
    prevBgColor: "#FFF4F4",
    firstColor: "#FB8D76",
    secondColor: "#A8CFBD",
    periodColor: "#DAA520",
    punchLine: "Transformation through struggle, resilience",
    statue: {
      name: "Lotus Temple",
      place: "New Delhi, Delhi",
      year: "1986",
      imageWidth: 650,
      mobileImageWidth: 250,
      image: LotusTemple,
      fontColor:'#DAA520'
    },
  },
  {
    period: "1990",
    prevPeriod: "1940",
    data: data1990,
    bgColor: "#FAFCFF",
    prevBgColor: "#FFF7E1",
    firstColor: "#FFB174",
    secondColor: "#FDE66C",
    periodColor: "#0258B7",
    punchLine: "New dawn, rapid progress",
    statue: {
      name: "Statue of Unity",
      place: "New Delhi, Delhi",
      year: "2018",
      imageWidth: 250,
      mobileImageWidth: 250,
      image: StatueOfUnity,
      fontColor: "#0258B7",
    },
  },
];
