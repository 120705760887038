import React, { useEffect, useRef, useState } from "react";
import { Description } from "./description";
import { Intro } from "./introduction/intro";
import { TopBar } from "./top.bar";
import { PeriodData } from "../utils/periodsData/data";
import { Periods } from "./periods/periods";
import { BackgroundImages } from "../utils/images";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { JumpTo } from "./periods/jumpTo";
import { AboutUs } from "./aboutus";
import { ContactForm } from "./contactus";
import { Loader } from "./loader";
import { useMediaQuery, useScreenSize } from "../utils/responsive";
import { BottomBar } from "./bottom.bar";

// Register the ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

export function Main({ pauseAudio, playAudio, isPause }) {
  const [pause, setPause] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);
  const { responsiveHeight } = useMediaQuery();
  const screenSize = useScreenSize();

  const years = [
    "1600",
    "1700",
    "1750",
    "1800",
    "1850",
    "1900",
    "1950",
    "1990",
  ];

  const sectionRefs = useRef(
    years.reduce((acc, year) => {
      acc[year] = React.createRef();
      return acc;
    }, {})
  ).current;

  const introRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);
  const descriptionRef = useRef(null);

  const scrollTo = (year) => {
    const sectionRef = sectionRefs[year];
    if (sectionRef && sectionRef.current) {
      gsap.to(window, {
        scrollTo: {
          y: sectionRef.current,
          offsetY:
            screenSize === "xs" || screenSize === "sm"
              ? -responsiveHeight(260)
              : -responsiveHeight(190),
        },
        duration: 4,
        ease: "power2.out",
      });
    }
  };

  const scrollToTopBar = (ref, offsetY) => {
    if (ref.current) {
      gsap.to(window, {
        scrollTo: { y: ref.current, offsetY: offsetY },
        duration: 4,
        ease: "power2.out",
      });
    }
  };

  const handleImageClick = () => {
    setPause(isPause);
    pauseAudio();
    openDialogs();
  };

  const openDialogs = () => {
    gsap.to("#periods", {
      opacity: 0,
      y: -300,
      duration: 0.3,
      ease: "power2.inOut",
    });
    gsap.to("#jumpto", {
      opacity: 0,
      y: 200,
      duration: 0.3,
      ease: "power2.inOut",
    });
  };

  const closeDialgs = () => {
    gsap.to(["#jumpto", "#periods"], {
      opacity: 1,
      y: 0,
      duration: 0.3,
      ease: "power2.inOut",
    });
  };

  const handleCloseFullScreen = () => {
    if (!pause) playAudio();
    closeDialgs();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="overflow-x-clip">
      {showOverlay && <Loader />}
      <div ref={introRef}>
        <Intro
          onClick={() => scrollToTopBar(descriptionRef, responsiveHeight(350))}
        />
      </div>
      <div>
        <Description jumpTo1600={scrollTo} scrollRef={descriptionRef} />
      </div>
      <TopBar
        scrollToContactUs={() => scrollToTopBar(contactUsRef)}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
        isPause={isPause}
        scrollToAboutUs={() => scrollToTopBar(aboutUsRef)}
        scrollToIntro={() => scrollToTopBar(introRef)}
      />
      <div>
        {PeriodData.map(
          (
            {
              period,
              data,
              bgColor,
              prevBgColor,
              periodColor,
              firstColor,
              secondColor,
              prevPeriod,
              punchLine,
              statue,
            },
            index
          ) => (
            <div key={period} ref={sectionRefs[period]} data-period={period}>
              <Periods
                period={period}
                prevPeriod={prevPeriod}
                bgColor={bgColor}
                prevBgColor={prevBgColor}
                firstColor={firstColor}
                secondColor={secondColor}
                periodColor={periodColor}
                BackgroundImages={BackgroundImages[index]}
                data={data}
                handleImageClick={handleImageClick}
                handleCloseFullScreen={handleCloseFullScreen}
                id={index}
                punchLine={punchLine}
                statue={statue}
              />
            </div>
          )
        )}
        <JumpTo onClick={scrollTo} years={years} />
      </div>
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>
      <div ref={contactUsRef}>
        <ContactForm />
      </div>
      <BottomBar />
    </div>
  );
}
