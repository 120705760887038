import React, { useRef, useState, useEffect } from "react";
import gsap from "gsap";
import { useMediaQuery, useScreenSize } from "../../utils/responsive";

export function JumpTo({ onClick, years }) {
  const yearDividerRef = useRef(null);
  const vDividerRef = useRef([]);
  const yearRef = useRef([]);
  const mainRef = useRef(null);
  const { responsiveWidth } = useMediaQuery();
  const lineWidth = responsiveWidth(240);
  const screenSize = useScreenSize();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const yearDivider = yearDividerRef.current;
    const vdividerRef = vDividerRef.current;
    const yRef = yearRef.current;
    const ref = mainRef.current;

    gsap.killTweensOf(yearDivider);
    vdividerRef.forEach((divider) => gsap.killTweensOf(divider));
    yRef.forEach((year) => gsap.killTweensOf(year));

    const tl = gsap.timeline({ paused: true });
    tl.to(yearDivider, {
      width: lineWidth,
      duration: 0.3,
      ease: "power2.inOut",
    });
    vdividerRef.forEach((divider, index) => {
      tl.to(
        divider,
        { opacity: 1, duration: 0.1, ease: "power2.inOut" },
        index * 0.1
      );
    });
    vdividerRef.forEach((divider, index) => {
      const yearTimeline = gsap.timeline({ paused: true });

      yearTimeline.to(yRef[index], {
        opacity: 1,
        duration: 0.1,
        ease: "power2.inOut",
      });

      const handleMouseEnter = () => {
        yearTimeline.play();
      };

      const handleMouseLeave = () => {
        yearTimeline.reverse();
      };

      divider.addEventListener("mouseenter", handleMouseEnter);
      divider.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        divider.removeEventListener("mouseenter", handleMouseEnter);
        divider.removeEventListener("mouseleave", handleMouseLeave);
      };
    });

    const handleMouseEnterMain = () => {
      setIsHovered(true);
      tl.play();
    };

    const handleMouseLeaveMain = () => {
      setIsHovered(false);
      tl.reverse();
    };

    ref.addEventListener("mouseenter", handleMouseEnterMain);
    ref.addEventListener("mouseleave", handleMouseLeaveMain);

    return () => {
      ref.removeEventListener("mouseenter", handleMouseEnterMain);
      ref.removeEventListener("mouseleave", handleMouseLeaveMain);
    };
  }, [lineWidth]);

  return (
    <div className="flex sticky-year justify-end">
      <div
        ref={mainRef}
        id="jumpto"
        className="flex items-center overpass font-bold"
        style={{
          fontSize: responsiveWidth(25),
          paddingBottom:
            screenSize === "sm" || screenSize === "xs"
              ? 16
              : responsiveWidth(50),
          paddingRight:
            screenSize === "sm" || screenSize === "xs"
              ? 16
              : responsiveWidth(80),
        }}
      >
        <div>1600</div>
        <div
          ref={yearDividerRef}
          className="bg-black h-[3px] flex justify-center items-center m-2 mb-4"
          style={{ width: responsiveWidth(10) }}
        >
          {[...Array(8)].map((_, index) => (
            <div key={index} className="justify-center items-center mb-4">
              <div
                style={{ fontSize: responsiveWidth(12) }}
                className="opacity-0"
                ref={(el) => (yearRef.current[index] = el)}
              >
                {years[index]}
              </div>
              <div
                ref={(el) => (vDividerRef.current[index] = el)}
                className="mx-2 opacity-0 cursor-pointer"
                onClick={() => isHovered && onClick(years[index])}
              >
                |
              </div>
            </div>
          ))}
        </div>
        <div>2024</div>
      </div>
    </div>
  );
}
