import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { CLOSE, Left, Right, SHARE } from "../../../utils/icons";

export function MobileViewDetails({
  data,
  index,
  imgIndex,
  bgColor,
  period,
  closeFullScreen,
}) {
  const imageRef = useRef();
  const colorRef = useRef();
  const detailRef = useRef();
  const headRef = useRef();
  const yearRef = useRef();
  const [isImageFullHeight, setIsImageFullHeight] = useState(false);

  const [newIndex, setNewIndex] = useState(index);
  const [newImgIndex, setNewImgIndex] = useState(imgIndex);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

    tl.set(imageRef.current, { scale: 0.5, opacity: 0, visibility: "visible" });
    tl.set(headRef.current, { scale: 0, opacity: 0, visibility: "visible" });
    tl.set(yearRef.current, { scale: 0, opacity: 0, visibility: "visible" });
    tl.set(detailRef.current, { y: 150, opacity: 0, visibility: "visible" });
    tl.set("#buttons", { opacity: 0, visibility: "visible" });
    tl.set(colorRef.current, { x: -100, opacity: 1, visibility: "visible" });

    tl.fromTo(
      imageRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 1 }
    );
    tl.fromTo(
      headRef.current,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5 }
    );

    tl.fromTo(
      yearRef.current,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.7 },
      "-=0.3"
    );

    tl.fromTo(
      detailRef.current,
      { y: 150, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.7 },
      "-=0.3"
    );

    tl.fromTo("#buttons", { opacity: 0 }, { opacity: 1 }, "-=0.3");
    gsap.fromTo(
      colorRef.current,
      { scaleY: 10 },
      { scaleY: 1, duration: 1, ease: "power2.inOut" }
    );
  }, []);

  const toggleImageFullHeight = () => {
    setIsImageFullHeight(!isImageFullHeight);

    if (!isImageFullHeight) {
      // Expand image to full height and center
      gsap.to(imageRef.current, {
        scale: 1.5,
        yPercent: -50,
        xPercent: -50,
        top: "70%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      });
      // Hide other content
      gsap.set(
        [
          headRef.current,
          yearRef.current,
          detailRef.current,
          "#buttons",
          colorRef.current,
        ],
        {
          opacity: 0,
          visibility: "hidden",
        }
      );
    } else {
      // Restore original position and size of image
      gsap.to(imageRef.current, {
        scale: 1,
        yPercent: 0,
        xPercent: 0,
        top: "-25%",
        left: 0,
        position: "relative",
        transform: "translate(0, 0)",
      });

      // Show other content
      gsap.set(
        [
          headRef.current,
          yearRef.current,
          detailRef.current,
          "#buttons",
          colorRef.current,
        ],
        {
          opacity: 1,
          visibility: "visible",
        }
      );
    }
  };

  const closeScreen = () => {
    const tl = gsap.timeline({
      defaults: { ease: "power1.out" },
      onComplete: () => {
        closeFullScreen();
      },
    });

    tl.to([imageRef.current, headRef.current, yearRef.current, "#buttons"], {
      scale: 0,
      opacity: 0,
      duration: 1,
    })
      .to(
        colorRef.current,
        {
          scaleY: 10,
          duration: 1.5,
          ease: "power2.inOut",
        },
        "-=1"
      )
      .to(
        detailRef.current,
        {
          y: 150,
          opacity: 0,
          duration: 0.7,
        },
        "-=1"
      );
  };

  const animateOut = (direction, onComplete) => {
    const xValue = direction === "left" ? 100 : -100;
    const tl = gsap.timeline({ onComplete });

    tl.to(imageRef.current, { x: xValue, opacity: 0, duration: 0.5 });
    tl.to(headRef.current, { x: xValue, opacity: 0, duration: 0.5 }, "-=0.5");
    tl.to(yearRef.current, { x: xValue, opacity: 0, duration: 0.5 }, "-=0.5");
    tl.to(detailRef.current, { x: xValue, opacity: 0, duration: 0.5 }, "-=0.5");
  };

  const animateIn = (direction) => {
    const xValue = direction === "left" ? -100 : 100;
    const tl = gsap.timeline();

    tl.fromTo(
      imageRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 }
    );
    tl.fromTo(
      headRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
    tl.fromTo(
      yearRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
    tl.fromTo(
      detailRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
  };

  const rightClick = () => {
    animateOut("right", () => {
      if (newImgIndex === 1 && data.length > newIndex + 1) {
        setNewIndex(newIndex + 1);
        setNewImgIndex(0);
      } else {
        if (data.length > newIndex && data[newIndex]["years"].length === 2) {
          setNewImgIndex(1);
        }
      }
      animateIn("right");
    });
  };

  const leftClick = () => {
    animateOut("left", () => {
      if (newImgIndex === 1) {
        setNewImgIndex(0);
      } else {
        if (newIndex !== 0) {
          setNewIndex(newIndex - 1);
          setNewImgIndex(1);
        } else {
          setNewIndex(0);
        }
      }
      animateIn("left");
    });
  };

  const detailParagraphs = data[newIndex]["details"][newImgIndex]
    .split("\n")
    .map((paragraph, index) => (
      <p key={index} className="ifTextColor overpass text-[20px] mb-4">
        {paragraph}
      </p>
    ));

  return (
    <div className="z-1 bg-white fixed top-0 flex flex-col h-full w-full overflow-hidden ">
      <div className="flex flex-col w-full overflow-hidden">
        <div
          className="flex flex-row pt-4 pb-[10px] px-2 items-center justify-center w-full"
          id="buttons"
          style={{ visibility: "hidden" }}
        >
          <div className="absolute left-0 pl-4">
            <p className="text-[16px] overpass">{period}s</p>
          </div>
          <div className="flex flex-row justify-center items-center">
            <button className="pr-[10px]" onClick={leftClick}>
              <img src={Left} alt="left" />
            </button>
            <button onClick={rightClick}>
              <img src={Right} alt="right" />
            </button>
          </div>
          <div className="absolute right-0 pr-4">
            <button onClick={null} className="pr-[10px]">
              <img src={SHARE} alt="share" />
            </button>
            <button onClick={closeScreen}>
              <img src={CLOSE} alt="close" />
            </button>
          </div>
        </div>
        <div
          className="divider"
          id="buttons"
          style={{
            visibility: "hidden",
          }}
        ></div>
        <div className="pt-[30px] relative w-full flex justify-center">
          <div className="w-[210px] h-[250px] relative">
            <img
              ref={imageRef}
              src={data[newIndex]["images"][newImgIndex]}
              alt="FullScreen"
              onClick={toggleImageFullHeight}
              className={`${
                isImageFullHeight
                  ? "object-contain h-screen transition-all duration-500 transform scale-105"
                  : "max-h-[250px] mt-[50px]"
              } absolute z-20 object-contain`}
              style={{
                width: "350px",
                height: "350px",
                top: "25%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                visibility: "hidden",
              }}
            />
            <div
              ref={colorRef}
              className="w-screen h-[150px] absolute z-10 bottom-0 shadow-lg"
              style={{ backgroundColor: bgColor, visibility: "hidden" }}
            ></div>
          </div>
        </div>
        <div className="m-[10px] p-[10px] bg-white overflow-y-auto flex-1 relative rounded-[20px] text-left flex flex-col justify-between shadow-lg">
          <div className="flex justify-center items-center text-center w-full mb-4">
            <div>
              <h2
                ref={headRef}
                className="font-semibold ifTextColor overpass text-[34px]"
                id="head"
                style={{ visibility: "hidden" }}
              >
                {data[newIndex]["names"][newImgIndex]}
              </h2>
              <p
                ref={yearRef}
                id="year"
                className="mb-2 ifTextColor overpass text-[11px]"
                style={{
                  visibility: "hidden",
                  color: "#970000",
                }}
              >
                {data[newIndex]["years"][newImgIndex]}
              </p>
            </div>
          </div>
          {/* <div
            id="audio"
            style={{
              padding: `10px 14px`,
            }}
          >
            <AudioPlayer audioFile={data[newIndex]["audios"][newImgIndex]} />
          </div> */}
          <div
            id="detail"
            ref={detailRef}
            className="overflow-y-auto flex-1"
            style={{ visibility: "hidden" }}
          >
            {detailParagraphs}
          </div>
        </div>
      </div>
    </div>
  );
}
