import { useScreenSize } from "../../utils/responsive";
import { ViewPeriod } from "./desktopView/view.period";

import { MobileViewPeriod } from "./mobileView/view.period.mobile";

export function Periods({
  period,
  prevPeriod,
  bgColor,
  firstColor,
  secondColor,
  prevBgColor,
  periodColor,
  BackgroundImages,
  data,
  handleImageClick,
  handleCloseFullScreen,
  id,
  punchLine,
  statue
}) {
  const screenSize = useScreenSize();

  return (
    <div>
      {screenSize === "sm" || screenSize === "xs" ? (
        <MobileViewPeriod
          period={period}
          prevPeriod={prevPeriod}
          bgColor={bgColor}
          firstColor={firstColor}
          secondColor={secondColor}
          periodColor={periodColor}
          bgImage={BackgroundImages}
          prevbgColor={prevBgColor}
          data={data}
          onImageClick={handleImageClick}
          onCloseFullScreen={handleCloseFullScreen}
          id={`viewPeriod-${id}`}
          punchLine={punchLine}
          statue={statue}
        />
      ) : (
        <ViewPeriod
          period={period}
          prevPeriod={prevPeriod}
          bgColor={bgColor}
          firstColor={firstColor}
          secondColor={secondColor}
          periodColor={periodColor}
          bgImage={BackgroundImages}
          prevbgColor={prevBgColor}
          data={data}
          onImageClick={handleImageClick}
          onCloseFullScreen={handleCloseFullScreen}
          id={`viewPeriod-${id}`}
          punchLine={punchLine}
          statue={statue}
        />
      )}
    </div>
  );
}
