import gsap from "gsap";
import { useEffect, useRef } from "react";

export function DescriptionBG() {
  const waveRefs = useRef([]);
  const addToRefs = (el) => {
    if (el && !waveRefs.current.includes(el)) {
      waveRefs.current.push(el);
    }
  };

  useEffect(() => {
    gsap.to("#navigation", {
      rotation: 180,
      transformOrigin: "50% 50%",
      scrollTrigger: {
        trigger: "#navigation",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
    });
    gsap.to("#bNavigation", {
      rotation: -180,
      transformOrigin: "50% 50%",
      scrollTrigger: {
        trigger: "#navigation",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
    });
    waveRefs.current.forEach((wavePath) => {
      if (wavePath) {
        const pathLength = wavePath.getTotalLength();
        gsap.set(wavePath, {
          strokeDasharray: pathLength,
          strokeDashoffset: pathLength,
        });
        gsap.to(wavePath, {
          strokeDashoffset: 0,
          scrollTrigger: {
            trigger: wavePath,
            start: "top 70%",
            end: "bottom center",
            scrub: true,
          },
        });
      }
    });
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="square"
      strokeMiterlimit="1.5"
      clipRule="evenodd"
      viewBox="0 0 1827 1073"
    >
      <path
        fill="none"
        d="M705.786 5283.78h1976.21v1218.18H705.786z"
        transform="matrix(.92163 0 0 .87562 -648.973 -4623.12)"
      ></path>
      <circle
        cx="3009.35"
        cy="6065.98"
        r="64.508"
        fill="#fff"
        stroke="#55433c"
        strokeWidth="3.57"
        transform="matrix(.7 0 0 .7 -1960.852 -4137.03)"
      ></circle>
      <circle
        cx="3009.35"
        cy="6065.98"
        r="64.508"
        fill="#fff"
        stroke="#55433c"
        strokeWidth="4.47"
        transform="matrix(.56582 0 0 .55165 -1557.063 -3237.48)"
      ></circle>
      <path
        id="bNavigation"
        fill="#fff"
        stroke="#55433c"
        strokeWidth="1.664"
        d="M114.334 78.08l14.838 32.147-14.89 30.766 31.405-14.65 31.29 14.927-15.677-31.043 15.728-32.186-31.34 15.619z"
      ></path>
      <path
        id="bNavigation"
        fill="none"
        stroke="#55433c"
        strokeWidth="1.11"
        d="M114.283 78.041l62.306 63.275"
      ></path>
      <path
        id="bNavigation"
        fill="none"
        stroke="#55433c"
        strokeWidth="0.832"
        d="M176.575 78.041l-62.306 63.275"
      ></path>
      <path
        id="navigation"
        fill="#fff"
        stroke="#55433c"
        strokeWidth="2.503"
        d="M145.39 21.676l-9.58 78.616-79.905 9.915 80.602 8.142 9.18 80.428 8.839-79.557 78.718-9.013-78.647-9.502z"
      ></path>
      <path
        id="navigation"
        fill="none"
        stroke="#55433c"
        strokeWidth="1.664"
        d="M55.905 110.227h177.34M145.69 197.462l-.302-174.636"
      ></path>
      <circle
        cx="3029.81"
        cy="6083.8"
        r="3.387"
        fill="#55433c"
        transform="translate(-1809.186 -3815.16) scale(.64513)"
      ></circle>
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeDasharray="8.75,11.67,0,0"
        strokeWidth="3.893"
        d="M1822.834 242.308s-92.613 24.734-116.2 63.374-5 103.453-23.76 148.04c-17.68 42.052-99.906 80.16-96 119.452 4.667 46.947 79.72 56.427 144.027 30.84 33.733-13.426 91.933-1.306 91.933-1.306M581.184 1068.533s176.64-137.12 275.386-141.906c98.76-4.774 244.546 111.48 348.239 119.48 128.16 9.906 322.279-1.507 438.159-60.014 68.96-34.813 179.866-156.64 179.866-156.64M245.132 3.482c-3.096 36.814 9.218 148.693-45.66 166.867-40.72 13.48-96.62-19.147-143.7 27.88-26.191 26.16-70.57 98.546.33 148.306 97.77 68.6 265.822 76.2 5.355 257.88-108.395 75.599-38.075 255.319 148.578 283.905 59.101 9.053 84.49 81.227 61.999 99.507-17.778 14.453-39.478 24.96-47.76 40.56-6.879 12.946-6.186 25.493-5.79 40.146"
      ></path>
      {/* line */}
      <path
        id="line"
        fill="none"
        stroke="#7a7a7a"
        strokeDasharray="8.75,11.67,0,0"
        strokeWidth="3.893"
        d="M563.024 3.482s-74.987 76.88-178.85 151.32c-75.718 54.28-168.223 97.493-251.136 156.72-73.512 52.506-30.793 145.893-54.993 206.466-21.468 53.733-73.879 77.146-73.879 77.146"
      ></path>
      {/* line */}
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.667"
        d="M75.69 698.086c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 24.627 11.014 34.562 11.294 8.68.24 19.778-13.654 27.925-13.694s19.492 12.814 28.153 12.707 15.103-13 23.815-13.333c8.71-.32 14.212 14.346 21.85 14.333 7.464-.013 16.511-14.48 23.976-14.427 8.151.054 20.618 11.454 29.351 11.24s15.035-16 23.05-12.506c8.705 3.786 20.92 12.68 32.551 10.346"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.387"
        d="M111.23 634.256c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 18.85 11.974 28.784 12.24 8.68.24 18.128-14.106 26.275-14.16 8.147-.04 16.33 17.667 24.992 17.56s21.021-16.066 29.733-16.386 18.883 12.066 26.52 12.053c7.465-.013 16.773-11.173 24.239-11.133 8.15.053 16.234 14.28 24.968 14.08M93.174 755.057c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 18.85 11.974 28.784 12.24 8.68.24 18.128-14.106 26.275-14.16 8.147-.04 18.279 14.507 26.94 14.4s19.073-12.906 27.785-13.226 19.461 11.813 33.521 8.813"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.6"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 14.137 8.98 21.588 9.18 6.51.18 13.596-10.58 19.706-10.62 6.11-.03 13.709 10.88 20.205 10.8s14.305-9.68 20.839-9.92 14.596 8.86 25.141 6.61"
        transform="matrix(1.07775 0 0 1.33333 -639.819 -7088.737)"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.947"
        d="M1293.402 818.937c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 24.627 11.014 34.562 11.294 8.68.24 19.778-13.654 27.925-13.694s19.492 12.814 28.153 12.707 15.103-13 23.815-13.333c8.71-.32 14.212 14.346 21.85 14.333 7.464-.013 16.511-14.48 23.976-14.427 8.151.054 20.618 11.454 29.351 11.24s15.035-16 23.05-12.506c8.705 3.786 20.92 12.68 32.551 10.346"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.38"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 18.47 8.26 25.921 8.47 6.51.18 14.834-10.24 20.944-10.27s14.619 9.61 21.115 9.53 11.327-9.75 17.861-10c6.533-.24 10.659 10.76 16.387 10.75 5.599-.01 12.384-10.86 17.983-10.82 6.113.04 15.463 8.59 22.013 8.43s11.276-12 17.287-9.38c6.529 2.84 15.69 9.51 24.414 7.76"
        transform="matrix(1.11217 0 0 1.29806 384.778 -6904.267)"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.15"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 18.47 8.26 25.921 8.47 6.51.18 14.834-10.24 20.944-10.27s14.619 9.61 21.115 9.53 11.327-9.75 17.861-10c6.533-.24 10.659 10.76 16.387 10.75 5.599-.01 12.384-10.86 17.983-10.82 6.113.04 15.463 8.59 22.013 8.43s11.276-12 17.287-9.38c6.529 2.84 15.69 9.51 24.414 7.76"
        transform="matrix(1.11217 0 0 1.29806 610.542 -6911.367)"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.387"
        d="M1323.479 858.25c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 18.85 11.974 28.784 12.24 8.68.24 18.128-14.106 26.275-14.16 8.147-.04 18.279 14.507 26.94 14.4s19.073-12.906 27.785-13.226 19.461 11.813 33.521 8.813"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.6"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 14.137 8.98 21.588 9.18 6.51.18 13.596-10.58 19.706-10.62 6.11-.03 13.709 10.88 20.205 10.8s14.305-9.68 20.839-9.92 14.596 8.86 25.141 6.61"
        transform="matrix(1.07775 0 0 1.33333 590.486 -6985.543)"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.667"
        d="M1279.315 215.87c15.899.133 23.837-8.227 33.334-12.614 11.042-5.08 18.85 11.974 28.784 12.24 8.68.24 18.128-14.106 26.275-14.16 8.147-.04 18.279 14.507 26.94 14.4s19.073-12.906 27.785-13.226 19.461 11.813 33.521 8.813"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.15"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 14.137 8.98 21.588 9.18 6.51.18 13.596-10.58 19.706-10.62 6.11-.03 13.709 10.88 20.205 10.8s14.305-9.68 20.839-9.92 14.596 8.86 25.141 6.61"
        transform="matrix(1.07775 0 0 1.33333 521.238 -7631.621)"
      ></path>
      <path
        ref={addToRefs}
        fill="none"
        stroke="#55433c"
        strokeWidth="1.37"
        d="M734.806 5921.05c11.924.1 17.878-6.17 25.001-9.46 8.281-3.81 14.137 8.98 21.588 9.18 6.51.18 13.596-10.58 19.706-10.62 6.11-.03 13.709 10.88 20.205 10.8s14.305-9.68 20.839-9.92 14.596 8.86 25.141 6.61"
        transform="matrix(1.07775 0 0 1.33333 522.224 -7720.392)"
      ></path>
    </svg>
  );
}
