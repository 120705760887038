import React, { useEffect } from "react";
import { gsap } from "gsap";

export function ArrowDown() {
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    tl.to(".path1", { opacity: 1, duration: 0.4 })
      .to(".path2", { opacity: 1, duration: 0.4 })
      .to(".path3", { opacity: 1, duration: 0.4 })
      .to(".path1", { opacity: 0, duration: 0.2 })
      .to(".path2", { opacity: 0, duration: 0.2 })
      .to(".path3", { opacity: 0, duration: 0 });
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="27"
      fill="none"
      viewBox="0 0 25 27"
    >
      <path
        className="path1"
        stroke="#F15B25"
        d="M1 1l12 10L24 1"
        style={{ opacity: 0 }}
      ></path>
      <path
        className="path2"
        stroke="#EAEAEA"
        d="M1 8l12 10L24 8"
        style={{ opacity: 0 }}
      ></path>
      <path
        className="path3"
        stroke="#046A38"
        d="M1 16l12 10 11-10"
        style={{ opacity: 0 }}
      ></path>
    </svg>
  );
}
