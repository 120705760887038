import React, { useCallback, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery, useScreenSize } from "../utils/responsive";
import { BharatSaga, SoundOff, SoundOn } from "../utils/icons";
import { AppDrawer } from "./app.drawer";

gsap.registerPlugin(ScrollTrigger);

export function TopBar({
  pauseAudio,
  playAudio,
  isPause,
  scrollToAboutUs,
  scrollToContactUs,
  scrollToIntro,
}) {
  const screenSize = useScreenSize();
  const { responsiveWidth } = useMediaQuery();

  const handleMouseEnter = useCallback((event) => {
    const button = event.currentTarget;
    gsap.to(button, {
      backgroundColor: "#FF671F",
      color: "#fff",
      duration: 0.5,
      ease: "power1.inOut",
    });
  }, []);

  const handleMouseLeave = useCallback((event) => {
    const button = event.currentTarget;
    gsap.to(button, {
      backgroundColor: "transparent",
      color: "#3B5045",
      duration: 0.5,
      ease: "power1.inOut",
    });
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(".tabs");
    buttons.forEach((button) => {
      button.addEventListener("mouseenter", handleMouseEnter);
      button.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        button.removeEventListener("mouseenter", handleMouseEnter);
        button.removeEventListener("mouseleave", handleMouseLeave);
      };
    });

    gsap.fromTo(
      "#periods",
      {
        opacity: 0,
        x: -100,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#periods",
          start: "top center",
          end: "bottom top",
          scrub: 0.5,
        },
      }
    );
    return () => {};
  }, [screenSize, handleMouseEnter, handleMouseLeave]);

  return (
    <div className="sticky-bar" id="periods">
      <div
        id="saga-image"
        className="flex items-between justify-between pt-2"
        style={{
          paddingRight:
            screenSize === "sm" || screenSize === "xs"
              ? responsiveWidth(20)
              : responsiveWidth(20),
          paddingLeft:
            screenSize === "sm" || screenSize === "xs"
              ? responsiveWidth(20)
              : responsiveWidth(40),
          paddingTop: responsiveWidth(20),
        }}
      >
        <img
          id="saga-image "
          src={BharatSaga}
          alt="saga"
          onClick={() => scrollToIntro()}
          className="cursor-pointer"
          style={{
            width:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(100)
                : responsiveWidth(148),
            paddingRight: responsiveWidth(15),
          }}
        />
        <div
          className={`${
            screenSize === "sm" || screenSize === "xs" ? "" : "flex-row"
          } flex items-center overpass font-semibold ifTextColor button-container z-1`}
          style={{
            fontSize:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(11)
                : responsiveWidth(16),
          }}
        >
          {screenSize === "sm" || screenSize === "xs" ? (
            <></>
          ) : (
            ["ABOUT", "CONTACT US"].map((text, index) => (
              <button
                key={text}
                className="tabs"
                onClick={() =>
                  index === 0 ? scrollToAboutUs() : scrollToContactUs()
                }
                style={{
                  marginRight:
                    screenSize === "sm" || screenSize === "xs"
                      ? responsiveWidth(5)
                      : responsiveWidth(index === 2 ? 10 : 25),
                  padding: responsiveWidth(5),
                }}
              >
                {text}
              </button>
            ))
          )}
          <img
            src={isPause ? SoundOff : SoundOn}
            onClick={() => (isPause ? playAudio() : pauseAudio())}
            alt="Music"
            className="cursor-pointer"
            style={{ height: responsiveWidth(24) }}
          />
          {screenSize === "xs" || screenSize === "sm" ? (
            <AppDrawer
              scrollToAboutUs={scrollToAboutUs}
              scrollToContactUs={scrollToContactUs}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
