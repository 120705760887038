import gsap from "gsap";
import { Paragraphs } from "../utils/paragraph";
import { useMediaQuery, useScreenSize } from "../utils/responsive";
import { AboutUsDetail } from "../utils/topBarDetails";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function AboutUs() {
  const screenSize = useScreenSize();
  const { responsiveWidth } = useMediaQuery();
  const aboutUsRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        aboutUsRef.current,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          scrollTrigger: {
            trigger: aboutUsRef.current,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }, aboutUsRef);

    return () => ctx.revert();
  }, [screenSize, responsiveWidth]);

  return (
    <div
      ref={aboutUsRef}
      className="w-screen bg-white flex items-center justify-center"
      style={{
        paddingLeft:
          screenSize === "sm" || screenSize === "xs"
            ? responsiveWidth(16)
            : responsiveWidth(100),
        paddingRight:
          screenSize === "sm" || screenSize === "xs"
            ? responsiveWidth(16)
            : responsiveWidth(100),
      }}
    >
      <div>
        <div
          className="flex justify-center kalnia"
          style={{
            fontSize:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(27)
                : responsiveWidth(49),
            paddingBottom:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(16)
                : responsiveWidth(50),
          }}
        >
          About <p className="text-[#F15B25] ml-2"> BharatSaga</p>
        </div>
        <div
          className="overflow-y-auto hide-scrollbar"
          style={{
            fontSize:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(16)
                : responsiveWidth(24),
          }}
        >
          <Paragraphs details={AboutUsDetail} />
        </div>
      </div>
    </div>
  );
}
