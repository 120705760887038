import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Main } from "./components/main";
import { MediaQueryProvider } from "./utils/responsive";
import backgroundMusic from "./assets/audio/indian-temple-187922.mp3";

function App() {
  const audioRef = useRef(null);
  const [isPause, setIsPause] = useState(true);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.volume = 0.05;
    }
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (audioElement && audioElement.paused && !isPause) {
          audioElement.play().catch((error) => {
            console.error("Auto-play failed:", error);
          });
        }
      } else {
        if (audioElement && !audioElement.paused) {
          audioElement.pause();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPause]);

  const pause = () => {
    if (audioRef.current) {
      setIsPause(true);
      audioRef.current.pause();
    }
  };

  const play = () => {
    if (audioRef.current) {
      setIsPause(false);
      audioRef.current.play();
    }
  };

  return (
    <MediaQueryProvider>
      <div>
        <audio ref={audioRef} loop>
          <source src={backgroundMusic} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <Main pauseAudio={pause} playAudio={play} isPause={isPause} />
      </div>
    </MediaQueryProvider>
  );
}

export default App;
