import React from "react";

export const Paragraphs = ({ details }) => {
  return details.split("\n").map((paragraph, index) => {
    // First, handle "BharatSaga"
    let parts = paragraph.split(
      /(BharatSaga|BiCSoM Technologies Private Limited|bharatiya@bharatsaga.info)/
    );

    return (
      <p key={index} className="text-left ifTextColor overpass mb-4">
        {parts.map((part, i) => {
          if (part === "BharatSaga") {
            return (
              <span key={i} className="text-[#F15B25]">
                BharatSaga
              </span>
            );
          } else if (part === "BiCSoM Technologies Private Limited") {
            return (
              <span key={i} className="underline">
                <a
                  key={i}
                  href="https://bicsom.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  BiCSoM Technologies Private Limited
                </a>
              </span>
            );
          } else if (part === "bharatiya@bharatsaga.info") {
            return (
              <span key={i} className="underline">
                <a
                  key={i}
                  href="mailto:bharatiya@bharatsaga.info"
                  className="underline"
                >
                  bharatiya@bharatsaga.info
                </a>
              </span>
            );
          } else {
            return <React.Fragment key={i}>{part}</React.Fragment>;
          }
        })}
      </p>
    );
  });
};
