import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { useMediaQuery } from "../../../utils/responsive";
import { CLOSE, Left, Right, SHARE } from "../../../utils/icons";

export function ViewDetails({
  data,
  index,
  imgIndex,
  bgColor,
  period,
  closeFullScreen,
}) {
  const imageRef = useRef();
  const colorRef = useRef();
  const detailRef = useRef();
  const headRef = useRef();
  const yearRef = useRef();
  const [newIndex, setNewIndex] = useState(index);
  const [newImgIndex, setNewImgIndex] = useState(imgIndex);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

    tl.set(
      [
        imageRef.current,
        headRef.current,
        yearRef.current,
        detailRef.current,
        "#buttons",
        colorRef.current,
      ],
      { visibility: "visible" }
    );

    tl.fromTo(
      imageRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 1 }
    );
    tl.fromTo(
      headRef.current,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5 }
    );

    tl.fromTo(
      [yearRef.current, "#audio"],
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.7 },
      "-=0.3"
    );

    tl.fromTo(
      detailRef.current,
      { y: 150, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.7 },
      "-=0.3"
    );

    tl.fromTo("#buttons", { opacity: 0 }, { opacity: 1 }, "-=0.3");

    gsap.fromTo(
      colorRef.current,
      { x: -100, scaleX: 10, opacity: 0.5 },
      { x: 0, opacity: 1, scaleX: 1, duration: 1.5, ease: "power2.inOut" }
    );
  }, []);

  const closeScreen = () => {
    const tl = gsap.timeline({
      defaults: { ease: "power1.out" },
      onComplete: () => {
        closeFullScreen();
      },
    });

    tl.to(
      [
        imageRef.current,
        headRef.current,
        yearRef.current,
        "#audio",
        "#buttons",
      ],
      {
        opacity: 0,
        duration: 1,
      }
    )
      .to(
        colorRef.current,
        {
          x: -100,
          scaleX: 10,
          opacity: 0.5,
          duration: 1.5,
          ease: "power2.inOut",
        },
        "-=1"
      )
      .to(
        detailRef.current,
        {
          y: 150,
          opacity: 0,
          duration: 0.7,
        },
        "-=1"
      );
  };

  const animateOut = (direction, onComplete) => {
    const xValue = direction === "left" ? 100 : -100;
    const tl = gsap.timeline({ onComplete });

    tl.to(imageRef.current, { x: xValue, opacity: 0, duration: 0.5 });
    tl.to(headRef.current, { x: xValue, opacity: 0, duration: 0.5 }, "-=0.5");
    tl.to(
      [yearRef.current, "#audio"],
      { x: xValue, opacity: 0, duration: 0.5 },
      "-=0.5"
    );
    tl.to(detailRef.current, { x: xValue, opacity: 0, duration: 0.5 }, "-=0.5");
  };

  const animateIn = (direction) => {
    const xValue = direction === "left" ? -100 : 100;
    const tl = gsap.timeline();

    tl.fromTo(
      imageRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 }
    );
    tl.fromTo(
      headRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
    tl.fromTo(
      [yearRef.current, "#audio"],
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
    tl.fromTo(
      detailRef.current,
      { x: xValue, opacity: 0 },
      { x: 0, opacity: 1, duration: 0.5 },
      "-=0.5"
    );
  };

  const rightClick = () => {
    animateOut("right", () => {
      if (newImgIndex === 1 && data.length > newIndex + 1) {
        setNewIndex(newIndex + 1);
        setNewImgIndex(0);
      } else {
        if (data.length > newIndex && data[newIndex]["years"].length === 2) {
          setNewImgIndex(1);
        }
      }
      animateIn("right");
    });
  };

  const leftClick = () => {
    animateOut("left", () => {
      if (newImgIndex === 1) {
        setNewImgIndex(0);
      } else {
        if (newIndex !== 0) {
          setNewIndex(newIndex - 1);
          setNewImgIndex(1);
        } else {
          setNewIndex(0);
        }
      }
      animateIn("left");
    });
  };

  const { responsiveWidth, responsiveHeight } = useMediaQuery();
  const detailParagraphs = data[newIndex]["details"][newImgIndex]
    .split("\n")
    .map((paragraph, index) => (
      <p key={index} className="ifTextColor overpass text-[20px] mb-4">
        {paragraph}
      </p>
    ));

  return (
    <div className="z-10000 bg-white fixed top-0 left-0 flex flex-col h-full w-full overflow-y-hidden">
      <div
        tabIndex="-1"
        className="flex justify-center w-full h-full overflow-y-hidden overflow-x-hidden"
      >
        <div className="relative w-1/3 h-full">
          <div
            ref={colorRef}
            className="w-[245px] h-full p-[10px] absolute z-10 right-0 shadow-md"
            style={{
              backgroundColor: bgColor,
              backgroundSize: "contain",
              visibility: "hidden",
            }}
          ></div>
          <div className="w-[250px] h-[250px]">
            <img
              ref={imageRef}
              src={data[newIndex]["images"][newImgIndex]}
              alt="FullScreen"
              className="object-contain absolute z-20"
              style={{
                width: "350px",
                height: "350px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                visibility: "hidden",
              }}
            />
          </div>
        </div>
        <div className="w-2/3 h-full relative p-8 bg-white rounded-[20px] text-left flex flex-col justify-between">
          <div
            className="flex flex-row items-center justify-between w-full"
            id="buttons"
            style={{ visibility: "hidden" }}
          >
            <p className="text-[16px] overpass w-[100px]">{period}s</p>
            <div>
              <button
                onClick={leftClick}
                style={{ paddingRight: responsiveWidth(20) }}
              >
                <img src={Left} alt="left" />
              </button>
              <button onClick={rightClick}>
                <img src={Right} alt="right" />
              </button>
            </div>
            <div>
              <button
                onClick={null}
                style={{ paddingRight: responsiveWidth(20) }}
              >
                <img src={SHARE} alt="share" />
              </button>
              <button onClick={closeScreen}>
                <img src={CLOSE} alt="close" />
              </button>
            </div>
          </div>
          <div
            className="divider"
            id="buttons"
            style={{
              marginBottom: responsiveHeight(20),
              marginTop: responsiveHeight(20),
              visibility: "hidden",
            }}
          ></div>
          <div className="flex justify-center items-center text-center w-full">
            <div>
              <h2
                ref={headRef}
                className="font-semibold ifTextColor overpass"
                id="head"
                style={{ fontSize: responsiveWidth(49), visibility: "hidden" }}
              >
                {data[newIndex]["names"][newImgIndex]}
              </h2>
              <p
                ref={yearRef}
                id="year"
                className="mb-2 overpass cherry"
                style={{
                  fontSize: responsiveWidth(16),
                  visibility: "hidden",
                }}
              >
                {data[newIndex]["years"][newImgIndex]}
              </p>
            </div>
          </div>

          <div
            id="detail"
            ref={detailRef}
            className="overflow-y-auto flex-1"
            style={{ visibility: "hidden" }}
          >
            {detailParagraphs}
          </div>
        </div>
      </div>
    </div>
  );
}
