import gsap, { Power2, Circ, Back, Power1 } from "gsap";
import { useLayoutEffect, useRef, useState } from "react";
import { ViewDetails } from "./view.details";
import { useMediaQuery, useScreenSize } from "../../../utils/responsive";
import { CircularView } from "../circularView";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function ViewPeriod({
  data,
  bgColor,
  prevbgColor,
  firstColor,
  secondColor,
  periodColor,
  period,
  prevPeriod,
  onImageClick,
  onCloseFullScreen,
  id,
  punchLine,
  statue,
}) {
  const wrapperRef = useRef();
  const horizontalScrollRef = useRef();
  const revealsRef = useRef([]);

  const [fullScreenImage, setFullScreenImage] = useState(null);
  const { responsiveWidth, responsiveHeight } = useMediaQuery();

  const addToRefs = (el) => {
    if (el && !revealsRef.current.includes(el)) {
      revealsRef.current.push(el);
    }
  };

  const handleClick = (imageIndex, index, year) => {
    const imageId = `image${year.replace(/\s+/g, "").replace(/,/g, "")}`;
    const imageYearId = `year${year.replace(/\s+/g, "").replace(/,/g, "")}`;

    const allImageIds = data.flatMap(({ years }) =>
      years.map((y) => `image${y.replace(/\s+/g, "").replace(/,/g, "")}`)
    );
    const allYearIds = data.flatMap(({ years }) =>
      years.map((y) => `year${y.replace(/\s+/g, "").replace(/,/g, "")}`)
    );
    allImageIds.forEach((id, index) => {
      if (id !== imageId) {
        gsap.to(
          [`#${id}`, `#${allYearIds[index]}`, `#${imageYearId}`, "#viewPeriod"],
          {
            opacity: 0,
            y: -50,
            duration: 0.3,
            ease: Power2.easeInOut,
          }
        );
      }
    });

    const image = document.getElementById(imageId);

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    const imageRect = image.getBoundingClientRect();
    const imageWidth = imageRect.width;
    const imageHeight = imageRect.height;
    const destinationX = -imageRect.left + imageWidth / 4;
    const destinationY = -0.15 * (vh - imageHeight); // Center vertically

    const tl = gsap.timeline({
      onComplete: () =>
        setFullScreenImage({
          data,
          imageIndex,
          index,
          bgColor,
        }),
    });

    tl.to(image, {
      x: destinationX,
      y: destinationY,
      scale: 1,
      opacity: 0,
      ease: Power2.easeInOut,
      duration: 1,
    });
    gsap.to(`#statue${id}`, {
      opacity: 0,
      duration: 1,
    });

    document.body.style.overflow = "hidden";
    onImageClick();
  };

  const closeDialog = (year) => {
    document.body.style.overflow = "";
    setFullScreenImage(null);

    const imageId = `image${year.replace(/\s+/g, "").replace(/,/g, "")}`;
    const imageYearId = `year${year.replace(/\s+/g, "").replace(/,/g, "")}`;

    const allImageIds = data.flatMap(({ years }) =>
      years.map((y) => `image${y.replace(/\s+/g, "").replace(/,/g, "")}`)
    );
    const allYearIds = data.flatMap(({ years }) =>
      years.map((y) => `year${y.replace(/\s+/g, "").replace(/,/g, "")}`)
    );

    allImageIds.forEach((id, index) => {
      if (id !== imageId) {
        gsap.to([`#${id}`, `#${allYearIds[index]}`, "#viewPeriod"], {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: Power2.easeInOut,
        });
      }
    });
    gsap.to([`#${imageId}`, `#${imageYearId}`], {
      x: 0,
      y: 0,
      scale: 1,
      opacity: 1,
      ease: Power2.easeInOut,
      duration: 1,
    });
    gsap.to(`#statue${id}`, {
      opacity: 1,
      duration: 2,
    });
    onCloseFullScreen();
  };

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const totalScrollWidth =
        horizontalScrollRef.current.scrollWidth - window.innerWidth;
      const horizontalTween = gsap
        .timeline({
          scrollTrigger: {
            trigger: wrapperRef.current,
            pin: true,
            scrub: 0.7,
            start: "top top",
            anticipatePin: 1,
            invalidateOnRefresh: true,
            fastScrollEnd: true,
            end: () => `+=${totalScrollWidth}`,
          },
        })
        .to(horizontalScrollRef.current, {
          x: -totalScrollWidth,
          ease: "none",
        });
      revealsRef.current.forEach((el, index) => {
        switch (index) {
          case 0:
          case 8:
          case 16:
            gsap.fromTo(
              el,
              { scale: 0.5, opacity: 0 },
              {
                opacity: 1,
                scale: 1,
                ease: Power1.easeIn,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 1:
          case 9:
          case 17:
            gsap.fromTo(
              el,
              { scale: 2, opacity: 0 },
              {
                scale: 1,
                opacity: 1,
                ease: Power1.easeOut,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 2:
          case 10:
          case 18:
            gsap.fromTo(
              el,
              { y: 300, opacity: 0 },
              {
                y: 0,
                opacity: 1,
                ease: Power1.easeInOut,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 3:
          case 11:
          case 19:
            gsap.fromTo(
              el,
              { y: -300, opacity: 0 },
              {
                y: 0,
                opacity: 1,
                ease: Back.in,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 4:
          case 12:
          case 20:
            gsap.fromTo(
              el,
              { x: 100, skewX: 20, opacity: 0 },
              {
                x: 0,
                skewX: 0,
                opacity: 1,
                ease: Back.out,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 5:
          case 13:
          case 21:
            gsap.fromTo(
              el,
              { opacity: 0 },
              {
                opacity: 1,
                ease: Circ.in,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          case 6:
          case 14:
          case 22:
            gsap.fromTo(
              el,
              { scaleX: 0, opacity: 0 },
              {
                scaleX: 1,
                opacity: 1,
                ease: Power2.easeInOut,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
            break;
          default:
            gsap.fromTo(
              el,
              { y: -400, opacity: 0 },
              {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                  trigger: el,
                  containerAnimation: horizontalTween,
                  start: "start 90%",
                  end: "50% 80%",
                  scrub: true,
                },
              }
            );
        }
      });
      gsap.fromTo(
        `#statue${id}`,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: `#statue${id}`,
            start: "top bottom",
            end: "bottom center",
            scrub: true,
          },
        }
      );
      gsap.to("#viewPeriod", {
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top top",
          end: () => `+=${totalScrollWidth}`,
          scrub: 0.7,
        },
        x: totalScrollWidth + window.innerWidth,
        ease: "none",
      });
    }, wrapperRef);
    return () => ctx.revert();
  }, []);

  return (
    <div>
      <CircularView
        id={`circular-${id}`}
        prevColor={prevbgColor}
        firstColor={firstColor}
        secondColor={secondColor}
        color={bgColor}
        fYear={prevPeriod}
        sYear={period}
        punchLine={punchLine}
      />
      <div
        ref={wrapperRef}
        className="w-full h-screen pt-[40px] overflow-hidden"
        style={{
          backgroundColor: bgColor,
        }}
      >
        <div
          id={`statue${id}`}
          className="flex items-end"
          style={{
            position: "fixed",
            left: "1%",
            bottom: "1%",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              width: responsiveWidth(statue["imageWidth"]),
            }}
          >
            <img
              src={statue["image"]}
              alt={statue["image"]}
              style={{ opacity: statue["opacity"] }}
            />
          </div>
          <div
            className="text-container pb-[40px] overpass text-left"
            style={{ color: statue["fontColor"] }}
          >
            <div className="text-[12px]">{statue["year"]}</div>
            <div className="text-2xl kaushnaScript">{statue["name"]}</div>
            <div className="text-base">{statue["place"]}</div>
          </div>
        </div>
        <div ref={horizontalScrollRef} className="flex h-full">
          <div
            className="flex-shrink-0 flex items-start justify-end h-full pt-16"
            id="viewPeriod"
          >
            <div
              className="border border-gray-400 mr-1"
              style={{ height: "100%" }}
            ></div>
            <div
              className="ifTextColor text-[13px] overpass pl-[5px] font-bold"
              style={{ color: periodColor }}
            >
              {period}
            </div>
          </div>
          <div className="flex">
            <section
              key={10}
              className="flex-shrink-0 flex justify-center items-start"
              style={{ flexBasis: "70vw" }}
            ></section>
            {data.map(({ images, names, years, details }, index) => (
              <section
                key={index}
                className="flex-shrink-0 flex justify-center items-start"
                style={{ flexBasis: "100vw" }}
              >
                <div className="items-start flex flex-row h-screen w-full justify-around font-semibold">
                  {images.map((imgSrc, imgIndex) => (
                    <div key={imgIndex} ref={addToRefs}>
                      <ImageItem
                        year={years[imgIndex]}
                        imgSrc={imgSrc}
                        name={names[imgIndex]}
                        details={details[imgIndex]}
                        onClick={() =>
                          handleClick(imgIndex, index, years[imgIndex])
                        }
                      />
                    </div>
                  ))}
                  {images.length === 1 && <div></div>}
                </div>
              </section>
            ))}
            <section
              key={12}
              className="flex-shrink-0 flex justify-center items-start"
              style={{ flexBasis: "70vw" }}
            ></section>
          </div>
        </div>
      </div>
      {fullScreenImage && (
        <div className="show">
          <ViewDetails
            data={fullScreenImage.data}
            index={fullScreenImage.index}
            imgIndex={fullScreenImage.imageIndex}
            bgColor={fullScreenImage.bgColor}
            period={period}
            closeFullScreen={() =>
              closeDialog(
                data[fullScreenImage.index]["years"][fullScreenImage.imageIndex]
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export const ImageItem = ({ year, imgSrc, name, onClick }) => {
  const screenSize = useScreenSize();
  const { responsiveWidth, responsiveHeight } = useMediaQuery();
  return (
    <div
      className={`${
        screenSize === "sm" || screenSize === "xs" ? "pl-16" : ""
      } relative flex flex-col items-start`}
      onClick={onClick}
      style={{
        paddingTop: responsiveHeight(80),
      }}
    >
      <div id={`year${year.replace(/\s+/g, "").replace(/,/g, "")}`}>
        <div
          className=" ifTextColor text-left font-normal overpass text-base"
          style={{
            fontSize:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(11)
                : responsiveWidth(16),
          }}
        >
          {year}
        </div>
        <div
          className="ifTextColor text-left flex overpass truncate-2-lines"
          style={{
            lineHeight: "1.1",
            maxWidth: responsiveWidth(280),
            fontSize:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveWidth(22)
                : responsiveWidth(31),
          }}
        >
          {name}
        </div>
      </div>
      <div id={`imageContainer${year.replace(/\s+/g, "").replace(/,/g, "")}`}>
        <img
          src={imgSrc}
          alt={`${name}`}
          id={`image${year.replace(/\s+/g, "").replace(/,/g, "")}`}
          className="bg-white"
          style={{
            cursor: "pointer",
            display: "block",
            width: "100%",
            height: "auto",
            maxHeight:
              screenSize === "sm" || screenSize === "xs"
                ? responsiveHeight(250)
                : responsiveWidth(350),
          }}
        />
      </div>
    </div>
  );
};
