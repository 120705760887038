import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { useMediaQuery, useScreenSize } from "../utils/responsive";
import { images } from "../utils/images";
import { DescriptionBG } from "../utils/svgImages/descriptionBg";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function Description({ scrollRef, jumpTo1600 }) {
  const screenSize = useScreenSize();
  const elementRefs = useRef([]);

  useEffect(() => {
    const elements = elementRefs.current;
    elements.forEach((el, index) => {
      const direction =
        index % 2 === 0 ? -window.innerWidth : window.innerWidth;
      gsap.fromTo(
        el,
        {
          opacity: 0,
          x: direction,
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            end: "bottom 60%",
            scrub: 0.5,
          },
        }
      );
    });
    gsap.fromTo(
      "#scrolldown",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        rotate: 360,
        scrollTrigger: {
          trigger: "#scrolldown",
          start: "top 90%",
          end: "bottom 75%",
          scrub: 0.5,
        },
      }
    );
    gsap.fromTo(
      "#click",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: "#click",
          start: "top 90%",
          end: "bottom 75%",
          scrub: 0.5,
        },
      }
    );
  }, []);

  const { responsiveWidth } = useMediaQuery();
  const fontStyle = {
    fontSize:
      screenSize === "xs" || screenSize === "sm" ? "34px" : responsiveWidth(49),
  };

  return (
    <div className="relative mb-[27px]" style={fontStyle}>
      <div className="absolute inset-0 z-0">
        {screenSize === "sm" || screenSize === "xs" ? (
          <></>
        ) : (
          <DescriptionBG className="w-full h-full" />
        )}
      </div>
      <div className="relative z-10 flex flex-col items-center">
        {[
          "Saluting the unsung",
          "Heroes & Events",
          "of India’s aka Bharat’s",
          "Independence & Growth",
          "from 1600 to 2024...",
        ].map((text, index) => (
          <div
            key={index}
            ref={(el) => (elementRefs.current[index] = el)}
            className={`${index === 0 ? "mt-[50px] ifTextColor" : ""} ${
              index === 1 ? "mt-[17px] safron" : ""
            } ${
              index === 2
                ? "mt-[30px] safron overpass italic"
                : index === 4
                ? "mt-[30px]  overpass italic"
                : ""
            } ${index === 3 ? "mt-[15px] " : ""}`}
            id={`element-${index}`}
          >
            {text}
          </div>
        ))}
        <img
          alt="scrollDown"
          id="scrolldown"
          src={images.SCROLLDOWN}
          className="relative cursor-pointer"
          ref={scrollRef}
          style={{
            width: responsiveWidth(130),
          }}
          onClick={() => jumpTo1600("1600")}
        />
        <div
          className="overpass text-[#F15B25] relative "
          id="click"
          style={{
            fontSize: responsiveWidth(16),
            paddingTop: responsiveWidth(20),
          }}
        >
          CLICK THE PEACOCK TO START...
        </div>
      </div>
    </div>
  );
}
