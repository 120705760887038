import { BLACK, GREEN, SAFFRON } from "../../utils/colors";
import React, { useEffect, useState } from "react";
import { images } from "../../utils/images";
import { gsap, TextPlugin } from "gsap/all";
import { useMediaQuery, useScreenSize } from "../../utils/responsive";
import { ArrowDown } from "../../utils/svgImages/arrow.down";

gsap.registerPlugin(TextPlugin);

export function Intro({ onClick }) {
  const [mounted, setMounted] = useState(false);
  const screenSize = useScreenSize();

  useEffect(() => {
    const animateElements = () => {
      gsap.to("#flower1", {
        rotation: 10,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        duration: 3,
      });
      gsap.to("#flower2", {
        rotation: 5,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        duration: 2,
      });
      gsap.to("#ashok", {
        rotation: 360,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        duration: 3,
        transformOrigin: "center center",
      });

      const tl = gsap.timeline();
      tl.add([
        gsap.from("#bottom", {
          scale: 0,
          scaleY: 6,
          transformOrigin: "top",
          ease: "SlowMo.ease",
          duration: 1,
        }),
        gsap.fromTo(
          "#leaves1",
          { x: "-100%", transformOrigin: "bottom", ease: "SlowMo.ease" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
        gsap.fromTo(
          "#leaves2",
          { x: "100%", transformOrigin: "bottom", ease: "Power3.easeIn" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
        gsap.fromTo(
          "#grass1",
          { x: "-130%", transformOrigin: "bottom", ease: "Power3.easeIn" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
        gsap.fromTo(
          "#grass2",
          { x: "130%", transformOrigin: "bottom", ease: "Power3.easeIn" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
        gsap.from("#flower1", {
          scaleY: 0,
          transformOrigin: "bottom",
          ease: "Power3.easeIn",
          duration: 2,
        }),
        gsap.from("#flower2", {
          scale: 0,
          transformOrigin: "bottom",
          ease: "Power1.easeIn",
          duration: 2,
        }),
        gsap.fromTo(
          "#minar1",
          { x: "-100%", transformOrigin: "bottom", ease: "expo.in" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
        gsap.fromTo(
          "#minar2",
          { x: "100%", transformOrigin: "bottom", ease: "expo.in" },
          {
            x: "0%",
            transformOrigin: "bottom",
            ease: "SlowMo.ease",
            duration: 2,
          }
        ),
      ])
        .fromTo(
          "#peacock",
          {
            opacity: 0,
            y: screenSize === "sm" || screenSize === "xs" ? "0" : "100%",
            x: screenSize === "sm" || screenSize === "xs" ? "-200%" : "0",
          },
          {
            duration: 1,
            opacity: 1,
            y: 0,
            x: screenSize === "sm" || screenSize === "xs" ? "-100%" : 0,
            ease: "power2.in",
          }
        )
        .from("#bharat", 0.8, {
          scale: 0,
          transformOrigin: "center",
          ease: "Power1.easeIn",
        })
        .from("#period", 0.8, {
          scale: 0,
          transformOrigin: "center",
          ease: "Power1.easeIn",
        })
        .from(["#ashok", "#description"], { opacity: 0, ease: "power1.inOut" })
        .to("#text", {
          text: {
            value: "A story of India’s independence and growth...",
          },
          duration: 4,
          delay: 1,
          ease: "none",
        })
        .fromTo(
          "#arrowDown",
          {
            opacity: 0,
            y: "-100%",
          },
          {
            duration: 1,
            opacity: 1,
            y: 0,
            ease: "power2.in",
          }
        )
        .staggerFrom("#intro > g > #screen > path", 0.1, { scaleX: 0 }, -0.05);

      gsap.fromTo(
        "#cursor",
        { opacity: 1 },
        {
          opacity: 0,
          repeat: -1,
          yoyo: true,
          ease: "power1.inOut",
          duration: 0.5,
        }
      );
    };

    if (mounted) {
      animateElements();
    }

    return () => {
      gsap.killTweensOf("#flower1");
      gsap.killTweensOf("#flower2");
      gsap.killTweensOf("#ashok");
      gsap.killTweensOf("#bottom");
      gsap.killTweensOf("#leaves1");
      gsap.killTweensOf("#leaves2");
      gsap.killTweensOf("#grass1");
      gsap.killTweensOf("#grass2");
      gsap.killTweensOf("#minar1");
      gsap.killTweensOf("#minar2");
      gsap.killTweensOf("#peacock");
      gsap.killTweensOf("#bharat");
      gsap.killTweensOf("#period");
      gsap.killTweensOf("#ashok");
      gsap.killTweensOf("#description");
      gsap.killTweensOf("#text");
      gsap.killTweensOf("#cursor");
    };
  }, [mounted, screenSize]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { responsiveWidth, responsiveHeight } = useMediaQuery();

  return screenSize === "sm" || screenSize === "xs" ? (
    <div className="flex flex-col justify-between h-screen">
      <div className="flex flex-col justify-start items-center text-[53px] p-4">
        <div className="font-medium " id="bharat" style={{ color: SAFFRON }}>
          Bharat
        </div>
        <img
          src={images.ASHOK_CHACKRA}
          alt="ashok"
          id="ashok"
          className="w-[32px] h-[32px]"
        />
        <div className="font-medium" id="period" style={{ color: GREEN }}>
          1600-2024
        </div>
        <div className="flex text-[17px]" id="description">
          <div
            className="font-medium cormorant "
            id="text"
            style={{ color: BLACK }}
          ></div>
          <div id="cursor" className="black">
            |
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <div
            alt="Arrow down"
            id="arrowDown"
            className="pt-2 cursor-pointer"
            onClick={() => onClick()}
          >
            <ArrowDown />
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-end justify-end w-full overflow-x-clip relative">
          <div className="relative flex items-end  w-full">
            <img
              id="flower1"
              src={images.FLOWER_RIGHT1}
              alt="flower"
              className="absolute "
            />
            <img
              id="flower2"
              src={images.FLOWER_RIGHT2}
              alt="flower"
              className="absolute left-[80px] "
            />
            <img
              id="grass1"
              src={images.GRASS}
              alt="grass"
              className="absolute bottom-[-40px]"
            />
            <img
              id="leaves2"
              src={images.LEAVES}
              alt="Leaves"
              className="relative ml-auto"
              style={{
                left: responsiveWidth(80),
                height: responsiveHeight(350),
              }}
            />
            <img
              id="minar1"
              src={images.MINAR}
              alt="Minar"
              className="absolute px-8"
              style={{
                left: "0",
                right: "0",
                margin: "auto",
                height: responsiveHeight(550),
              }}
            />
            <img
              src={images.PEACOCK2}
              alt="Peacock"
              id="peacock"
              className="absolute"
              style={{
                height: responsiveHeight(200),
                top: -responsiveHeight(190),
                left: "45%",
                transform: "translateX(-120%)",
              }}
            />
          </div>
        </div>
        <img
          src={images.BOTTOM_RECTANGLE}
          alt="bottom"
          className="relative left-0 right-0"
          id="bottom"
        ></img>
      </div>
    </div>
  ) : (
    <div className="flex flex-col justify-end h-screen ">
      <div className="flex flex-row justify-between overflow-visible">
        <div className="flex items-end overflow-hidden overflow-visible">
          <div
            style={{ height: responsiveHeight(500) }}
            className="relative flex items-end"
          >
            <img
              id="flower1"
              src={images.FLOWER_RIGHT1}
              alt="flower"
              className="absolute left-[90px]"
              style={{
                height: responsiveWidth(200),
              }}
            />
            <img
              id="grass1"
              src={images.GRASS}
              alt="grass"
              className="absolute bottom-[-40px] left-[50px]"
            />
            <img
              id="flower2"
              src={images.FLOWER_RIGHT2}
              alt="flower"
              className="absolute right-[-30px] "
              style={{
                height: responsiveWidth(150),
              }}
            />
            <img
              id="leaves1"
              src={images.LEAVES}
              alt="Leaves"
              className="relative ml-auto flip"
              style={{
                right: responsiveWidth(124),
                height: responsiveWidth(350),
              }}
            />
            <img
              id="minar1"
              src={images.MINAR}
              alt="Minar"
              className="absolute"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center justify-between content-between">
          <div>
            <div
              className=" flex flex-col justify-center items-center"
              style={{ fontSize: responsiveWidth(76) }}
            >
              <div
                className="font-medium"
                id="bharat"
                style={{ color: SAFFRON }}
              >
                Bharat
              </div>
              <img
                src={images.ASHOK_CHACKRA}
                alt="ashok"
                id="ashok"
                style={{
                  width: responsiveWidth(56),
                  height: responsiveWidth(56),
                }}
              />
              <div className="font-medium" id="period" style={{ color: GREEN }}>
                1600-2024
              </div>
            </div>
            <div className="flex flex-row items-start" id="description">
              <div
                className="font-medium cormorant text-left"
                id="text"
                style={{ color: BLACK, fontSize: responsiveWidth(28) }}
              ></div>
              <div id="cursor" style={{ fontSize: responsiveWidth(28) }}>
                |
              </div>
            </div>
            <div className="flex flex-row justify-center">
              <div
                alt="Arrow down"
                id="arrowDown"
                className="pt-2 cursor-pointer"
                onClick={() => onClick()}
              >
                <ArrowDown />
              </div>
            </div>
          </div>
          <img
            src={images.PEACOCK}
            alt="Peacock"
            id="peacock"
            className="pt-10"
            style={{ height: 250 }}
          ></img>
        </div>
        <div className="relative flex items-end overflow-hidden overflow-visible">
          <div
            style={{ height: responsiveHeight(500) }}
            className="relative flex items-end overflow-hidden overflow-visible"
          >
            <img
              src={images.FLOWER_RIGHT1}
              alt="flower"
              id="flower1"
              className="absolute left-[-20px]"
              style={{
                height: responsiveWidth(160),
              }}
            />
            <img
              id="grass2"
              src={images.GRASS}
              alt="grass"
              className="absolute bottom-[-40px] left-[-50px]"
            />
            <img
              src={images.FLOWER_RIGHT2}
              alt="flower"
              id="flower2"
              className="absolute right-[50px] "
              style={{
                height: responsiveWidth(200),
              }}
            />
            <img
              src={images.LEAVES}
              alt="Leaves"
              id="leaves2"
              className="relative ml-auto"
              style={{
                left: responsiveWidth(124),
                height: responsiveWidth(350),
              }}
            />
            <img
              src={images.MINAR}
              id="minar2"
              alt="Minar"
              className="absolute bottom-0 "
            />
          </div>
        </div>
      </div>
      <img
        src={images.BOTTOM_RECTANGLE}
        alt="bottom"
        className="relative bottom-2 left-0 right-0"
        id="bottom"
      ></img>
    </div>
  );
}
