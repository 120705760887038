import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { useMediaQuery } from "../utils/responsive";

export const AppDrawer = ({ scrollToAboutUs, scrollToContactUs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { responsiveWidth } = useMediaQuery();
  const toggleOpenDrawer = () => {
    setIsOpen(true);
  };

  const toggleCloseDrawer = () => {
    setIsOpen(false);
  };

  const goToAbout = () => {
    scrollToAboutUs();
    toggleCloseDrawer();
  };

  const goTocontact = () => {
    scrollToContactUs();
    toggleCloseDrawer();
  };

  return (
    <div>
      <button className="menu-btn" onClick={toggleOpenDrawer}>
        <div className="ml-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={responsiveWidth(30)}
            viewBox="0 0 20 20"
            fill="none"
          >
            <path d="M3 17.7998H21" stroke="#046A38" strokeWidth="2" />
            <path d="M3 12.3999H21" stroke="white" strokeWidth="2" />
            <path d="M3 7H21" stroke="#F15B25" strokeWidth="2" />
          </svg>
        </div>
      </button>
      <Drawer
        open={isOpen}
        onClose={toggleCloseDrawer}
        anchor="right"
        PaperProps={{
          style: {
            width: responsiveWidth(300),
            padding: responsiveWidth(20),
            backgroundColor: "#F15B25",
          },
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col">
            <button
              className="close-btn text-right text-[#fff] pb-[25px]"
              style={{
                fontSize: responsiveWidth(34),
              }}
              onClick={toggleCloseDrawer}
            >
              &times;
            </button>
            <div className="text-[#fff] overpass text-left">
              <div onClick={goToAbout} className="drawer-item pb-[24px]">
                ABOUT
              </div>
              <div onClick={goTocontact} className="drawer-item">
                CONTACT US
              </div>
            </div>
          </div>
          <span className="text-[#fff] flex flex-wrap overpass items-center text-[9px] text-left ">
            © 2024 BiCSoM Technologies Private Limited. | Made with
            <span className="inline-flex items-center mx-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M6 10.5002L5.275 9.8502C4.43333 9.09186 3.7375 8.4377 3.1875 7.8877C2.6375 7.3377 2.2 6.84395 1.875 6.40645C1.55 5.96895 1.32292 5.56686 1.19375 5.2002C1.06458 4.83353 1 4.45853 1 4.0752C1 3.29186 1.2625 2.6377 1.7875 2.1127C2.3125 1.5877 2.96667 1.3252 3.75 1.3252C4.18333 1.3252 4.59583 1.41686 4.9875 1.6002C5.37917 1.78353 5.71667 2.04186 6 2.3752C6.28333 2.04186 6.62083 1.78353 7.0125 1.6002C7.40417 1.41686 7.81667 1.3252 8.25 1.3252C9.03333 1.3252 9.6875 1.5877 10.2125 2.1127C10.7375 2.6377 11 3.29186 11 4.0752C11 4.45853 10.9354 4.83353 10.8063 5.2002C10.6771 5.56686 10.45 5.96895 10.125 6.40645C9.8 6.84395 9.3625 7.3377 8.8125 7.8877C8.2625 8.4377 7.56667 9.09186 6.725 9.8502L6 10.5002ZM6 9.1502C6.8 8.43353 7.45833 7.81895 7.975 7.30645C8.49167 6.79395 8.9 6.34811 9.2 5.96895C9.5 5.58978 9.70833 5.25228 9.825 4.95645C9.94167 4.66061 10 4.36686 10 4.0752C10 3.5752 9.83333 3.15853 9.5 2.8252C9.16667 2.49186 8.75 2.3252 8.25 2.3252C7.85833 2.3252 7.49583 2.43561 7.1625 2.65645C6.82917 2.87728 6.6 3.15853 6.475 3.5002H5.525C5.4 3.15853 5.17083 2.87728 4.8375 2.65645C4.50417 2.43561 4.14167 2.3252 3.75 2.3252C3.25 2.3252 2.83333 2.49186 2.5 2.8252C2.16667 3.15853 2 3.5752 2 4.0752C2 4.36686 2.05833 4.66061 2.175 4.95645C2.29167 5.25228 2.5 5.58978 2.8 5.96895C3.1 6.34811 3.50833 6.79395 4.025 7.30645C4.54167 7.81895 5.2 8.43353 6 9.1502Z"
                  fill="#fff"
                />
              </svg>
            </span>
            in India
          </span>
        </div>
      </Drawer>
    </div>
  );
};
